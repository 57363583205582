const nl = require('../langs/nl.json');
const de = require('../langs/de.json');
const fr = require('../langs/fr.json');
const en = require('../langs/en.json');

module.exports = {
  nl: nl,
  en: en,
  de: de,
  fr: fr
};
