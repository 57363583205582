// Kompasroos Data, generated by prepare.js

let kompasroosData = {
  "manufacturers": {
    "0BF35960-3F26-42FD-8C97-03263EC42E84": {
      "id": "0BF35960-3F26-42FD-8C97-03263EC42E84",
      "name": "Aerodyne Research",
      "countrycode": "us",
      "shortname": "Aerodyne",
      "url": "https://www.flyaerodyne.com",
      "slug": "aerodyne_research"
    },
    "97AD094C-A001-4928-8B4E-792EC76EFBCB": {
      "id": "97AD094C-A001-4928-8B4E-792EC76EFBCB",
      "name": "Airtime",
      "countrycode": "us",
      "shortname": "Airtime",
      "slug": "airtime"
    },
    "6C76AE26-F763-4536-AA22-2C4C8326804D": {
      "id": "6C76AE26-F763-4536-AA22-2C4C8326804D",
      "name": "Atair Aerodynamics",
      "countrycode": "us",
      "shortname": "Atair",
      "url": "http://www.atair.com/parachutes/",
      "slug": "atair_aerodynamics"
    },
    "5947BBA8-8DE6-4F61-AB89-B0B77A74B1EB": {
      "id": "5947BBA8-8DE6-4F61-AB89-B0B77A74B1EB",
      "name": "Big-air sportz",
      "countrycode": "us",
      "shortname": "Big-air",
      "url": "http://www.bigairsportz.com/",
      "slug": "big-air_sportz"
    },
    "781D6203-9BF1-4D51-8CE0-21FFFB9D4D12": {
      "id": "781D6203-9BF1-4D51-8CE0-21FFFB9D4D12",
      "name": "Parachute Systems",
      "countrycode": "sa",
      "remarks": {
        "en": "Parachute Systems was called Chute Shop originally.",
        "nl": "Parachute Systems heette voorheen Chute Shop."
      },
      "shortname": "Parachute Systems",
      "url": "http://parachutesystems.com/",
      "slug": "parachute_systems"
    },
    "B1F74487-55A3-4A0C-9A7E-463B508E3438": {
      "id": "B1F74487-55A3-4A0C-9A7E-463B508E3438",
      "name": "Eiff Aerodynamics",
      "countrycode": "us",
      "shortname": "Eiff",
      "url": "http://eiff.com",
      "slug": "eiff_aerodynamics"
    },
    "7AE2A118-7566-456B-9D73-2E6751BF7D7A": {
      "id": "7AE2A118-7566-456B-9D73-2E6751BF7D7A",
      "name": "Firebird",
      "countrycode": "us, de",
      "remarks": {
        "en": "Firebird was called Performance Variable originally.",
        "nl": "Firebird heette voorheen Performance Variable."
      },
      "shortname": "Firebird",
      "url": "http://www.flyfirebird.com/",
      "slug": "firebird"
    },
    "97C58924-5A47-4306-B8D4-E6A932E25A9F": {
      "id": "97C58924-5A47-4306-B8D4-E6A932E25A9F",
      "name": "Flight Concepts",
      "countrycode": "us",
      "shortname": "Flight Concepts",
      "remarks": {
        "en": "Flight Concepts was called Glide Path originally.",
        "nl": "Flight Concepts heette voorheen Glide Path."
      },
      "url": "http://flightconceptsint.com/",
      "slug": "flight_concepts"
    },
    "29A49954-54B1-4F77-A399-CCEFCCC9B3B9": {
      "id": "29A49954-54B1-4F77-A399-CCEFCCC9B3B9",
      "name": "Icarus Canopies",
      "countrycode": "nz, es",
      "remarks": {
        "en": "Most Icarus canopies are manufactured by NZ Aerosports.",
        "nl": "De meeste typen Icarus parachutes worden gefabriceerd door NZ Aerosports."
      },
      "shortname": "Icarus",
      "url": "http://www.icaruscanopies.aero/",
      "slug": "icarus_canopies"
    },
    "ACCD2816-15E8-4668-B217-3F345AA6854F": {
      "id": "ACCD2816-15E8-4668-B217-3F345AA6854F",
      "name": "Jump Shack",
      "countrycode": "us",
      "shortname": "Jump Shack",
      "url": "http://www.jumpshack.com/",
      "slug": "jump_shack"
    },
    "2228CEB3-3246-47D8-A393-52FF9DFEBB45": {
      "id": "2228CEB3-3246-47D8-A393-52FF9DFEBB45",
      "name": "North American Aerodynamics, Inc.",
      "countrycode": "us",
      "shortname": "NAA",
      "url": "http://www.naaero.com/",
      "slug": "north_american_aerodynamics_inc"
    },
    "4A42F850-E739-44D4-9A69-F66AD3247B1F": {
      "id": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
      "name": "NZ Aerosports",
      "countrycode": "nz",
      "remarks": {
        "en": "NZ Aerosports is the manufacturer of most of the Icarus canopies.",
        "nl": "NZ Aerosports is de fabrikant van de meeste typen Icarus parachutes."
      },
      "shortname": "NZ Aerosports",
      "url": "http://www.nzaerosports.com",
      "slug": "nz_aerosports"
    },
    "35AF72C6-F1D1-473D-99D0-804769E3E768": {
      "id": "35AF72C6-F1D1-473D-99D0-804769E3E768",
      "name": "Parachutes de France",
      "countrycode": "fr",
      "remarks": {
        "en": "Parachutes de France was merged in Zodiac Aerospace around 2014. In February 2018, Safran took control of Zodiac Aerospace.",
        "nl": "Parachutes de France is rond 2014 opgegaan in Zodiac Aerospace. In februari 2018 heeft Safran Zodiac Aerospace overgenomen."
      },
      "shortname": "PdF",
      "slug": "parachutes_de_france"
    },
    "E1DA167E-6512-401E-9033-8621884D2C67": {
      "id": "E1DA167E-6512-401E-9033-8621884D2C67",
      "name": "Paratec",
      "countrycode": "de",
      "shortname": "Paratec",
      "url": "http://www.paratec.de/",
      "slug": "paratec"
    },
    "541E8673-69E0-458A-8633-A3698311FD4B": {
      "id": "541E8673-69E0-458A-8633-A3698311FD4B",
      "name": "Performance Designs",
      "countrycode": "us",
      "shortname": "PD",
      "url": "https://www.performancedesigns.com/",
      "slug": "performance_designs"
    },
    "E0E33BAB-7067-42EC-96E3-FD6B35926A11": {
      "id": "E0E33BAB-7067-42EC-96E3-FD6B35926A11",
      "name": "PISA",
      "countrycode": "sa",
      "remarks": {
        "en": "PISA (Parachute Industries of South Africa) is no longer active. In 2018 the website was not online any more.",
        "nl": "PISA (Parachute Industries of South Africa) is niet meer actief. In 2018 was de website niet meer actief."
      },
      "shortname": "PISA",
      "slug": "pisa"
    },
    "7D4DAE42-062F-42A6-AD27-187B0AECDDA6": {
      "id": "7D4DAE42-062F-42A6-AD27-187B0AECDDA6",
      "name": "Precision Aerodynamics",
      "countrycode": "us",
      "shortname": "Precision",
      "url": "http://www.precision.net/",
      "slug": "precision_aerodynamics"
    },
    "ACD6855E-9F54-4E27-B498-14C9693FB7EF": {
      "id": "ACD6855E-9F54-4E27-B498-14C9693FB7EF",
      "name": "Strong Enterprises",
      "countrycode": "us",
      "shortname": "Strong",
      "url": "http://www.strongparachutes.com/",
      "slug": "strong_enterprises"
    },
    "CD51335E-1304-4391-9672-A6058F97C352": {
      "id": "CD51335E-1304-4391-9672-A6058F97C352",
      "name": "Squirrel",
      "countrycode": "us",
      "shortname": "Squirrel",
      "url": "https://squirrel.ws/",
      "slug": "squirrel"
    },
    "932734A0-EDDD-4BED-8B8C-C329214F7F4C": {
      "id": "932734A0-EDDD-4BED-8B8C-C329214F7F4C",
      "name": "Fluid Wings",
      "countrycode": "us",
      "shortname": "Fluid",
      "url": "http://www.fluidwings.com/",
      "slug": "fluid_wings"
    },
    "A82F2C34-DBA9-4A4B-8DE7-7319B00C7DB4": {
      "id": "A82F2C34-DBA9-4A4B-8DE7-7319B00C7DB4",
      "name": "Jojowings",
      "countrycode": "cz",
      "shortname": "Jojo",
      "url": "http://www.jojowing.com/",
      "slug": "jojowings"
    },
    "F497D86C-7E1E-4D4E-8FD4-AD4766AE5B15": {
      "id": "F497D86C-7E1E-4D4E-8FD4-AD4766AE5B15",
      "name": "Skylark Parachutes",
      "countrycode": "ua",
      "shortname": "Skylark",
      "url": "http://skylarkparachutes.com/",
      "slug": "skylark_parachutes"
    },
    "571E4861-2CCE-4276-A5B2-046A38F4870E": {
      "id": "571E4861-2CCE-4276-A5B2-046A38F4870E",
      "name": "Para-Flite",
      "countrycode": "us",
      "shortname": "Para-Flite",
      "remarks": {
        "en": "Para-Flite is no longer active. It became part of Airborne systems around 2006",
        "nl": "Para-Flite is niet meer actief. Het is onderdeel geworden van Airborne systems rond 2006"
      },
      "slug": "para-flite"
    },
    "75B438C0-B3B7-4D3E-9D64-6058A351A82F": {
      "id": "75B438C0-B3B7-4D3E-9D64-6058A351A82F",
      "name": "Innovative Parachute Technologies",
      "countrycode": "us",
      "shortname": "IPT",
      "slug": "innovative_parachute_technologies"
    }
  },
  "canopies": {
    "CB50EA97-DACF-4CCB-9D09-BA89C6C4B967": {
      "id": "CB50EA97-DACF-4CCB-9D09-BA89C6C4B967",
      "name": "Navigator",
      "category": 1,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "navigator-r23",
      "firstyearofproduction": "1998",
      "manufacturerid": "541E8673-69E0-458A-8633-A3698311FD4B",
      "maxsize": "300",
      "minsize": "200",
      "url": "https://www.performancedesigns.com/products/navigator/",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/navigator-r23"
        },
        {
          "type": "youtube",
          "title": "Test Navigator 240 sq ft",
          "url": "https://www.youtube.com/watch?v=KALCUff9Tx4"
        }
      ],
      "xbraced": false,
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Performance Designs",
      "manufacturerurl": "https://www.performancedesigns.com/",
      "manufacturerslug": "performance_designs",
      "search": "navigator|performancedesigns",
      "slug": "performance_designs-navigator"
    },
    "3231807B-22F3-4D6B-A490-403CC0018042": {
      "id": "3231807B-22F3-4D6B-A490-403CC0018042",
      "name": "PD F111 serie (7 cell)",
      "category": 1,
      "cells": "7",
      "commontype": 0,
      "dropzoneid": "pd-7-cell-r21",
      "firstyearofproduction": "1985",
      "lastyearofproduction": "2000",
      "manufacturerid": "541E8673-69E0-458A-8633-A3698311FD4B",
      "maxsize": "253",
      "minsize": "143",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/pd-7-cell-r21"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Performance Designs",
      "manufacturerurl": "https://www.performancedesigns.com/",
      "manufacturerslug": "performance_designs",
      "search": "pdf111serie7cell|performancedesigns",
      "slug": "performance_designs-pd_f111_serie_7_cell"
    },
    "393488EE-E78B-4DFB-9995-0E44AB538F42": {
      "id": "393488EE-E78B-4DFB-9995-0E44AB538F42",
      "name": "PD F111 serie (9 cell)",
      "category": 1,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "pd-9-cell-r22",
      "firstyearofproduction": "1984",
      "lastyearofproduction": "2000",
      "manufacturerid": "541E8673-69E0-458A-8633-A3698311FD4B",
      "maxsize": "340",
      "minsize": "150",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/pd-9-cell-r22"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Performance Designs",
      "manufacturerurl": "https://www.performancedesigns.com/",
      "manufacturerslug": "performance_designs",
      "search": "pdf111serie9cell|performancedesigns",
      "slug": "performance_designs-pd_f111_serie_9_cell"
    },
    "EDC9AA02-9A92-4809-BCEC-5BA863A28C69": {
      "id": "EDC9AA02-9A92-4809-BCEC-5BA863A28C69",
      "name": "Sabre (student)",
      "category": 1,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "sabre-r18",
      "firstyearofproduction": "1990",
      "lastyearofproduction": "2005",
      "manufacturerid": "541E8673-69E0-458A-8633-A3698311FD4B",
      "maxsize": "230",
      "minsize": "97",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/sabre-r18"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Performance Designs",
      "manufacturerurl": "https://www.performancedesigns.com/",
      "manufacturerslug": "performance_designs",
      "search": "sabrestudent|performancedesigns",
      "slug": "performance_designs-sabre_student"
    },
    "8360A137-A59C-4541-8B7A-6F596128A6AF": {
      "id": "8360A137-A59C-4541-8B7A-6F596128A6AF",
      "name": "Solo",
      "category": 1,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "solo-r354",
      "firstyearofproduction": "2003",
      "manufacturerid": "0BF35960-3F26-42FD-8C97-03263EC42E84",
      "maxsize": "290",
      "minsize": "190",
      "url": "https://www.flyaerodyne.com/solo.asp",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/solo-r354"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Aerodyne Research",
      "manufacturerurl": "https://www.flyaerodyne.com",
      "manufacturerslug": "aerodyne_research",
      "search": "solo|aerodyneresearch",
      "slug": "aerodyne_research-solo"
    },
    "CCD4E177-0E52-4837-BCA8-0640570E41E6": {
      "id": "CCD4E177-0E52-4837-BCA8-0640570E41E6",
      "name": "Exit One",
      "category": 1,
      "commontype": 0,
      "dropzoneid": "exit-one-r475",
      "manufacturerid": "7AE2A118-7566-456B-9D73-2E6751BF7D7A",
      "maxsize": "260",
      "minsize": "210",
      "url": "http://www.flyfirebird.com/catalog/product_info.php/cPath/90_21_159/products_id/670",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/exit-one-r475"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Firebird",
      "manufacturerurl": "http://www.flyfirebird.com/",
      "manufacturerslug": "firebird",
      "search": "exitone|firebird",
      "slug": "firebird-exit_one"
    },
    "38A6C9BE-8929-4599-8C1C-77C10F93000C": {
      "id": "38A6C9BE-8929-4599-8C1C-77C10F93000C",
      "name": "Spark Student Hybride",
      "category": 1,
      "commontype": 0,
      "manufacturerid": "7AE2A118-7566-456B-9D73-2E6751BF7D7A",
      "xbraced": false,
      "links": [],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Firebird",
      "manufacturerurl": "http://www.flyfirebird.com/",
      "manufacturerslug": "firebird",
      "search": "sparkstudenthybride|firebird",
      "slug": "firebird-spark_student_hybride"
    },
    "D3678054-4391-45BD-9C32-0FD253EF2AAD": {
      "id": "D3678054-4391-45BD-9C32-0FD253EF2AAD",
      "name": "Prima",
      "category": 1,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "aerodyne-research-corporation-r48",
      "manufacturerid": "35AF72C6-F1D1-473D-99D0-804769E3E768",
      "maxsize": "290",
      "minsize": "230",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/aerodyne-research-corporation-r48"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Parachutes de France",
      "manufacturerslug": "parachutes_de_france",
      "search": "prima|parachutesdefrance",
      "slug": "parachutes_de_france-prima"
    },
    "4BBA3DB9-9EC3-4EAD-BC59-C72744F4EE02": {
      "id": "4BBA3DB9-9EC3-4EAD-BC59-C72744F4EE02",
      "name": "Drakkar",
      "category": 1,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "drakkar-r53",
      "manufacturerid": "35AF72C6-F1D1-473D-99D0-804769E3E768",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/drakkar-r53"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Parachutes de France",
      "manufacturerslug": "parachutes_de_france",
      "search": "drakkar|parachutesdefrance",
      "slug": "parachutes_de_france-drakkar"
    },
    "54BB53BC-E33D-4FCF-8C7C-2211FF09F57C": {
      "id": "54BB53BC-E33D-4FCF-8C7C-2211FF09F57C",
      "name": "Turbo",
      "category": 1,
      "cells": "7",
      "commontype": 0,
      "manufacturerid": "35AF72C6-F1D1-473D-99D0-804769E3E768",
      "xbraced": false,
      "links": [],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Parachutes de France",
      "manufacturerslug": "parachutes_de_france",
      "search": "turbo|parachutesdefrance",
      "slug": "parachutes_de_france-turbo"
    },
    "9EFB5970-67D1-4A17-BD43-687072DFBEA3": {
      "id": "9EFB5970-67D1-4A17-BD43-687072DFBEA3",
      "name": "Prime",
      "category": 1,
      "cells": "9",
      "commontype": 0,
      "manufacturerid": "35AF72C6-F1D1-473D-99D0-804769E3E768",
      "maxsize": "280",
      "minsize": "200",
      "xbraced": false,
      "links": [],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Parachutes de France",
      "manufacturerslug": "parachutes_de_france",
      "search": "prime|parachutesdefrance",
      "slug": "parachutes_de_france-prime"
    },
    "3ECA0242-5B31-47C4-AC03-31FEC46BB777": {
      "id": "3ECA0242-5B31-47C4-AC03-31FEC46BB777",
      "name": "Raven",
      "cells": "7",
      "category": 1,
      "commontype": 0,
      "manufacturerid": "7D4DAE42-062F-42A6-AD27-187B0AECDDA6",
      "xbraced": false,
      "links": [],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Precision Aerodynamics",
      "manufacturerurl": "http://www.precision.net/",
      "manufacturerslug": "precision_aerodynamics",
      "search": "raven|precisionaerodynamics",
      "slug": "precision_aerodynamics-raven"
    },
    "54596159-118E-40BC-B8F5-080F962C0D16": {
      "id": "54596159-118E-40BC-B8F5-080F962C0D16",
      "name": "Super Raven",
      "cells": "7",
      "category": 1,
      "commontype": 0,
      "maxsize": "282",
      "minsize": "120",
      "dropzoneid": "super-raven-r28",
      "manufacturerid": "7D4DAE42-062F-42A6-AD27-187B0AECDDA6",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/super-raven-r28"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Precision Aerodynamics",
      "manufacturerurl": "http://www.precision.net/",
      "manufacturerslug": "precision_aerodynamics",
      "search": "superraven|precisionaerodynamics",
      "slug": "precision_aerodynamics-super_raven"
    },
    "1AFA9F7D-F7C2-4807-80EA-B394D15AAFE9": {
      "id": "1AFA9F7D-F7C2-4807-80EA-B394D15AAFE9",
      "name": "Falcon",
      "cells": "9",
      "category": 1,
      "commontype": 0,
      "maxsize": "300",
      "minsize": "120",
      "manufacturerid": "7D4DAE42-062F-42A6-AD27-187B0AECDDA6",
      "url": "http://www.precision.net/falcon-9.htm",
      "xbraced": false,
      "links": [],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Precision Aerodynamics",
      "manufacturerurl": "http://www.precision.net/",
      "manufacturerslug": "precision_aerodynamics",
      "search": "falcon|precisionaerodynamics",
      "slug": "precision_aerodynamics-falcon"
    },
    "C728529C-B0FE-4726-9D1C-7438721117CA": {
      "id": "C728529C-B0FE-4726-9D1C-7438721117CA",
      "name": "Voyager student",
      "category": 1,
      "commontype": 0,
      "manufacturerid": "7D4DAE42-062F-42A6-AD27-187B0AECDDA6",
      "xbraced": false,
      "links": [],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Precision Aerodynamics",
      "manufacturerurl": "http://www.precision.net/",
      "manufacturerslug": "precision_aerodynamics",
      "search": "voyagerstudent|precisionaerodynamics",
      "slug": "precision_aerodynamics-voyager_student"
    },
    "884274A6-948A-409C-AE6E-96BEA6373ACE": {
      "id": "884274A6-948A-409C-AE6E-96BEA6373ACE",
      "name": "Skymaster",
      "category": 1,
      "cells": "9",
      "commontype": 1,
      "manufacturerid": "E0E33BAB-7067-42EC-96E3-FD6B35926A11",
      "maxsize": "290",
      "minsize": "170",
      "xbraced": false,
      "links": [],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "PISA",
      "manufacturerslug": "pisa",
      "search": "skymaster|pisa",
      "slug": "pisa-skymaster"
    },
    "600122AA-A33A-4AF6-A326-8B9E251219D9": {
      "id": "600122AA-A33A-4AF6-A326-8B9E251219D9",
      "name": "Balance",
      "category": 1,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "balance-r370",
      "manufacturerid": "E1DA167E-6512-401E-9033-8621884D2C67",
      "maxsize": "280",
      "minsize": "150",
      "url": "http://www.paratec.de/legacy/?page_id=3653",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/balance-r370"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Paratec",
      "manufacturerurl": "http://www.paratec.de/",
      "manufacturerslug": "paratec",
      "search": "balance|paratec",
      "slug": "paratec-balance"
    },
    "9B97CE42-AF52-41E6-828C-9D0EF103B7A3": {
      "id": "9B97CE42-AF52-41E6-828C-9D0EF103B7A3",
      "name": "Element",
      "category": 1,
      "cells": "9",
      "commontype": 0,
      "manufacturerid": "E1DA167E-6512-401E-9033-8621884D2C67",
      "maxsize": "260",
      "minsize": "160",
      "url": "http://www.paratec.de/legacy/?page_id=4236",
      "xbraced": false,
      "links": [],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Paratec",
      "manufacturerurl": "http://www.paratec.de/",
      "manufacturerslug": "paratec",
      "search": "element|paratec",
      "slug": "paratec-element"
    },
    "B1CCC136-FAB0-4140-A927-9603C11DD1AA": {
      "id": "B1CCC136-FAB0-4140-A927-9603C11DD1AA",
      "name": "Maverick",
      "category": 1,
      "cells": "7",
      "commontype": 0,
      "dropzoneid": "fight-concepts-7-cell-r42",
      "manufacturerid": "97C58924-5A47-4306-B8D4-E6A932E25A9F",
      "maxsize": "200",
      "minsize": "200",
      "url": "http://flightconceptsint.com/classic/7-cell-classic",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/fight-concepts-7-cell-r42"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Flight Concepts",
      "manufacturerurl": "http://flightconceptsint.com/",
      "manufacturerslug": "flight_concepts",
      "search": "maverick|flightconcepts",
      "slug": "flight_concepts-maverick"
    },
    "E1297544-310F-4FBD-9436-98368303E71E": {
      "id": "E1297544-310F-4FBD-9436-98368303E71E",
      "name": "Fury",
      "category": 1,
      "cells": "7",
      "dropzoneid": "fight-concepts-7-cell-r42",
      "commontype": 0,
      "manufacturerid": "97C58924-5A47-4306-B8D4-E6A932E25A9F",
      "maxsize": "220",
      "minsize": "200",
      "url": "http://flightconceptsint.com/classic/7-cell-classic",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/fight-concepts-7-cell-r42"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Flight Concepts",
      "manufacturerurl": "http://flightconceptsint.com/",
      "manufacturerslug": "flight_concepts",
      "search": "fury|flightconcepts",
      "slug": "flight_concepts-fury"
    },
    "371ABAEA-0A5B-4832-BB52-135A20A2C74F": {
      "id": "371ABAEA-0A5B-4832-BB52-135A20A2C74F",
      "name": "Firelite",
      "category": 1,
      "cells": "7",
      "commontype": 0,
      "dropzoneid": "fight-concepts-7-cell-r42",
      "manufacturerid": "97C58924-5A47-4306-B8D4-E6A932E25A9F",
      "maxsize": "175",
      "minsize": "175",
      "url": "http://flightconceptsint.com/classic/7-cell-classic",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/fight-concepts-7-cell-r42"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Flight Concepts",
      "manufacturerurl": "http://flightconceptsint.com/",
      "manufacturerslug": "flight_concepts",
      "search": "firelite|flightconcepts",
      "slug": "flight_concepts-firelite"
    },
    "428AC10E-5398-4593-852B-EBD29E451899": {
      "id": "428AC10E-5398-4593-852B-EBD29E451899",
      "name": "Cricket",
      "category": 1,
      "cells": "7",
      "commontype": 0,
      "dropzoneid": "fight-concepts-7-cell-r42",
      "manufacturerid": "97C58924-5A47-4306-B8D4-E6A932E25A9F",
      "maxsize": "145",
      "minsize": "145",
      "url": "http://flightconceptsint.com/classic/7-cell-classic",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/fight-concepts-7-cell-r42"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Flight Concepts",
      "manufacturerurl": "http://flightconceptsint.com/",
      "manufacturerslug": "flight_concepts",
      "search": "cricket|flightconcepts",
      "slug": "flight_concepts-cricket"
    },
    "5FF8C2E2-9A99-47C5-BAD1-3E5C3B0EE615": {
      "id": "5FF8C2E2-9A99-47C5-BAD1-3E5C3B0EE615",
      "name": "Startrac",
      "category": 1,
      "cells": "7",
      "commontype": 0,
      "manufacturerid": "97C58924-5A47-4306-B8D4-E6A932E25A9F",
      "maxsize": "320",
      "minsize": "265",
      "url": "http://flightconceptsint.com/classic/7-cell-classic",
      "xbraced": false,
      "links": [],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Flight Concepts",
      "manufacturerurl": "http://flightconceptsint.com/",
      "manufacturerslug": "flight_concepts",
      "search": "startrac|flightconcepts",
      "slug": "flight_concepts-startrac"
    },
    "DEBE5437-93D0-4F1C-8A64-F75E77567AE9": {
      "id": "DEBE5437-93D0-4F1C-8A64-F75E77567AE9",
      "name": "Sharpchuter",
      "category": 1,
      "cells": "7",
      "dropzoneid": "fight-concepts-7-cell-r42",
      "commontype": 0,
      "manufacturerid": "97C58924-5A47-4306-B8D4-E6A932E25A9F",
      "maxsize": "245",
      "minsize": "245",
      "url": "http://flightconceptsint.com/classic/7-cell-classic",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/fight-concepts-7-cell-r42"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Flight Concepts",
      "manufacturerurl": "http://flightconceptsint.com/",
      "manufacturerslug": "flight_concepts",
      "search": "sharpchuter|flightconcepts",
      "slug": "flight_concepts-sharpchuter"
    },
    "A2B53986-0BFC-40D6-8D6D-6A2B4DFF8D14": {
      "id": "A2B53986-0BFC-40D6-8D6D-6A2B4DFF8D14",
      "name": "Raider",
      "category": 1,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "fight-concepts-9-cell-r41",
      "manufacturerid": "97C58924-5A47-4306-B8D4-E6A932E25A9F",
      "maxsize": "220",
      "minsize": "220",
      "url": "http://flightconceptsint.com/classic/9-cell-classic",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/fight-concepts-9-cell-r41"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Flight Concepts",
      "manufacturerurl": "http://flightconceptsint.com/",
      "manufacturerslug": "flight_concepts",
      "search": "raider|flightconcepts",
      "slug": "flight_concepts-raider"
    },
    "89E4F360-8E77-457D-8549-C739496A287E": {
      "id": "89E4F360-8E77-457D-8549-C739496A287E",
      "name": "Maverone",
      "category": 1,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "fight-concepts-9-cell-r41",
      "manufacturerid": "97C58924-5A47-4306-B8D4-E6A932E25A9F",
      "maxsize": "250",
      "minsize": "250",
      "url": "http://flightconceptsint.com/classic/9-cell-classic",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/fight-concepts-9-cell-r41"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Flight Concepts",
      "manufacturerurl": "http://flightconceptsint.com/",
      "manufacturerslug": "flight_concepts",
      "search": "maverone|flightconcepts",
      "slug": "flight_concepts-maverone"
    },
    "3A6927EF-1272-48DF-BB2A-130E960FA1E8": {
      "id": "3A6927EF-1272-48DF-BB2A-130E960FA1E8",
      "name": "Clipper",
      "category": 1,
      "cells": "9",
      "commontype": 0,
      "manufacturerid": "97C58924-5A47-4306-B8D4-E6A932E25A9F",
      "maxsize": "195",
      "minsize": "195",
      "dropzoneid": "fight-concepts-9-cell-r41",
      "url": "http://flightconceptsint.com/classic/9-cell-classic",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/fight-concepts-9-cell-r41"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Flight Concepts",
      "manufacturerurl": "http://flightconceptsint.com/",
      "manufacturerslug": "flight_concepts",
      "search": "clipper|flightconcepts",
      "slug": "flight_concepts-clipper"
    },
    "BE30E66B-0C3C-4664-ABE5-99997416B687": {
      "id": "BE30E66B-0C3C-4664-ABE5-99997416B687",
      "name": "Manta",
      "category": 1,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "fight-concepts-9-cell-r41",
      "manufacturerid": "97C58924-5A47-4306-B8D4-E6A932E25A9F",
      "maxsize": "290",
      "minsize": "290",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/fight-concepts-9-cell-r41"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Flight Concepts",
      "manufacturerurl": "http://flightconceptsint.com/",
      "manufacturerslug": "flight_concepts",
      "search": "manta|flightconcepts",
      "slug": "flight_concepts-manta"
    },
    "08FC5722-0531-4D4C-97E4-61C2A123D784": {
      "id": "08FC5722-0531-4D4C-97E4-61C2A123D784",
      "name": "Manta ZP",
      "category": 1,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "zp-manta-r570",
      "manufacturerid": "97C58924-5A47-4306-B8D4-E6A932E25A9F",
      "maxsize": "320",
      "minsize": "185",
      "url": "http://flightconceptsint.com/zp-main/zp-manta",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/zp-manta-r570"
        },
        {
          "type": "youtube",
          "title": "Parachute inflation of Flight Concepts fully zero porosity Manta",
          "url": "https://www.youtube.com/watch?v=wfaDnBkO6iI"
        }
      ],
      "xbraced": false,
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Flight Concepts",
      "manufacturerurl": "http://flightconceptsint.com/",
      "manufacturerslug": "flight_concepts",
      "search": "mantazp|flightconcepts",
      "slug": "flight_concepts-manta_zp"
    },
    "2B6BAAC0-2E47-48BF-B966-137373CA9CA9": {
      "id": "2B6BAAC0-2E47-48BF-B966-137373CA9CA9",
      "name": "Cruiselite",
      "category": 1,
      "commontype": 0,
      "manufacturerid": "97C58924-5A47-4306-B8D4-E6A932E25A9F",
      "xbraced": false,
      "links": [],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Flight Concepts",
      "manufacturerurl": "http://flightconceptsint.com/",
      "manufacturerslug": "flight_concepts",
      "search": "cruiselite|flightconcepts",
      "slug": "flight_concepts-cruiselite"
    },
    "86A007AE-A9FE-404E-9BF6-AD880D4F8568": {
      "id": "86A007AE-A9FE-404E-9BF6-AD880D4F8568",
      "name": "Student ZP7",
      "category": 1,
      "cells": "7",
      "commontype": 0,
      "dropzoneid": "icarus-student-zp-7-r7",
      "manufacturerid": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/icarus-student-zp-7-r7"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "NZ Aerosports",
      "manufacturerurl": "http://www.nzaerosports.com",
      "manufacturerslug": "nz_aerosports",
      "search": "studentzp7|nzaerosports",
      "slug": "nz_aerosports-student_zp7"
    },
    "89DCF61C-2FE5-4717-B14F-B5E8BB266DFB": {
      "id": "89DCF61C-2FE5-4717-B14F-B5E8BB266DFB",
      "name": "Equinox",
      "category": 1,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "equinox-r571",
      "manufacturerid": "29A49954-54B1-4F77-A399-CCEFCCC9B3B9",
      "maxsize": "229",
      "minsize": "189",
      "url": "http://www.icaruscanopies.aero/index.php?option=com_content&view=article&id=20&Itemid=19",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/equinox-r571"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Icarus Canopies",
      "manufacturerurl": "http://www.icaruscanopies.aero/",
      "manufacturerslug": "icarus_canopies",
      "search": "equinox|icaruscanopies",
      "slug": "icarus_canopies-equinox"
    },
    "B14481B6-5DE6-485F-9858-7011DE27884C": {
      "id": "B14481B6-5DE6-485F-9858-7011DE27884C",
      "name": "Zepo Student",
      "cells": "9",
      "category": 1,
      "commontype": 0,
      "manufacturerid": "781D6203-9BF1-4D51-8CE0-21FFFB9D4D12",
      "xbraced": false,
      "links": [],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Parachute Systems",
      "manufacturerurl": "http://parachutesystems.com/",
      "manufacturerslug": "parachute_systems",
      "search": "zepostudent|parachutesystems",
      "slug": "parachute_systems-zepo_student"
    },
    "747B45F3-B12C-4743-A5F8-2D90C20F22F1": {
      "id": "747B45F3-B12C-4743-A5F8-2D90C20F22F1",
      "name": "Eagle 7-cell",
      "category": 1,
      "cells": "7",
      "maxsize": "320",
      "minsize": "120",
      "commontype": 0,
      "dropzoneid": "eagle-7-cell-r68",
      "manufacturerid": "2228CEB3-3246-47D8-A393-52FF9DFEBB45",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/eagle-7-cell-r68"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "North American Aerodynamics, Inc.",
      "manufacturerurl": "http://www.naaero.com/",
      "manufacturerslug": "north_american_aerodynamics_inc",
      "search": "eagle7cell|northamericanaerodynamicsinc.",
      "slug": "north_american_aerodynamics_inc-eagle_7-cell"
    },
    "128B96F9-DAC3-4297-8329-FC24CCF9E781": {
      "id": "128B96F9-DAC3-4297-8329-FC24CCF9E781",
      "name": "Eagle 9-cell",
      "category": 1,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "eagle-9-cell-r69",
      "manufacturerid": "2228CEB3-3246-47D8-A393-52FF9DFEBB45",
      "maxsize": "330",
      "minsize": "110",
      "url": "http://www.naaero.com/sport/Eagle%209-cell.htm",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/eagle-9-cell-r69"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "North American Aerodynamics, Inc.",
      "manufacturerurl": "http://www.naaero.com/",
      "manufacturerslug": "north_american_aerodynamics_inc",
      "search": "eagle9cell|northamericanaerodynamicsinc.",
      "slug": "north_american_aerodynamics_inc-eagle_9-cell"
    },
    "D5522A6B-5A85-4C59-A0E7-D424A96E94E4": {
      "id": "D5522A6B-5A85-4C59-A0E7-D424A96E94E4",
      "name": "Icarus Student",
      "category": 1,
      "firstyearofproduction": "2015",
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "icarus-student-r841",
      "manufacturerid": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
      "url": "http://www.nzaerosports.com/canopies/icarus-student",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/icarus-student-r841"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "NZ Aerosports",
      "manufacturerurl": "http://www.nzaerosports.com",
      "manufacturerslug": "nz_aerosports",
      "search": "icarusstudent|nzaerosports",
      "slug": "nz_aerosports-icarus_student"
    },
    "F9F3F9F7-04E3-4E60-BFE8-A07A517550FE": {
      "id": "F9F3F9F7-04E3-4E60-BFE8-A07A517550FE",
      "name": "ZP",
      "category": 1,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "zp-r823",
      "manufacturerid": "781D6203-9BF1-4D51-8CE0-21FFFB9D4D12",
      "url": "http://www.parachutesystemseurope.com/eng/zp.php",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/zp-r823"
        }
      ],
      "displaycategory": 1,
      "calculationcategory": 1,
      "manufacturername": "Parachute Systems",
      "manufacturerurl": "http://parachutesystems.com/",
      "manufacturerslug": "parachute_systems",
      "search": "zp|parachutesystems",
      "slug": "parachute_systems-zp"
    },
    "9BADF237-317E-4B64-BF72-9A476F85CA6B": {
      "id": "9BADF237-317E-4B64-BF72-9A476F85CA6B",
      "name": "Electra",
      "category": 2,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "electra-r229",
      "manufacturerid": "35AF72C6-F1D1-473D-99D0-804769E3E768",
      "maxsize": "210",
      "minsize": "130",
      "planform": "9.5",
      "planformcells": "4",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/electra-r229"
        }
      ],
      "displaycategory": 2,
      "calculationcategory": 2,
      "manufacturername": "Parachutes de France",
      "manufacturerslug": "parachutes_de_france",
      "search": "electra|parachutesdefrance",
      "slug": "parachutes_de_france-electra"
    },
    "69B03296-4EDD-4823-BDAF-DD91604F9587": {
      "id": "69B03296-4EDD-4823-BDAF-DD91604F9587",
      "name": "Merit",
      "category": 2,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "merit-r49",
      "manufacturerid": "35AF72C6-F1D1-473D-99D0-804769E3E768",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/merit-r49"
        }
      ],
      "displaycategory": 2,
      "calculationcategory": 2,
      "manufacturername": "Parachutes de France",
      "manufacturerslug": "parachutes_de_france",
      "search": "merit|parachutesdefrance",
      "slug": "parachutes_de_france-merit"
    },
    "F32C85D5-615A-4CDA-A7AD-F2F27985E283": {
      "id": "F32C85D5-615A-4CDA-A7AD-F2F27985E283",
      "name": "Pilot",
      "category": 2,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "pilot-r353",
      "firstyearofproduction": "2003",
      "manufacturerid": "0BF35960-3F26-42FD-8C97-03263EC42E84",
      "maxsize": "230",
      "minsize": "90",
      "planform": "6.7",
      "planformcells": "4",
      "url": "https://www.flyaerodyne.com/pilot.asp",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/pilot-r353"
        },
        {
          "type": "youtube",
          "title": "Parachute Opening - Aerodyne Pilot 168",
          "url": "https://www.youtube.com/watch?v=6QgnKTmS0lA"
        }
      ],
      "xbraced": false,
      "displaycategory": 2,
      "calculationcategory": 2,
      "manufacturername": "Aerodyne Research",
      "manufacturerurl": "https://www.flyaerodyne.com",
      "manufacturerslug": "aerodyne_research",
      "search": "pilot|aerodyneresearch",
      "slug": "aerodyne_research-pilot"
    },
    "E0F993E0-766D-4FEB-86E1-DE06748F912C": {
      "id": "E0F993E0-766D-4FEB-86E1-DE06748F912C",
      "name": "Pilot7",
      "category": 2,
      "cells": "7",
      "commontype": 1,
      "dropzoneid": "pilot7-r861",
      "firstyearofproduction": "2016",
      "manufacturerid": "0BF35960-3F26-42FD-8C97-03263EC42E84",
      "maxsize": "247",
      "minsize": "117",
      "url": "https://www.flyaerodyne.com/pilot7.asp",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/pilot7-r861"
        },
        {
          "type": "youtube",
          "title": "Pilot 7",
          "url": "https://www.youtube.com/watch?v=aXn0yqapUbE"
        },
        {
          "type": "skydivemag",
          "title": "Skydive Magazine: Introducing the Pilot7",
          "url": "http://www.skydivemag.com/new/2016-04-20-introducing-the-pilot7"
        }
      ],
      "displaycategory": 2,
      "calculationcategory": 2,
      "manufacturername": "Aerodyne Research",
      "manufacturerurl": "https://www.flyaerodyne.com",
      "manufacturerslug": "aerodyne_research",
      "search": "pilot7|aerodyneresearch",
      "slug": "aerodyne_research-pilot7"
    },
    "02BFB95A-E433-4A02-A6A1-3F8593BFDC90": {
      "id": "02BFB95A-E433-4A02-A6A1-3F8593BFDC90",
      "name": "Triathlon",
      "category": 2,
      "cells": "7",
      "commontype": 1,
      "dropzoneid": "triathlon-r37",
      "firstyearofproduction": "2000",
      "manufacturerid": "0BF35960-3F26-42FD-8C97-03263EC42E84",
      "maxsize": "260",
      "minsize": "99",
      "url": "https://www.flyaerodyne.com/triathlon.asp",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/triathlon-r37"
        },
        {
          "type": "youtube",
          "title": "Slo-Motion Parachute Canopy triathlon 190 deployment",
          "url": "https://www.youtube.com/watch?v=7MoCrLpsJJY"
        },
        {
          "type": "youtube",
          "title": "Triathlon 160",
          "url": "https://www.youtube.com/watch?v=IwAUp_nO-3M"
        },
        {
          "type": "youtube",
          "title": "CRW canopy packing (Triathlon Aerodyne 135 sq ft.)",
          "url": "https://www.youtube.com/watch?v=ykDh2bp0Qp4"
        }
      ],
      "displaycategory": 2,
      "calculationcategory": 2,
      "manufacturername": "Aerodyne Research",
      "manufacturerurl": "https://www.flyaerodyne.com",
      "manufacturerslug": "aerodyne_research",
      "search": "triathlon|aerodyneresearch",
      "slug": "aerodyne_research-triathlon"
    },
    "49B5D670-24BF-4392-B6D5-3426A88DC2AC": {
      "id": "49B5D670-24BF-4392-B6D5-3426A88DC2AC",
      "name": "Super 7",
      "category": 2,
      "cells": "7",
      "commontype": 1,
      "dropzoneid": "super-seven-r371",
      "manufacturerid": "E1DA167E-6512-401E-9033-8621884D2C67",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/super-seven-r371"
        }
      ],
      "displaycategory": 2,
      "calculationcategory": 2,
      "manufacturername": "Paratec",
      "manufacturerurl": "http://www.paratec.de/",
      "manufacturerslug": "paratec",
      "search": "super7|paratec",
      "slug": "paratec-super_7"
    },
    "D1AEB195-55A5-4447-9578-47BD8431E13F": {
      "id": "D1AEB195-55A5-4447-9578-47BD8431E13F",
      "name": "Quadra V-Tec",
      "cells": "9",
      "category": 2,
      "commontype": 0,
      "dropzoneid": "quadra-v-tec-r372",
      "manufacturerid": "E1DA167E-6512-401E-9033-8621884D2C67",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/quadra-v-tec-r372"
        }
      ],
      "displaycategory": 2,
      "calculationcategory": 2,
      "manufacturername": "Paratec",
      "manufacturerurl": "http://www.paratec.de/",
      "manufacturerslug": "paratec",
      "search": "quadravtec|paratec",
      "slug": "paratec-quadra_v-tec"
    },
    "B83BF1CE-F80C-481A-9797-0FE46F6BA11F": {
      "id": "B83BF1CE-F80C-481A-9797-0FE46F6BA11F",
      "name": "Sabre",
      "category": 2,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "sabre-r18",
      "firstyearofproduction": "1990",
      "lastyearofproduction": "2005",
      "manufacturerid": "541E8673-69E0-458A-8633-A3698311FD4B",
      "maxsize": "230",
      "minsize": "97",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/sabre-r18"
        }
      ],
      "displaycategory": 2,
      "calculationcategory": 2,
      "manufacturername": "Performance Designs",
      "manufacturerurl": "https://www.performancedesigns.com/",
      "manufacturerslug": "performance_designs",
      "search": "sabre|performancedesigns",
      "slug": "performance_designs-sabre"
    },
    "AAC013BE-1485-46B3-9539-A4162D9C95AC": {
      "id": "AAC013BE-1485-46B3-9539-A4162D9C95AC",
      "name": "Spectre",
      "category": 2,
      "cells": "7",
      "commontype": 1,
      "dropzoneid": "spectre-r10",
      "firstyearofproduction": "1997",
      "manufacturerid": "541E8673-69E0-458A-8633-A3698311FD4B",
      "maxsize": "230",
      "minsize": "97",
      "url": "https://www.performancedesigns.com/products/spectre/",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/spectre-r10"
        },
        {
          "type": "youtube",
          "title": "How to land Spectre 190",
          "url": "https://www.youtube.com/watch?v=nwALHH1Nhdk"
        },
        {
          "type": "skydivemag",
          "title": "Skydive Magazine: Packing a SPECTRE",
          "url": "http://www.skydivemag.com/new/packing-a-spectre"
        }
      ],
      "xbraced": false,
      "displaycategory": 2,
      "calculationcategory": 2,
      "manufacturername": "Performance Designs",
      "manufacturerurl": "https://www.performancedesigns.com/",
      "manufacturerslug": "performance_designs",
      "search": "spectre|performancedesigns",
      "slug": "performance_designs-spectre"
    },
    "40734BBD-6781-45F7-9A90-C73C89C9ECF2": {
      "id": "40734BBD-6781-45F7-9A90-C73C89C9ECF2",
      "name": "Silhouette",
      "category": 2,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "silhouette-r20",
      "firstyearofproduction": "1997",
      "manufacturerid": "541E8673-69E0-458A-8633-A3698311FD4B",
      "maxsize": "260",
      "minsize": "135",
      "url": "https://www.performancedesigns.com/products/silhouette/",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/silhouette-r20"
        }
      ],
      "displaycategory": 2,
      "calculationcategory": 2,
      "manufacturername": "Performance Designs",
      "manufacturerurl": "https://www.performancedesigns.com/",
      "manufacturerslug": "performance_designs",
      "search": "silhouette|performancedesigns",
      "slug": "performance_designs-silhouette"
    },
    "86B73616-BABF-4D88-9184-ECD1C5657293": {
      "id": "86B73616-BABF-4D88-9184-ECD1C5657293",
      "name": "Pulse",
      "category": 2,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "pulse-r468",
      "firstyearofproduction": "2009",
      "manufacturerid": "541E8673-69E0-458A-8633-A3698311FD4B",
      "maxsize": "260",
      "minsize": "107",
      "url": "https://www.performancedesigns.com/products/pulse/",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/pulse-r468"
        },
        {
          "type": "youtube",
          "title": "PD Pulse promo",
          "url": "https://www.youtube.com/watch?v=lWM-r67r-9Y"
        }
      ],
      "xbraced": false,
      "displaycategory": 2,
      "calculationcategory": 2,
      "manufacturername": "Performance Designs",
      "manufacturerurl": "https://www.performancedesigns.com/",
      "manufacturerslug": "performance_designs",
      "search": "pulse|performancedesigns",
      "slug": "performance_designs-pulse"
    },
    "8544A47F-DF6D-4B07-A0D9-FF85800E8BB1": {
      "id": "8544A47F-DF6D-4B07-A0D9-FF85800E8BB1",
      "name": "Zero",
      "category": 2,
      "cells": "7",
      "commontype": 1,
      "dropzoneid": "zero-r655",
      "firstyearofproduction": "2010",
      "manufacturerid": "541E8673-69E0-458A-8633-A3698311FD4B",
      "maxsize": "305",
      "minsize": "205",
      "url": "https://www.performancedesigns.com/products/zero/",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/zero-r655"
        }
      ],
      "displaycategory": 2,
      "calculationcategory": 2,
      "manufacturername": "Performance Designs",
      "manufacturerurl": "https://www.performancedesigns.com/",
      "manufacturerslug": "performance_designs",
      "search": "zero|performancedesigns",
      "slug": "performance_designs-zero"
    },
    "8C4442DA-BBEF-4C3A-A95C-F45CE891F407": {
      "id": "8C4442DA-BBEF-4C3A-A95C-F45CE891F407",
      "name": "Synergy",
      "category": 2,
      "cells": "7",
      "commontype": 0,
      "dropzoneid": "synergy-r225",
      "manufacturerid": "7D4DAE42-062F-42A6-AD27-187B0AECDDA6",
      "maxsize": "230",
      "minsize": "98",
      "url": "http://www.precision.net/synergy.htm",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/synergy-r225"
        }
      ],
      "displaycategory": 2,
      "calculationcategory": 2,
      "manufacturername": "Precision Aerodynamics",
      "manufacturerurl": "http://www.precision.net/",
      "manufacturerslug": "precision_aerodynamics",
      "search": "synergy|precisionaerodynamics",
      "slug": "precision_aerodynamics-synergy"
    },
    "5A213243-07F2-4450-9397-B021595F5865": {
      "id": "5A213243-07F2-4450-9397-B021595F5865",
      "name": "Omni",
      "category": 2,
      "cells": "7",
      "commontype": 0,
      "dropzoneid": "omni-r236",
      "manufacturerid": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
      "maxsize": "229",
      "minsize": "99",
      "url": "http://www.icaruscanopies.aero/index.php?option=com_content&view=article&id=1&Itemid=11",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/omni-r236"
        }
      ],
      "displaycategory": 2,
      "calculationcategory": 2,
      "manufacturername": "NZ Aerosports",
      "manufacturerurl": "http://www.nzaerosports.com",
      "manufacturerslug": "nz_aerosports",
      "search": "omni|nzaerosports",
      "slug": "nz_aerosports-omni"
    },
    "95031F08-A04A-4FC4-81D5-EEAAEC329F6D": {
      "id": "95031F08-A04A-4FC4-81D5-EEAAEC329F6D",
      "name": "Omega",
      "category": 2,
      "cells": "7",
      "commontype": 1,
      "dropzoneid": "omega-r4",
      "firstyearofproduction": "1998",
      "manufacturerid": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
      "maxsize": "229",
      "minsize": "99",
      "url": "http://www.nzaerosports.com/canopies/icarus-omega",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/omega-r4"
        }
      ],
      "displaycategory": 2,
      "calculationcategory": 2,
      "manufacturername": "NZ Aerosports",
      "manufacturerurl": "http://www.nzaerosports.com",
      "manufacturerslug": "nz_aerosports",
      "search": "omega|nzaerosports",
      "slug": "nz_aerosports-omega"
    },
    "F1269861-262D-4CCC-A8FA-4C190B59C0C6": {
      "id": "F1269861-262D-4CCC-A8FA-4C190B59C0C6",
      "name": "Cayenne",
      "category": 2,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "cayenne-light-r473",
      "manufacturerid": "7AE2A118-7566-456B-9D73-2E6751BF7D7A",
      "maxsize": "190",
      "minsize": "130",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/cayenne-light-r473"
        },
        {
          "type": "pdf",
          "title": "Manual",
          "url": "http://www.flyfirebird.com/fb/downloads/manuals/cayenne.pdf"
        }
      ],
      "xbraced": false,
      "displaycategory": 2,
      "calculationcategory": 2,
      "manufacturername": "Firebird",
      "manufacturerurl": "http://www.flyfirebird.com/",
      "manufacturerslug": "firebird",
      "search": "cayenne|firebird",
      "slug": "firebird-cayenne"
    },
    "037A12CF-5B9F-4B20-82C1-4A5FB376B38D": {
      "id": "037A12CF-5B9F-4B20-82C1-4A5FB376B38D",
      "name": "Dragon",
      "category": 2,
      "cells": "7",
      "commontype": 0,
      "dropzoneid": "dragon-r585",
      "manufacturerid": "6C76AE26-F763-4536-AA22-2C4C8326804D",
      "maxsize": "210",
      "minsize": "135",
      "url": "http://www.basetroll.com/products_4.html",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/dragon-r585"
        }
      ],
      "displaycategory": 2,
      "calculationcategory": 2,
      "manufacturername": "Atair Aerodynamics",
      "manufacturerurl": "http://www.atair.com/parachutes/",
      "manufacturerslug": "atair_aerodynamics",
      "search": "dragon|atairaerodynamics",
      "slug": "atair_aerodynamics-dragon"
    },
    "24924509-5EF2-473C-863C-6B43BF64FE09": {
      "id": "24924509-5EF2-473C-863C-6B43BF64FE09",
      "name": "ParaFoil",
      "category": 2,
      "cells": "7",
      "commontype": 1,
      "dropzoneid": "parafoil-r66",
      "manufacturerid": "2228CEB3-3246-47D8-A393-52FF9DFEBB45",
      "maxsize": "304",
      "minsize": "220",
      "url": "http://www.naaero.com/sport/parafoil.htm",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/parafoil-r66"
        }
      ],
      "displaycategory": 2,
      "calculationcategory": 2,
      "manufacturername": "North American Aerodynamics, Inc.",
      "manufacturerurl": "http://www.naaero.com/",
      "manufacturerslug": "north_american_aerodynamics_inc",
      "search": "parafoil|northamericanaerodynamicsinc.",
      "slug": "north_american_aerodynamics_inc-parafoil"
    },
    "7017A355-35E0-4578-A81D-89CE8F021AAA": {
      "id": "7017A355-35E0-4578-A81D-89CE8F021AAA",
      "name": "Classic",
      "category": 2,
      "cells": "7",
      "commontype": 0,
      "dropzoneid": "classic-r771",
      "manufacturerid": "B1F74487-55A3-4A0C-9A7E-463B508E3438",
      "maxsize": "328",
      "minsize": "198",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/classic-r771"
        }
      ],
      "displaycategory": 2,
      "calculationcategory": 2,
      "manufacturername": "Eiff Aerodynamics",
      "manufacturerurl": "http://eiff.com",
      "manufacturerslug": "eiff_aerodynamics",
      "search": "classic|eiffaerodynamics",
      "slug": "eiff_aerodynamics-classic"
    },
    "21BD9B3B-77E6-4605-B89A-0B118C97C960": {
      "id": "21BD9B3B-77E6-4605-B89A-0B118C97C960",
      "name": "Commodore",
      "category": 2,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "commodore-r615",
      "manufacturerid": "F497D86C-7E1E-4D4E-8FD4-AD4766AE5B15",
      "maxsize": "190",
      "minsize": "290",
      "url": "http://skylarkparachutes.com/commodore",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/commodore-r615"
        }
      ],
      "displaycategory": 2,
      "calculationcategory": 2,
      "manufacturername": "Skylark Parachutes",
      "manufacturerurl": "http://skylarkparachutes.com/",
      "manufacturerslug": "skylark_parachutes",
      "search": "commodore|skylarkparachutes",
      "slug": "skylark_parachutes-commodore"
    },
    "3F5AAAF5-64FD-435D-81C8-17EF54119440": {
      "id": "3F5AAAF5-64FD-435D-81C8-17EF54119440",
      "name": "Turbo Z",
      "category": 2,
      "commontype": 0,
      "manufacturerid": "571E4861-2CCE-4276-A5B2-046A38F4870E",
      "xbraced": false,
      "links": [],
      "displaycategory": 2,
      "calculationcategory": 2,
      "manufacturername": "Para-Flite",
      "manufacturerslug": "para-flite",
      "search": "turboz|paraflite",
      "slug": "para-flite-turbo_z"
    },
    "6BC329AA-C9C7-4372-990E-46E3CA20ECF2": {
      "id": "6BC329AA-C9C7-4372-990E-46E3CA20ECF2",
      "name": "Turbo ZX",
      "category": 2,
      "dropzoneid": "turbo-zx-r65",
      "commontype": 0,
      "manufacturerid": "571E4861-2CCE-4276-A5B2-046A38F4870E",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/turbo-zx-r65"
        }
      ],
      "displaycategory": 2,
      "calculationcategory": 2,
      "manufacturername": "Para-Flite",
      "manufacturerslug": "para-flite",
      "search": "turbozx|paraflite",
      "slug": "para-flite-turbo_zx"
    },
    "46281C3A-4286-422D-B8F4-8D9015AB96BB": {
      "id": "46281C3A-4286-422D-B8F4-8D9015AB96BB",
      "name": "Sabre 2",
      "category": 3,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "sabre2-r217",
      "firstyearofproduction": "2001",
      "manufacturerid": "541E8673-69E0-458A-8633-A3698311FD4B",
      "maxsize": "260",
      "minsize": "97",
      "planform": "5.4",
      "planformcells": "6",
      "url": "https://www.performancedesigns.com/products/sabre2/",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/sabre2-r217"
        },
        {
          "type": "youtube",
          "title": "PD Sabre 2 promo",
          "url": "https://www.youtube.com/watch?v=VKxMN8C3TtE"
        },
        {
          "type": "youtube",
          "title": "Sabre2 150 - flight performance and landings ",
          "url": "https://www.youtube.com/watch?v=kZ3gsPSAvvc"
        },
        {
          "type": "youtube",
          "title": "PD Sabre 2 pro pack door Nick Grillet 1/3",
          "url": "https://www.youtube.com/watch?v=vhEHdKemr1M"
        },
        {
          "type": "youtube",
          "title": "PD Sabre 2 pro pack door Nick Grillet 2/3",
          "url": "https://www.youtube.com/watch?v=jqNsFPbKIw8"
        },
        {
          "type": "youtube",
          "title": "PD Sabre 2 pro pack door Nick Grillet 3/3",
          "url": "https://www.youtube.com/watch?v=pHSpnXCShs0"
        },
        {
          "type": "youtube",
          "title": "Sabre 2 150 - Deployments",
          "url": "https://www.youtube.com/watch?v=19uMK1SN5kU"
        }
      ],
      "xbraced": false,
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Performance Designs",
      "manufacturerurl": "https://www.performancedesigns.com/",
      "manufacturerslug": "performance_designs",
      "search": "sabre2|performancedesigns",
      "slug": "performance_designs-sabre_2"
    },
    "15109E1D-8473-4218-9510-65EB089ADCC9": {
      "id": "15109E1D-8473-4218-9510-65EB089ADCC9",
      "name": "Sabre 3",
      "category": 3,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "sabre-3-r969",
      "firstyearofproduction": "2020",
      "manufacturerid": "541E8673-69E0-458A-8633-A3698311FD4B",
      "maxsize": "230",
      "minsize": "89",
      "url": "https://www.performancedesigns.com/products/sabre3/",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/sabre-3-r969"
        },
        {
          "type": "youtube",
          "title": "PD Sabre 3 promo",
          "url": "https://www.youtube.com/watch?v=QOj_XyWbrqM"
        }
      ],
      "xbraced": false,
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Performance Designs",
      "manufacturerurl": "https://www.performancedesigns.com/",
      "manufacturerslug": "performance_designs",
      "search": "sabre3|performancedesigns",
      "slug": "performance_designs-sabre_3"
    },
    "1EE39B10-69B0-4434-BCA1-5A26BADAFD27": {
      "id": "1EE39B10-69B0-4434-BCA1-5A26BADAFD27",
      "name": "Lightning",
      "category": 3,
      "cells": "7",
      "commontype": 1,
      "dropzoneid": "lightning-r19",
      "firstyearofproduction": "1990",
      "manufacturerid": "541E8673-69E0-458A-8633-A3698311FD4B",
      "maxsize": "253",
      "minsize": "113",
      "url": "https://www.performancedesigns.com/products/lightning/",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/lightning-r19"
        },
        {
          "type": "youtube",
          "title": "Canopy Formation 100 way world record 2007",
          "url": "https://www.youtube.com/watch?v=Fggc3B5u5Ck"
        },
        {
          "type": "youtube",
          "title": "44 way Australian CRW record",
          "url": "https://www.youtube.com/watch?v=jo6KpVO72UQ"
        },
        {
          "type": "youtube",
          "title": "Thread the Needle",
          "url": "https://www.youtube.com/watch?v=3MwrNPx5ciY"
        }
      ],
      "xbraced": false,
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Performance Designs",
      "manufacturerurl": "https://www.performancedesigns.com/",
      "manufacturerslug": "performance_designs",
      "search": "lightning|performancedesigns",
      "slug": "performance_designs-lightning"
    },
    "A982E92C-85D9-480B-98CE-A2F2BF80380C": {
      "id": "A982E92C-85D9-480B-98CE-A2F2BF80380C",
      "name": "Storm",
      "category": 3,
      "cells": "7",
      "commontype": 1,
      "dropzoneid": "storm-r463",
      "firstyearofproduction": "2008",
      "manufacturerid": "541E8673-69E0-458A-8633-A3698311FD4B",
      "maxsize": "230",
      "minsize": "97",
      "url": "https://www.performancedesigns.com/products/storm/",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/storm-r463"
        },
        {
          "type": "youtube",
          "title": "PD Storm promo",
          "url": "https://www.youtube.com/watch?v=pO8dlRu24Kc"
        }
      ],
      "xbraced": false,
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Performance Designs",
      "manufacturerurl": "https://www.performancedesigns.com/",
      "manufacturerslug": "performance_designs",
      "search": "storm|performancedesigns",
      "slug": "performance_designs-storm"
    },
    "BF1DAC87-02CD-4743-B715-62EFF23ECBED": {
      "id": "BF1DAC87-02CD-4743-B715-62EFF23ECBED",
      "name": "Horizon",
      "category": 3,
      "cells": "7",
      "commontype": 1,
      "dropzoneid": "horizon-r912",
      "firstyearofproduction": "2017",
      "manufacturerid": "541E8673-69E0-458A-8633-A3698311FD4B",
      "maxsize": "190",
      "minsize": "120",
      "url": "https://www.performancedesigns.com/products/horizon/",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/horizon-r912"
        },
        {
          "type": "youtube",
          "title": "PD Horizon promo",
          "url": "https://www.youtube.com/watch?v=a9wbmmY0IdI"
        }
      ],
      "xbraced": false,
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Performance Designs",
      "manufacturerurl": "https://www.performancedesigns.com/",
      "manufacturerslug": "performance_designs",
      "search": "horizon|performancedesigns",
      "slug": "performance_designs-horizon"
    },
    "8A64E433-8856-4329-9B5E-2BF6F2D37632": {
      "id": "8A64E433-8856-4329-9B5E-2BF6F2D37632",
      "name": "Fusion",
      "category": 3,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "fusion-r385",
      "manufacturerid": "7D4DAE42-062F-42A6-AD27-187B0AECDDA6",
      "maxsize": "230",
      "minsize": "89",
      "url": "http://www.precision.net/fusion.htm",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/fusion-r385"
        }
      ],
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Precision Aerodynamics",
      "manufacturerurl": "http://www.precision.net/",
      "manufacturerslug": "precision_aerodynamics",
      "search": "fusion|precisionaerodynamics",
      "slug": "precision_aerodynamics-fusion"
    },
    "52B5C7B1-D277-4358-AECA-714E0E219590": {
      "id": "52B5C7B1-D277-4358-AECA-714E0E219590",
      "name": "Lotus",
      "category": 3,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "lotus-r72",
      "manufacturerid": "5947BBA8-8DE6-4F61-AB89-B0B77A74B1EB",
      "maxsize": "190",
      "minsize": "95",
      "url": "http://www.bigairsportz.com/lotus.php",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/lotus-r72"
        }
      ],
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Big-air sportz",
      "manufacturerurl": "http://www.bigairsportz.com/",
      "manufacturerslug": "big-air_sportz",
      "search": "lotus|bigairsportz",
      "slug": "big-air_sportz-lotus"
    },
    "3B9BF398-E112-45F3-B0A7-B9DC448DF868": {
      "id": "3B9BF398-E112-45F3-B0A7-B9DC448DF868",
      "name": "Safire 1",
      "category": 3,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "safire-r2",
      "firstyearofproduction": "1998",
      "manufacturerid": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
      "remarks": {
        "en": "Safire 2 was introduced in 2001",
        "nl": "Safire 2 werd geïntroduceerd in 2001"
      },
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/safire-r2"
        }
      ],
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "NZ Aerosports",
      "manufacturerurl": "http://www.nzaerosports.com",
      "manufacturerslug": "nz_aerosports",
      "search": "safire1|nzaerosports",
      "slug": "nz_aerosports-safire_1"
    },
    "78C709A5-C1C8-4CAE-99F0-52D43B189D88": {
      "id": "78C709A5-C1C8-4CAE-99F0-52D43B189D88",
      "name": "Safire 2",
      "category": 3,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "safire-2-r237",
      "firstyearofproduction": "2001",
      "manufacturerid": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
      "maxsize": "229",
      "minsize": "99",
      "planform": "16.4",
      "planformcells": "8",
      "url": "http://www.nzaerosports.com/canopies/icarus-safire",
      "remarks": {
        "en": "Safire 3 was introduced in 2016",
        "nl": "Safire 3 werd geïntroduceerd in 2016"
      },
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/safire-2-r237"
        },
        {
          "type": "youtube",
          "title": "Safire 3 The Story",
          "url": "https://www.youtube.com/watch?v=ySkbuQ16H7I"
        },
        {
          "type": "youtube",
          "title": "Icarus Safire2 149",
          "url": "https://www.youtube.com/watch?v=DI3cjt3Wbrk"
        },
        {
          "type": "youtube",
          "title": "NEW ICARUS CANOPIES SAFIRE2 119 sqft OPENING & LANDING",
          "url": "https://www.youtube.com/watch?v=3RWf974sCtk"
        }
      ],
      "xbraced": false,
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "NZ Aerosports",
      "manufacturerurl": "http://www.nzaerosports.com",
      "manufacturerslug": "nz_aerosports",
      "search": "safire2|nzaerosports",
      "slug": "nz_aerosports-safire_2"
    },
    "B831B534-D7A7-4063-A1C5-694D1FEE9F22": {
      "id": "B831B534-D7A7-4063-A1C5-694D1FEE9F22",
      "name": "S-fire",
      "category": 3,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "s-fire-r909",
      "firstyearofproduction": "2017",
      "manufacturerid": "29A49954-54B1-4F77-A399-CCEFCCC9B3B9",
      "maxsize": "229",
      "minsize": "99",
      "url": "http://www.icaruscanopies.aero/index.php?option=com_content&view=article&id=127&Itemid=673",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/s-fire-r909"
        },
        {
          "type": "youtube",
          "title": "Landing Icarus S-fire 169",
          "url": "https://www.youtube.com/watch?v=NOmJjIOmSMk"
        },
        {
          "type": "youtube",
          "title": "ICARUS SFire 139 - Skydive - AIR 65",
          "url": "https://www.youtube.com/watch?v=Ik-HlZx_gok"
        }
      ],
      "xbraced": false,
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Icarus Canopies",
      "manufacturerurl": "http://www.icaruscanopies.aero/",
      "manufacturerslug": "icarus_canopies",
      "search": "sfire|icaruscanopies",
      "slug": "icarus_canopies-s-fire"
    },
    "83654CC3-4296-445B-863C-A5CC09185945": {
      "id": "83654CC3-4296-445B-863C-A5CC09185945",
      "name": "Safire 3",
      "category": 3,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "safire-3-r863",
      "firstyearofproduction": "2016",
      "manufacturerid": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
      "url": "http://www.nzaerosports.com/canopies/icarus-safire3",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/safire-3-r863"
        },
        {
          "type": "youtube",
          "title": "The story",
          "url": "https://www.youtube.com/watch?v=ySkbuQ16H7I"
        },
        {
          "type": "youtube",
          "title": "NZ Aerosports: The Safire 3",
          "url": "https://www.youtube.com/watch?v=UKi43C3NEUI"
        },
        {
          "type": "youtube",
          "title": "Openings",
          "url": "https://www.youtube.com/watch?v=QuNRQplQOhA"
        },
        {
          "type": "skydivemag",
          "title": "Skydive Magazine review",
          "url": "http://www.skydivemag.com/new/2017-04-12-safire-3-review/"
        }
      ],
      "xbraced": false,
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "NZ Aerosports",
      "manufacturerurl": "http://www.nzaerosports.com",
      "manufacturerslug": "nz_aerosports",
      "search": "safire3|nzaerosports",
      "slug": "nz_aerosports-safire_3"
    },
    "D78B4C18-1887-4D66-8FF6-6FFFEF93C6CB": {
      "id": "D78B4C18-1887-4D66-8FF6-6FFFEF93C6CB",
      "name": "Z-po",
      "category": 3,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "strong-z-po-r61",
      "manufacturerid": "ACD6855E-9F54-4E27-B498-14C9693FB7EF",
      "maxsize": "210",
      "minsize": "130",
      "url": "http://www.strongparachutes.com/pages/Sport/spmain.html",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/strong-z-po-r61"
        }
      ],
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Strong Enterprises",
      "manufacturerurl": "http://www.strongparachutes.com/",
      "manufacturerslug": "strong_enterprises",
      "search": "zpo|strongenterprises",
      "slug": "strong_enterprises-z-po"
    },
    "ABB72EAB-6276-42E8-9681-19FBCB846FC1": {
      "id": "ABB72EAB-6276-42E8-9681-19FBCB846FC1",
      "name": "Faqtor",
      "category": 3,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "faqtor-r373",
      "manufacturerid": "E1DA167E-6512-401E-9033-8621884D2C67",
      "maxsize": "150",
      "minsize": "97",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/faqtor-r373"
        }
      ],
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Paratec",
      "manufacturerurl": "http://www.paratec.de/",
      "manufacturerslug": "paratec",
      "search": "faqtor|paratec",
      "slug": "paratec-faqtor"
    },
    "FC044FAE-FD5C-45A2-8740-9CE8783C76FA": {
      "id": "FC044FAE-FD5C-45A2-8740-9CE8783C76FA",
      "name": "NRG",
      "category": 3,
      "cells": "9",
      "commontype": 0,
      "manufacturerid": "E1DA167E-6512-401E-9033-8621884D2C67",
      "url": "http://www.paratec.de/legacy/?page_id=4243",
      "links": [
        {
          "type": "youtube",
          "title": "NRG Opening",
          "url": "https://www.youtube.com/watch?v=2S9Hwnk-qI4"
        }
      ],
      "xbraced": false,
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Paratec",
      "manufacturerurl": "http://www.paratec.de/",
      "manufacturerslug": "paratec",
      "search": "nrg|paratec",
      "slug": "paratec-nrg"
    },
    "86593DB8-9894-4866-A580-E7A35D53D600": {
      "id": "86593DB8-9894-4866-A580-E7A35D53D600",
      "name": "Hornet",
      "cells": "9",
      "category": 3,
      "commontype": 0,
      "dropzoneid": "hornet-r773",
      "manufacturerid": "E0E33BAB-7067-42EC-96E3-FD6B35926A11",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/hornet-r773"
        }
      ],
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "PISA",
      "manufacturerslug": "pisa",
      "search": "hornet|pisa",
      "slug": "pisa-hornet"
    },
    "26C05258-8F36-4A4B-A710-5B7A4D64810B": {
      "id": "26C05258-8F36-4A4B-A710-5B7A4D64810B",
      "name": "Zepo exe",
      "category": 3,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "zpexe-r365",
      "manufacturerid": "781D6203-9BF1-4D51-8CE0-21FFFB9D4D12",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/zpexe-r365"
        }
      ],
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Parachute Systems",
      "manufacturerurl": "http://parachutesystems.com/",
      "manufacturerslug": "parachute_systems",
      "search": "zepoexe|parachutesystems",
      "slug": "parachute_systems-zepo_exe"
    },
    "FF9EE1B5-2B29-4EF8-B929-35C530DD52A0": {
      "id": "FF9EE1B5-2B29-4EF8-B929-35C530DD52A0",
      "name": "Volt",
      "category": 3,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "volt-r854",
      "manufacturerid": "781D6203-9BF1-4D51-8CE0-21FFFB9D4D12",
      "maxsize": "215",
      "minsize": "125",
      "url": "http://parachutesystems.com/volt",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/volt-r854"
        },
        {
          "type": "youtube",
          "title": "Canopy deployment: Parachute Systems Volt",
          "url": "https://www.youtube.com/watch?v=lbvbXkjUxfE"
        }
      ],
      "xbraced": false,
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Parachute Systems",
      "manufacturerurl": "http://parachutesystems.com/",
      "manufacturerslug": "parachute_systems",
      "search": "volt|parachutesystems",
      "slug": "parachute_systems-volt"
    },
    "4CCBA3E8-89A4-4F25-BAEE-B2248E90415F": {
      "id": "4CCBA3E8-89A4-4F25-BAEE-B2248E90415F",
      "name": "Spark",
      "cells": "9",
      "category": 3,
      "commontype": 1,
      "maxsize": "230",
      "minsize": "105",
      "manufacturerid": "7AE2A118-7566-456B-9D73-2E6751BF7D7A",
      "links": [
        {
          "type": "pdf",
          "title": "Manual",
          "url": "http://www.flyfirebird.com/fb/downloads/manuals/spark.pdf"
        }
      ],
      "xbraced": false,
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Firebird",
      "manufacturerurl": "http://www.flyfirebird.com/",
      "manufacturerslug": "firebird",
      "search": "spark|firebird",
      "slug": "firebird-spark"
    },
    "C6C59DF8-9127-4BCA-B320-2BFE21AAA1A8": {
      "id": "C6C59DF8-9127-4BCA-B320-2BFE21AAA1A8",
      "name": "Express",
      "cells": "7",
      "category": 3,
      "commontype": 0,
      "dropzoneid": "express-r43",
      "manufacturerid": "97C58924-5A47-4306-B8D4-E6A932E25A9F",
      "maxsize": "200",
      "minsize": "125",
      "url": "http://flightconceptsint.com/zp-main/express",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/express-r43"
        }
      ],
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Flight Concepts",
      "manufacturerurl": "http://flightconceptsint.com/",
      "manufacturerslug": "flight_concepts",
      "search": "express|flightconcepts",
      "slug": "flight_concepts-express"
    },
    "C34B49DE-FB46-49EA-B34C-41CC059EC4B5": {
      "id": "C34B49DE-FB46-49EA-B34C-41CC059EC4B5",
      "name": "Prodigy",
      "cells": "7",
      "category": 3,
      "commontype": 0,
      "dropzoneid": "prodigy-r44",
      "manufacturerid": "97C58924-5A47-4306-B8D4-E6A932E25A9F",
      "maxsize": "250",
      "minsize": "150",
      "url": "http://flightconceptsint.com/zp-main/prodigy",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/prodigy-r44"
        }
      ],
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Flight Concepts",
      "manufacturerurl": "http://flightconceptsint.com/",
      "manufacturerslug": "flight_concepts",
      "search": "prodigy|flightconcepts",
      "slug": "flight_concepts-prodigy"
    },
    "DD3686AE-6C9C-446F-A255-C41A5CEEBE46": {
      "id": "DD3686AE-6C9C-446F-A255-C41A5CEEBE46",
      "name": "Sentry",
      "category": 3,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "sentry-r569",
      "manufacturerid": "97C58924-5A47-4306-B8D4-E6A932E25A9F",
      "maxsize": "230",
      "minsize": "99",
      "url": "http://flightconceptsint.com/zp-main/sentry",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/sentry-r569"
        }
      ],
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Flight Concepts",
      "manufacturerurl": "http://flightconceptsint.com/",
      "manufacturerslug": "flight_concepts",
      "search": "sentry|flightconcepts",
      "slug": "flight_concepts-sentry"
    },
    "947b67ce-177f-4c70-8fa4-10ab3c171d29": {
      "id": "947b67ce-177f-4c70-8fa4-10ab3c171d29",
      "name": "Matrix",
      "category": 3,
      "cells": "7",
      "commontype": 0,
      "dropzoneid": "matrix-r842",
      "manufacturerid": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
      "url": "http://www.nzaerosports.com/canopies/icarus-matrix",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/matrix-r842"
        },
        {
          "type": "youtube",
          "title": "Matrix Reloaded",
          "url": "https://www.youtube.com/watch?v=tLOqd1VYI2w"
        }
      ],
      "xbraced": false,
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "NZ Aerosports",
      "manufacturerurl": "http://www.nzaerosports.com",
      "manufacturerslug": "nz_aerosports",
      "search": "matrix|nzaerosports",
      "slug": "nz_aerosports-matrix"
    },
    "9D021CA3-A103-4CB9-B053-A7ABCC4BBC96": {
      "id": "9D021CA3-A103-4CB9-B053-A7ABCC4BBC96",
      "name": "Matrix 2",
      "category": 5,
      "cells": "15",
      "commontype": 0,
      "dropzoneid": "matrix-2-r843",
      "firstyearofproduction": "2013",
      "manufacturerid": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
      "url": "http://www.nzaerosports.com/canopies/daedalus-matrix2",
      "xbraced": true,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/matrix-2-r843"
        }
      ],
      "displaycategory": 5,
      "calculationcategory": 5,
      "manufacturername": "NZ Aerosports",
      "manufacturerurl": "http://www.nzaerosports.com",
      "manufacturerslug": "nz_aerosports",
      "search": "matrix2|nzaerosports",
      "slug": "nz_aerosports-matrix_2"
    },
    "3EF9E44E-900D-40CC-9916-DFC4560F377C": {
      "id": "3EF9E44E-900D-40CC-9916-DFC4560F377C",
      "name": "Skipper",
      "category": 3,
      "cells": "7",
      "commontype": 0,
      "dropzoneid": "skipper-r612",
      "manufacturerid": "F497D86C-7E1E-4D4E-8FD4-AD4766AE5B15",
      "maxsize": "190",
      "minsize": "110",
      "url": "http://skylarkparachutes.com/skipper/",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/skipper-r612"
        }
      ],
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Skylark Parachutes",
      "manufacturerurl": "http://skylarkparachutes.com/",
      "manufacturerslug": "skylark_parachutes",
      "search": "skipper|skylarkparachutes",
      "slug": "skylark_parachutes-skipper"
    },
    "9B56DFEE-C85E-4DD7-B828-19D232C323BA": {
      "id": "9B56DFEE-C85E-4DD7-B828-19D232C323BA",
      "name": "Skipper EVO",
      "category": 3,
      "cells": "7",
      "commontype": 0,
      "manufacturerid": "F497D86C-7E1E-4D4E-8FD4-AD4766AE5B15",
      "maxsize": "185",
      "minsize": "95",
      "url": "http://skylarkparachutes.com/skipper-evo/",
      "xbraced": false,
      "links": [],
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Skylark Parachutes",
      "manufacturerurl": "http://skylarkparachutes.com/",
      "manufacturerslug": "skylark_parachutes",
      "search": "skipperevo|skylarkparachutes",
      "slug": "skylark_parachutes-skipper_evo"
    },
    "C3165B46-F1F8-4524-9D50-46ADD9B1E86F": {
      "id": "C3165B46-F1F8-4524-9D50-46ADD9B1E86F",
      "name": "Magellan",
      "category": 3,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "magellan-r613",
      "manufacturerid": "F497D86C-7E1E-4D4E-8FD4-AD4766AE5B15",
      "maxsize": "190",
      "minsize": "100",
      "url": "http://skylarkparachutes.com/magellan/",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/magellan-r613"
        }
      ],
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Skylark Parachutes",
      "manufacturerurl": "http://skylarkparachutes.com/",
      "manufacturerslug": "skylark_parachutes",
      "search": "magellan|skylarkparachutes",
      "slug": "skylark_parachutes-magellan"
    },
    "9661B8FA-BD0D-4C75-9F0A-0409C3121B37": {
      "id": "9661B8FA-BD0D-4C75-9F0A-0409C3121B37",
      "name": "Magellan EVO",
      "category": 3,
      "cells": "9",
      "commontype": 0,
      "manufacturerid": "F497D86C-7E1E-4D4E-8FD4-AD4766AE5B15",
      "maxsize": "185",
      "minsize": "95",
      "url": "http://skylarkparachutes.com/magellan-evo/",
      "xbraced": false,
      "links": [],
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Skylark Parachutes",
      "manufacturerurl": "http://skylarkparachutes.com/",
      "manufacturerslug": "skylark_parachutes",
      "search": "magellanevo|skylarkparachutes",
      "slug": "skylark_parachutes-magellan_evo"
    },
    "2351611F-068C-4901-820D-26EC38FC36D9": {
      "id": "2351611F-068C-4901-820D-26EC38FC36D9",
      "name": "Epicene",
      "category": 3,
      "cells": "7",
      "maxsize": "210",
      "minsize": "116",
      "commontype": 0,
      "dropzoneid": "epicene-r948",
      "manufacturerid": "CD51335E-1304-4391-9672-A6058F97C352",
      "url": "https://squirrel.ws/parachutes/skydiving/epicene",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/epicene-r948"
        },
        {
          "type": "vimeo",
          "title": "Epicene promo video",
          "url": "https://vimeo.com/113977394"
        }
      ],
      "xbraced": false,
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Squirrel",
      "manufacturerurl": "https://squirrel.ws/",
      "manufacturerslug": "squirrel",
      "search": "epicene|squirrel",
      "slug": "squirrel-epicene"
    },
    "65A55BBF-5E9E-4ACC-BD87-723BD3CB3ACF": {
      "id": "65A55BBF-5E9E-4ACC-BD87-723BD3CB3ACF",
      "name": "Prime",
      "maxsize": "190",
      "minsize": "120",
      "cells": "9",
      "firstyearofproduction": "2014",
      "category": 3,
      "commontype": 0,
      "manufacturerid": "932734A0-EDDD-4BED-8B8C-C329214F7F4C",
      "url": "http://www.fluidwings.com/prime",
      "xbraced": false,
      "links": [],
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Fluid Wings",
      "manufacturerurl": "http://www.fluidwings.com/",
      "manufacturerslug": "fluid_wings",
      "search": "prime|fluidwings",
      "slug": "fluid_wings-prime"
    },
    "A23740C3-2E70-4B87-8326-C9A88F4CD66E": {
      "id": "A23740C3-2E70-4B87-8326-C9A88F4CD66E",
      "name": "Winx",
      "cells": "7",
      "maxsize": "210",
      "minsize": "120",
      "category": 3,
      "commontype": 0,
      "manufacturerid": "6C76AE26-F763-4536-AA22-2C4C8326804D",
      "url": "http://www.basetroll.com/products/winx/",
      "xbraced": false,
      "links": [],
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Atair Aerodynamics",
      "manufacturerurl": "http://www.atair.com/parachutes/",
      "manufacturerslug": "atair_aerodynamics",
      "search": "winx|atairaerodynamics",
      "slug": "atair_aerodynamics-winx"
    },
    "CD87EF17-F2AC-429D-A8D5-5443485A6BF3": {
      "id": "CD87EF17-F2AC-429D-A8D5-5443485A6BF3",
      "name": "Nexus",
      "maxsize": "190",
      "minsize": "79",
      "cells": "9",
      "firstyearofproduction": "2017",
      "category": 3,
      "commontype": 0,
      "manufacturerid": "932734A0-EDDD-4BED-8B8C-C329214F7F4C",
      "url": "https://www.fluidwings.com/nexus",
      "xbraced": false,
      "links": [
        {
          "type": "vimeo",
          "title": "NEXUS ~the next step~",
          "url": "https://vimeo.com/264115452"
        }
      ],
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Fluid Wings",
      "manufacturerurl": "http://www.fluidwings.com/",
      "manufacturerslug": "fluid_wings",
      "search": "nexus|fluidwings",
      "slug": "fluid_wings-nexus"
    },
    "EE53A162-A74B-4899-AD30-CCE659CC5AF4": {
      "id": "EE53A162-A74B-4899-AD30-CCE659CC5AF4",
      "name": "Kraken",
      "category": 3,
      "cells": "7",
      "commontype": 0,
      "dropzoneid": "kraken-r963",
      "firstyearofproduction": "2019",
      "maxsize": "169",
      "minsize": "129",
      "manufacturerid": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
      "url": "https://www.nzaerosports.com/product/kraken/",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/kraken-r963"
        }
      ],
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "NZ Aerosports",
      "manufacturerurl": "http://www.nzaerosports.com",
      "manufacturerslug": "nz_aerosports",
      "search": "kraken|nzaerosports",
      "slug": "nz_aerosports-kraken"
    },
    "F46272C1-526B-41A1-88B1-8A4F7CE7BAA9": {
      "id": "F46272C1-526B-41A1-88B1-8A4F7CE7BAA9",
      "name": "ōm-7",
      "category": 3,
      "cells": "7",
      "commontype": 0,
      "dropzoneid": "om7-r962",
      "manufacturerid": "29A49954-54B1-4F77-A399-CCEFCCC9B3B9",
      "maxsize": "229",
      "minsize": "99",
      "url": "http://www.icaruscanopies.aero/index.php?option=com_content&view=article&id=146&Itemid=676",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/om7-r962"
        }
      ],
      "displaycategory": 3,
      "calculationcategory": 3,
      "manufacturername": "Icarus Canopies",
      "manufacturerurl": "http://www.icaruscanopies.aero/",
      "manufacturerslug": "icarus_canopies",
      "search": "om7|icaruscanopies",
      "slug": "icarus_canopies-om-7"
    },
    "107633B0-202B-4625-9CD4-8AFE131C5D10": {
      "id": "107633B0-202B-4625-9CD4-8AFE131C5D10",
      "name": "Stiletto",
      "category": 4,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "stiletto-r17",
      "firstyearofproduction": "1992",
      "manufacturerid": "541E8673-69E0-458A-8633-A3698311FD4B",
      "maxsize": "190",
      "minsize": "89",
      "planform": "13.3",
      "planformcells": "4",
      "url": "https://www.performancedesigns.com/products/stiletto/",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/stiletto-r17"
        }
      ],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Performance Designs",
      "manufacturerurl": "https://www.performancedesigns.com/",
      "manufacturerslug": "performance_designs",
      "search": "stiletto|performancedesigns",
      "slug": "performance_designs-stiletto"
    },
    "F0B876B6-F2C6-4560-BF4D-A22DD3C070ED": {
      "id": "F0B876B6-F2C6-4560-BF4D-A22DD3C070ED",
      "name": "BT",
      "category": 4,
      "cells": "9",
      "commontype": 0,
      "manufacturerid": "35AF72C6-F1D1-473D-99D0-804769E3E768",
      "xbraced": false,
      "links": [],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Parachutes de France",
      "manufacturerslug": "parachutes_de_france",
      "search": "bt|parachutesdefrance",
      "slug": "parachutes_de_france-bt"
    },
    "A088AE69-7D61-45A5-9919-BF2328F836BE": {
      "id": "A088AE69-7D61-45A5-9919-BF2328F836BE",
      "name": "Springo",
      "category": 4,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "springo-r50",
      "manufacturerid": "35AF72C6-F1D1-473D-99D0-804769E3E768",
      "planform": "28.0",
      "planformcells": "8",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/springo-r50"
        }
      ],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Parachutes de France",
      "manufacturerslug": "parachutes_de_france",
      "search": "springo|parachutesdefrance",
      "slug": "parachutes_de_france-springo"
    },
    "85990128-ED3F-442D-9D76-373E3F267CDA": {
      "id": "85990128-ED3F-442D-9D76-373E3F267CDA",
      "name": "Electra XS",
      "category": 4,
      "cells": "9",
      "commontype": 1,
      "manufacturerid": "35AF72C6-F1D1-473D-99D0-804769E3E768",
      "xbraced": false,
      "links": [],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Parachutes de France",
      "manufacturerslug": "parachutes_de_france",
      "search": "electraxs|parachutesdefrance",
      "slug": "parachutes_de_france-electra_xs"
    },
    "04B47713-9ACE-4E57-851F-137DE23CD4E5": {
      "id": "04B47713-9ACE-4E57-851F-137DE23CD4E5",
      "name": "Springo NG",
      "category": 4,
      "cells": "9",
      "commontype": 0,
      "manufacturerid": "35AF72C6-F1D1-473D-99D0-804769E3E768",
      "maxsize": "160",
      "minsize": "110",
      "planform": "28.0",
      "planformcells": "8",
      "xbraced": false,
      "links": [],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Parachutes de France",
      "manufacturerslug": "parachutes_de_france",
      "search": "springong|parachutesdefrance",
      "slug": "parachutes_de_france-springo_ng"
    },
    "2A2D16C5-D208-44AB-AF97-1398405EC1A5": {
      "id": "2A2D16C5-D208-44AB-AF97-1398405EC1A5",
      "name": "Batwing",
      "category": 4,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "batwing-r26",
      "manufacturerid": "7D4DAE42-062F-42A6-AD27-187B0AECDDA6",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/batwing-r26"
        }
      ],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Precision Aerodynamics",
      "manufacturerurl": "http://www.precision.net/",
      "manufacturerslug": "precision_aerodynamics",
      "search": "batwing|precisionaerodynamics",
      "slug": "precision_aerodynamics-batwing"
    },
    "7428649F-5425-4F5A-A704-14DC383611C9": {
      "id": "7428649F-5425-4F5A-A704-14DC383611C9",
      "name": "Jonathan",
      "category": 4,
      "cells": "9",
      "commontype": 0,
      "manufacturerid": "97AD094C-A001-4928-8B4E-792EC76EFBCB",
      "xbraced": false,
      "links": [],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Airtime",
      "manufacturerslug": "airtime",
      "search": "jonathan|airtime",
      "slug": "airtime-jonathan"
    },
    "F0C4AF1D-A9BE-4560-B8E4-4F38616B30D4": {
      "id": "F0C4AF1D-A9BE-4560-B8E4-4F38616B30D4",
      "name": "Ace",
      "category": 4,
      "commontype": 1,
      "manufacturerid": "6C76AE26-F763-4536-AA22-2C4C8326804D",
      "xbraced": false,
      "links": [],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Atair Aerodynamics",
      "manufacturerurl": "http://www.atair.com/parachutes/",
      "manufacturerslug": "atair_aerodynamics",
      "search": "ace|atairaerodynamics",
      "slug": "atair_aerodynamics-ace"
    },
    "9634AACA-2A16-42B6-A687-6536DE20D3F7": {
      "id": "9634AACA-2A16-42B6-A687-6536DE20D3F7",
      "name": "Cobalt",
      "category": 4,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "cobalt-r33",
      "manufacturerid": "6C76AE26-F763-4536-AA22-2C4C8326804D",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/cobalt-r33"
        }
      ],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Atair Aerodynamics",
      "manufacturerurl": "http://www.atair.com/parachutes/",
      "manufacturerslug": "atair_aerodynamics",
      "search": "cobalt|atairaerodynamics",
      "slug": "atair_aerodynamics-cobalt"
    },
    "C5820EA7-FD28-48D5-9BEC-0D830639B02A": {
      "id": "C5820EA7-FD28-48D5-9BEC-0D830639B02A",
      "name": "Alpha",
      "category": 4,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "alpha-r35",
      "manufacturerid": "6C76AE26-F763-4536-AA22-2C4C8326804D",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/alpha-r35"
        }
      ],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Atair Aerodynamics",
      "manufacturerurl": "http://www.atair.com/parachutes/",
      "manufacturerslug": "atair_aerodynamics",
      "search": "alpha|atairaerodynamics",
      "slug": "atair_aerodynamics-alpha"
    },
    "620114F1-70C7-474A-B996-318B49AA2D9F": {
      "id": "620114F1-70C7-474A-B996-318B49AA2D9F",
      "name": "Space",
      "category": 4,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "space-r34",
      "manufacturerid": "6C76AE26-F763-4536-AA22-2C4C8326804D",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/space-r34"
        }
      ],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Atair Aerodynamics",
      "manufacturerurl": "http://www.atair.com/parachutes/",
      "manufacturerslug": "atair_aerodynamics",
      "search": "space|atairaerodynamics",
      "slug": "atair_aerodynamics-space"
    },
    "772004AF-78F6-4E1E-ADAD-DC1E3187CC7E": {
      "id": "772004AF-78F6-4E1E-ADAD-DC1E3187CC7E",
      "name": "Impulse",
      "cells": "9",
      "category": 4,
      "commontype": 0,
      "manufacturerid": "6C76AE26-F763-4536-AA22-2C4C8326804D",
      "xbraced": false,
      "links": [],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Atair Aerodynamics",
      "manufacturerurl": "http://www.atair.com/parachutes/",
      "manufacturerslug": "atair_aerodynamics",
      "search": "impulse|atairaerodynamics",
      "slug": "atair_aerodynamics-impulse"
    },
    "38533C8E-BAA0-4BC5-B12F-FE0E0E88C471": {
      "id": "38533C8E-BAA0-4BC5-B12F-FE0E0E88C471",
      "name": "Vision",
      "category": 4,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "vision-r352",
      "firstyearofproduction": "2005",
      "lastyearofproduction": "2010",
      "manufacturerid": "0BF35960-3F26-42FD-8C97-03263EC42E84",
      "maxsize": "210",
      "minsize": "90",
      "planform": "15.5",
      "planformcells": "6",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/vision-r352"
        }
      ],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Aerodyne Research",
      "manufacturerurl": "https://www.flyaerodyne.com",
      "manufacturerslug": "aerodyne_research",
      "search": "vision|aerodyneresearch",
      "slug": "aerodyne_research-vision"
    },
    "C5479C12-84C5-4FB9-BBBB-E7A9202EE41A": {
      "id": "C5479C12-84C5-4FB9-BBBB-E7A9202EE41A",
      "name": "Diablo",
      "category": 4,
      "cells": "7",
      "commontype": 1,
      "dropzoneid": "diablo-r36",
      "firstyearofproduction": "2000",
      "lastyearofproduction": "2006",
      "manufacturerid": "0BF35960-3F26-42FD-8C97-03263EC42E84",
      "maxsize": "190",
      "minsize": "88",
      "planform": "10.7",
      "planformcells": "4",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/diablo-r36"
        }
      ],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Aerodyne Research",
      "manufacturerurl": "https://www.flyaerodyne.com",
      "manufacturerslug": "aerodyne_research",
      "search": "diablo|aerodyneresearch",
      "slug": "aerodyne_research-diablo"
    },
    "6B6EEC2A-31FE-4112-9200-3F74B3E6D119": {
      "id": "6B6EEC2A-31FE-4112-9200-3F74B3E6D119",
      "name": "Zulu",
      "category": 4,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "zulu-r806",
      "firstyearofproduction": "2015",
      "manufacturerid": "0BF35960-3F26-42FD-8C97-03263EC42E84",
      "maxsize": "152",
      "minsize": "102",
      "planform": "10",
      "url": "https://www.flyaerodyne.com/zulu.asp",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/zulu-r806"
        },
        {
          "type": "youtube",
          "title": "aerodyne zulu (152ft) test jump",
          "url": "https://www.youtube.com/watch?v=aGzmbjk30KU"
        }
      ],
      "xbraced": false,
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Aerodyne Research",
      "manufacturerurl": "https://www.flyaerodyne.com",
      "manufacturerslug": "aerodyne_research",
      "search": "zulu|aerodyneresearch",
      "slug": "aerodyne_research-zulu"
    },
    "66EE6227-E990-4451-B134-43D0D51715C6": {
      "id": "66EE6227-E990-4451-B134-43D0D51715C6",
      "name": "Pro serie",
      "category": 4,
      "commontype": 0,
      "manufacturerid": "97C58924-5A47-4306-B8D4-E6A932E25A9F",
      "xbraced": false,
      "links": [],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Flight Concepts",
      "manufacturerurl": "http://flightconceptsint.com/",
      "manufacturerslug": "flight_concepts",
      "search": "proserie|flightconcepts",
      "slug": "flight_concepts-pro_serie"
    },
    "FF9B09BF-27F1-4072-B0FB-1801C0A9B89E": {
      "id": "FF9B09BF-27F1-4072-B0FB-1801C0A9B89E",
      "name": "Rage",
      "cells": "9",
      "category": 4,
      "commontype": 0,
      "dropzoneid": "rage-r469",
      "manufacturerid": "97C58924-5A47-4306-B8D4-E6A932E25A9F",
      "maxsize": "230",
      "minsize": "90",
      "remarks": {
        "en": "There is a 'Rage' from Paratec too",
        "nl": "Er bestaat ook een 'Rage' van Paratec",
        "url": "http://flightconcepts.com/the-rage/"
      },
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/rage-r469"
        }
      ],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Flight Concepts",
      "manufacturerurl": "http://flightconceptsint.com/",
      "manufacturerslug": "flight_concepts",
      "search": "rage|flightconcepts",
      "slug": "flight_concepts-rage"
    },
    "DD5B5953-DDC5-49E1-995D-1997794694D3": {
      "id": "DD5B5953-DDC5-49E1-995D-1997794694D3",
      "name": "Fandango",
      "cells": 9,
      "category": 4,
      "commontype": 0,
      "dropzoneid": "fandango-r374",
      "manufacturerid": "E1DA167E-6512-401E-9033-8621884D2C67",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/fandango-r374"
        }
      ],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Paratec",
      "manufacturerurl": "http://www.paratec.de/",
      "manufacturerslug": "paratec",
      "search": "fandango|paratec",
      "slug": "paratec-fandango"
    },
    "8B779965-13DC-4836-825F-96360ADEA300": {
      "id": "8B779965-13DC-4836-825F-96360ADEA300",
      "name": "Contrail",
      "cells": 9,
      "category": 4,
      "maxsize": "170",
      "minsize": "75",
      "commontype": 0,
      "manufacturerid": "7AE2A118-7566-456B-9D73-2E6751BF7D7A",
      "links": [
        {
          "type": "pdf",
          "title": "Manual",
          "url": "http://www.flyfirebird.com/fb/downloads/manuals/contrail.pdf"
        }
      ],
      "xbraced": false,
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Firebird",
      "manufacturerurl": "http://www.flyfirebird.com/",
      "manufacturerslug": "firebird",
      "search": "contrail|firebird",
      "slug": "firebird-contrail"
    },
    "5A77CCA4-6BCF-4A6D-87BA-091CE6397E24": {
      "id": "5A77CCA4-6BCF-4A6D-87BA-091CE6397E24",
      "name": "Heatwave",
      "category": 4,
      "cells": "9",
      "commontype": 0,
      "manufacturerid": "E0E33BAB-7067-42EC-96E3-FD6B35926A11",
      "xbraced": false,
      "links": [],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "PISA",
      "manufacturerslug": "pisa",
      "search": "heatwave|pisa",
      "slug": "pisa-heatwave"
    },
    "CBA0E65A-1984-436E-A35F-AD2C5DECB853": {
      "id": "CBA0E65A-1984-436E-A35F-AD2C5DECB853",
      "name": "Hurricane",
      "category": 4,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "hurricane-r364",
      "manufacturerid": "781D6203-9BF1-4D51-8CE0-21FFFB9D4D12",
      "maxsize": "170",
      "minsize": "95",
      "url": "http://parachutesystems.com/hurricane",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/hurricane-r364"
        },
        {
          "type": "youtube",
          "title": "parachute - fun in the clouds - Hurricane Parachute Systems",
          "url": "https://www.youtube.com/watch?v=XUQU6zattoE"
        },
        {
          "type": "youtube",
          "title": "Hurricane 120 - swoop No.1.",
          "url": "https://www.youtube.com/watch?v=4RH9G4zDxUM"
        }
      ],
      "xbraced": false,
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Parachute Systems",
      "manufacturerurl": "http://parachutesystems.com/",
      "manufacturerslug": "parachute_systems",
      "search": "hurricane|parachutesystems",
      "slug": "parachute_systems-hurricane"
    },
    "6188C8D0-8CF4-4BE4-86FE-268862413701": {
      "id": "6188C8D0-8CF4-4BE4-86FE-268862413701",
      "name": "Vixen",
      "cells": 9,
      "category": 4,
      "commontype": 0,
      "manufacturerid": "781D6203-9BF1-4D51-8CE0-21FFFB9D4D12",
      "xbraced": false,
      "links": [],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Parachute Systems",
      "manufacturerurl": "http://parachutesystems.com/",
      "manufacturerslug": "parachute_systems",
      "search": "vixen|parachutesystems",
      "slug": "parachute_systems-vixen"
    },
    "8BF69609-7C2D-4EF6-97F8-8A0530893D02": {
      "id": "8BF69609-7C2D-4EF6-97F8-8A0530893D02",
      "name": "Firebolt",
      "category": 4,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "firebolt-r367",
      "manufacturerid": "ACCD2816-15E8-4668-B217-3F345AA6854F",
      "maxsize": "396",
      "minsize": "84",
      "url": "http://www.jumpshack.com/firebolt.htm",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/firebolt-r367"
        }
      ],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Jump Shack",
      "manufacturerurl": "http://www.jumpshack.com/",
      "manufacturerslug": "jump_shack",
      "search": "firebolt|jumpshack",
      "slug": "jump_shack-firebolt"
    },
    "1FB6128A-0203-44D9-9C55-06E4289D1540": {
      "id": "1FB6128A-0203-44D9-9C55-06E4289D1540",
      "name": "Crossfire",
      "category": 4,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "crossfire-r5",
      "firstyearofproduction": "1999",
      "manufacturerid": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
      "remarks": {
        "en": "Crossfire 2 was introduced in 2001",
        "nl": "Crossfire 2 werd geïntroduceerd in 2001"
      },
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/crossfire-r5"
        }
      ],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "NZ Aerosports",
      "manufacturerurl": "http://www.nzaerosports.com",
      "manufacturerslug": "nz_aerosports",
      "search": "crossfire|nzaerosports",
      "slug": "nz_aerosports-crossfire"
    },
    "E20AECA2-ECF5-4985-9EF2-0A01130B084B": {
      "id": "E20AECA2-ECF5-4985-9EF2-0A01130B084B",
      "name": "Ventus Hybrid",
      "cells": "9",
      "maxsize": "170",
      "minsize": "105",
      "category": 4,
      "dropzoneid": "ventus-hybrid-r855",
      "commontype": 0,
      "manufacturerid": "781D6203-9BF1-4D51-8CE0-21FFFB9D4D12",
      "url": "http://parachutesystems.com/ventus",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/ventus-hybrid-r855"
        }
      ],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Parachute Systems",
      "manufacturerurl": "http://parachutesystems.com/",
      "manufacturerslug": "parachute_systems",
      "search": "ventushybrid|parachutesystems",
      "slug": "parachute_systems-ventus_hybrid"
    },
    "5DB3826F-4207-4A7A-A3A4-F968F85F1D04": {
      "id": "5DB3826F-4207-4A7A-A3A4-F968F85F1D04",
      "name": "Scorpion",
      "cells": "9",
      "maxsize": "190",
      "minsize": "97",
      "category": 4,
      "firstyearofproduction": "2020",
      "commontype": 0,
      "manufacturerid": "E1DA167E-6512-401E-9033-8621884D2C67",
      "url": "https://www.paratec.de/scorpion",
      "xbraced": false,
      "links": [],
      "displaycategory": 4,
      "calculationcategory": 4,
      "manufacturername": "Paratec",
      "manufacturerurl": "http://www.paratec.de/",
      "manufacturerslug": "paratec",
      "search": "scorpion|paratec",
      "slug": "paratec-scorpion"
    },
    "C0785556-9B3B-431E-95A0-39911DB1DBD0": {
      "id": "C0785556-9B3B-431E-95A0-39911DB1DBD0",
      "name": "Odyssey",
      "category": 5,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "odyssey-r614",
      "manufacturerid": "F497D86C-7E1E-4D4E-8FD4-AD4766AE5B15",
      "maxsize": "150",
      "minsize": "85",
      "url": "http://skylarkparachutes.com/odyssey/",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/odyssey-r614"
        }
      ],
      "displaycategory": 5,
      "calculationcategory": 5,
      "manufacturername": "Skylark Parachutes",
      "manufacturerurl": "http://skylarkparachutes.com/",
      "manufacturerslug": "skylark_parachutes",
      "search": "odyssey|skylarkparachutes",
      "slug": "skylark_parachutes-odyssey"
    },
    "19982FDF-FB8B-4178-8F34-127E40964A12": {
      "id": "19982FDF-FB8B-4178-8F34-127E40964A12",
      "name": "Odyssey EVO",
      "category": 5,
      "cells": "9",
      "commontype": 0,
      "manufacturerid": "F497D86C-7E1E-4D4E-8FD4-AD4766AE5B15",
      "maxsize": "135",
      "minsize": "75",
      "url": "http://skylarkparachutes.com/odyssey-evo/",
      "xbraced": false,
      "links": [],
      "displaycategory": 5,
      "calculationcategory": 5,
      "manufacturername": "Skylark Parachutes",
      "manufacturerurl": "http://skylarkparachutes.com/",
      "manufacturerslug": "skylark_parachutes",
      "search": "odysseyevo|skylarkparachutes",
      "slug": "skylark_parachutes-odyssey_evo"
    },
    "909AAB5D-37EA-447A-A850-4AEEE03CF1D2": {
      "id": "909AAB5D-37EA-447A-A850-4AEEE03CF1D2",
      "name": "Crossfire 2",
      "category": 5,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "crossfire-2-r238",
      "manufacturerid": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
      "maxsize": "169",
      "minsize": "89",
      "firstyearofproduction": "2001",
      "planform": "21.6",
      "planformcells": "8",
      "url": "http://www.nzaerosports.com/canopies/icarus-crossfire",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/crossfire-2-r238"
        },
        {
          "type": "youtube",
          "title": "7 minutes packing crossfire 2 99",
          "url": "https://www.youtube.com/watch?v=Yo5us-fTU34"
        },
        {
          "type": "youtube",
          "title": "First jump on 119 sqft Cross Fire 2",
          "url": "https://www.youtube.com/watch?v=Ni-O-iaya9s"
        }
      ],
      "remarks": {
        "en": "Crossfire 3 was introduced in 2016",
        "nl": "Crossfire 3 werd geïntroduceerd in 2016"
      },
      "xbraced": false,
      "displaycategory": 5,
      "calculationcategory": 5,
      "manufacturername": "NZ Aerosports",
      "manufacturerurl": "http://www.nzaerosports.com",
      "manufacturerslug": "nz_aerosports",
      "search": "crossfire2|nzaerosports",
      "slug": "nz_aerosports-crossfire_2"
    },
    "6F21BE85-8902-4392-967C-8DE5FAAD478C": {
      "id": "6F21BE85-8902-4392-967C-8DE5FAAD478C",
      "name": "Crossfire 3",
      "category": 5,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "crossfire-3-r875",
      "manufacturerid": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
      "firstyearofproduction": "2016",
      "url": "http://www.nzaerosports.com/canopies/icarus-crossfire3",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/crossfire-3-r875"
        },
        {
          "type": "vimeo",
          "title": "Crossfire 3 trailer",
          "url": "https://vimeo.com/193450039"
        },
        {
          "type": "vimeo",
          "title": "Crossfire 3: Product video",
          "url": "https://vimeo.com/193607198"
        },
        {
          "type": "vimeo",
          "title": "Crossfire 3: Design, development and flight",
          "url": "https://vimeo.com/605014241"
        },
        {
          "type": "vimeo",
          "title": "Crossfire 3: openings and landings",
          "url": "https://vimeo.com/193448494"
        },
        {
          "type": "skydivemag",
          "title": "Skydivemag Crossfire 3 Insights",
          "url": "http://www.skydivemag.com/new/2016-12-05-crossfire-3-insights"
        }
      ],
      "xbraced": false,
      "displaycategory": 5,
      "calculationcategory": 5,
      "manufacturername": "NZ Aerosports",
      "manufacturerurl": "http://www.nzaerosports.com",
      "manufacturerslug": "nz_aerosports",
      "search": "crossfire3|nzaerosports",
      "slug": "nz_aerosports-crossfire_3"
    },
    "B66CC389-2650-4205-B30B-5D279ACDE8AB": {
      "id": "B66CC389-2650-4205-B30B-5D279ACDE8AB",
      "name": "X-fire",
      "category": 5,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "x-fire-r910",
      "manufacturerid": "29A49954-54B1-4F77-A399-CCEFCCC9B3B9",
      "firstyearofproduction": "2017",
      "url": "http://www.icaruscanopies.aero/index.php?option=com_content&view=article&id=131&Itemid=675",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/x-fire-r910"
        },
        {
          "type": "youtube",
          "title": "Icarus Xfire 92 openings & flight",
          "url": "https://www.youtube.com/watch?v=eTYCKHZHePQ"
        }
      ],
      "maxsize": "168",
      "minsize": "75",
      "xbraced": false,
      "displaycategory": 5,
      "calculationcategory": 5,
      "manufacturername": "Icarus Canopies",
      "manufacturerurl": "http://www.icaruscanopies.aero/",
      "manufacturerslug": "icarus_canopies",
      "search": "xfire|icaruscanopies",
      "slug": "icarus_canopies-x-fire"
    },
    "E761C654-5896-4903-8929-581C229EE0C3": {
      "id": "E761C654-5896-4903-8929-581C229EE0C3",
      "name": "Samurai",
      "category": 5,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "samurai-r71",
      "manufacturerid": "5947BBA8-8DE6-4F61-AB89-B0B77A74B1EB",
      "maxsize": "170",
      "minsize": "95",
      "url": "http://www.bigairsportz.com/samurai.php",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/samurai-r71"
        }
      ],
      "displaycategory": 5,
      "calculationcategory": 5,
      "manufacturername": "Big-air sportz",
      "manufacturerurl": "http://www.bigairsportz.com/",
      "manufacturerslug": "big-air_sportz",
      "search": "samurai|bigairsportz",
      "slug": "big-air_sportz-samurai"
    },
    "918A79DF-E421-4207-ADFD-C15C64194642": {
      "id": "918A79DF-E421-4207-ADFD-C15C64194642",
      "name": "Cobalt competition",
      "category": 5,
      "cells": "9",
      "commontype": 0,
      "manufacturerid": "6C76AE26-F763-4536-AA22-2C4C8326804D",
      "xbraced": false,
      "links": [],
      "displaycategory": 5,
      "calculationcategory": 5,
      "manufacturername": "Atair Aerodynamics",
      "manufacturerurl": "http://www.atair.com/parachutes/",
      "manufacturerslug": "atair_aerodynamics",
      "search": "cobaltcompetition|atairaerodynamics",
      "slug": "atair_aerodynamics-cobalt_competition"
    },
    "4B50B9FE-8895-4454-A94B-4860E664883C": {
      "id": "4B50B9FE-8895-4454-A94B-4860E664883C",
      "name": "Radical",
      "category": 5,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "radical-r584",
      "manufacturerid": "6C76AE26-F763-4536-AA22-2C4C8326804D",
      "maxsize": "170",
      "minsize": "85",
      "url": "http://www.basetroll.com/products_3.html",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/radical-r584"
        }
      ],
      "displaycategory": 5,
      "calculationcategory": 5,
      "manufacturername": "Atair Aerodynamics",
      "manufacturerurl": "http://www.atair.com/parachutes/",
      "manufacturerslug": "atair_aerodynamics",
      "search": "radical|atairaerodynamics",
      "slug": "atair_aerodynamics-radical"
    },
    "9EE6B1E3-564D-4310-81FC-8BD152FD7B61": {
      "id": "9EE6B1E3-564D-4310-81FC-8BD152FD7B61",
      "name": "Jedei",
      "cells": "9",
      "category": 5,
      "commontype": 0,
      "manufacturerid": "97AD094C-A001-4928-8B4E-792EC76EFBCB",
      "xbraced": false,
      "links": [],
      "displaycategory": 5,
      "calculationcategory": 5,
      "manufacturername": "Airtime",
      "manufacturerslug": "airtime",
      "search": "jedei|airtime",
      "slug": "airtime-jedei"
    },
    "CB0F9AD7-83BF-4555-B749-09A5BC0868B7": {
      "id": "CB0F9AD7-83BF-4555-B749-09A5BC0868B7",
      "name": "Demon",
      "cells": "9",
      "category": 5,
      "commontype": 0,
      "manufacturerid": "7AE2A118-7566-456B-9D73-2E6751BF7D7A",
      "maxsize": "130",
      "minsize": "60",
      "url": "http://www.flyfirebird.com/catalog/product_info.php/products_id/61",
      "links": [
        {
          "type": "pdf",
          "title": "Manual",
          "url": "http://www.flyfirebird.com/fb/downloads/manuals/demon.pdf"
        }
      ],
      "xbraced": false,
      "displaycategory": 5,
      "calculationcategory": 5,
      "manufacturername": "Firebird",
      "manufacturerurl": "http://www.flyfirebird.com/",
      "manufacturerslug": "firebird",
      "search": "demon|firebird",
      "slug": "firebird-demon"
    },
    "D7165D47-1EAA-49C9-8B53-A47CA6F30572": {
      "id": "D7165D47-1EAA-49C9-8B53-A47CA6F30572",
      "name": "Chilli",
      "category": 5,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "chilli-r474",
      "manufacturerid": "7AE2A118-7566-456B-9D73-2E6751BF7D7A",
      "maxsize": "150",
      "minsize": "80",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/chilli-r474"
        },
        {
          "type": "pdf",
          "title": "Manual",
          "url": "http://www.flyfirebird.com/fb/downloads/manuals/chilli.pdf"
        }
      ],
      "xbraced": false,
      "displaycategory": 5,
      "calculationcategory": 5,
      "manufacturername": "Firebird",
      "manufacturerurl": "http://www.flyfirebird.com/",
      "manufacturerslug": "firebird",
      "search": "chilli|firebird",
      "slug": "firebird-chilli"
    },
    "3CADD8FE-CBFE-4390-9306-DB453623D44C": {
      "id": "3CADD8FE-CBFE-4390-9306-DB453623D44C",
      "name": "Katana",
      "category": 5,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "katana-r383",
      "firstyearofproduction": "2003",
      "manufacturerid": "541E8673-69E0-458A-8633-A3698311FD4B",
      "maxsize": "170",
      "minsize": "83",
      "url": "https://www.performancedesigns.com/products/katana/",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/katana-r383"
        },
        {
          "type": "youtube",
          "title": "PD Katana 120 openings & landings ",
          "url": "https://www.youtube.com/watch?v=0az6PofPjck"
        }
      ],
      "xbraced": false,
      "displaycategory": 5,
      "calculationcategory": 5,
      "manufacturername": "Performance Designs",
      "manufacturerurl": "https://www.performancedesigns.com/",
      "manufacturerslug": "performance_designs",
      "search": "katana|performancedesigns",
      "slug": "performance_designs-katana"
    },
    "639CE7C8-8907-4F07-9AE6-C5D61B7E8341": {
      "id": "639CE7C8-8907-4F07-9AE6-C5D61B7E8341",
      "name": "Vengeance",
      "category": 5,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "vengeance-r24",
      "manufacturerid": "541E8673-69E0-458A-8633-A3698311FD4B",
      "maxsize": "170",
      "minsize": "89",
      "remarks": {
        "en": "Airlocked",
        "nl": "Airlocked"
      },
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/vengeance-r24"
        }
      ],
      "displaycategory": 5,
      "calculationcategory": 5,
      "manufacturername": "Performance Designs",
      "manufacturerurl": "https://www.performancedesigns.com/",
      "manufacturerslug": "performance_designs",
      "search": "vengeance|performancedesigns",
      "slug": "performance_designs-vengeance"
    },
    "3E43D7CE-DDFD-446F-B6D4-BB793ABB46A6": {
      "id": "3E43D7CE-DDFD-446F-B6D4-BB793ABB46A6",
      "name": "X-Wing",
      "cells": "9",
      "category": 5,
      "commontype": 0,
      "dropzoneid": "x-wing-r375",
      "manufacturerid": "E1DA167E-6512-401E-9033-8621884D2C67",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/x-wing-r375"
        }
      ],
      "displaycategory": 5,
      "calculationcategory": 5,
      "manufacturername": "Paratec",
      "manufacturerurl": "http://www.paratec.de/",
      "manufacturerslug": "paratec",
      "search": "xwing|paratec",
      "slug": "paratec-x-wing"
    },
    "BBF8B6D2-AD53-45AF-98E5-CBD30A00ED50": {
      "id": "BBF8B6D2-AD53-45AF-98E5-CBD30A00ED50",
      "name": "Rage",
      "cells": "9",
      "category": 5,
      "commontype": 0,
      "dropzoneid": "rage-r454",
      "manufacturerid": "E1DA167E-6512-401E-9033-8621884D2C67",
      "maxsize": "107",
      "minsize": "86",
      "remarks": {
        "en": "There is a Rage from Flight Concepts too",
        "nl": "Er bestaat ook een Rage van Flight Concepts"
      },
      "url": "http://www.paratec.de/legacy/?page_id=4253",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/rage-r454"
        }
      ],
      "displaycategory": 5,
      "calculationcategory": 5,
      "manufacturername": "Paratec",
      "manufacturerurl": "http://www.paratec.de/",
      "manufacturerslug": "paratec",
      "search": "rage|paratec",
      "slug": "paratec-rage"
    },
    "51970E29-3AFA-4668-9641-4F5281140168": {
      "id": "51970E29-3AFA-4668-9641-4F5281140168",
      "name": "BT Pro",
      "category": 5,
      "cells": "9",
      "commontype": 0,
      "manufacturerid": "35AF72C6-F1D1-473D-99D0-804769E3E768",
      "xbraced": false,
      "links": [],
      "displaycategory": 5,
      "calculationcategory": 5,
      "manufacturername": "Parachutes de France",
      "manufacturerslug": "parachutes_de_france",
      "search": "btpro|parachutesdefrance",
      "slug": "parachutes_de_france-bt_pro"
    },
    "116DA4A7-448E-4E49-849C-9457E292E1AC": {
      "id": "116DA4A7-448E-4E49-849C-9457E292E1AC",
      "name": "Instinct",
      "category": 5,
      "cells": "9",
      "commontype": 0,
      "manufacturerid": "35AF72C6-F1D1-473D-99D0-804769E3E768",
      "maxsize": "110",
      "minsize": "80",
      "xbraced": false,
      "links": [],
      "displaycategory": 5,
      "calculationcategory": 5,
      "manufacturername": "Parachutes de France",
      "manufacturerslug": "parachutes_de_france",
      "search": "instinct|parachutesdefrance",
      "slug": "parachutes_de_france-instinct"
    },
    "613D4345-0C3F-4BE2-AC7D-28D209F159BA": {
      "id": "613D4345-0C3F-4BE2-AC7D-28D209F159BA",
      "name": "Nitron",
      "category": 5,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "nitron-r218",
      "manufacturerid": "7D4DAE42-062F-42A6-AD27-187B0AECDDA6",
      "maxsize": "170",
      "minsize": "78",
      "url": "http://www.precision.net/nitron.htm",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/nitron-r218"
        }
      ],
      "displaycategory": 5,
      "calculationcategory": 5,
      "manufacturername": "Precision Aerodynamics",
      "manufacturerurl": "http://www.precision.net/",
      "manufacturerslug": "precision_aerodynamics",
      "search": "nitron|precisionaerodynamics",
      "slug": "precision_aerodynamics-nitron"
    },
    "3F35D12C-4BBA-400D-BA7F-BC13512B22EB": {
      "id": "3F35D12C-4BBA-400D-BA7F-BC13512B22EB",
      "name": "Mamba",
      "category": 5,
      "cells": "9",
      "commontype": 1,
      "dropzoneid": "mamba-r442",
      "firstyearofproduction": "2005",
      "manufacturerid": "0BF35960-3F26-42FD-8C97-03263EC42E84",
      "maxsize": "150",
      "minsize": "90",
      "url": "https://www.flyaerodyne.com/mamba.asp",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/mamba-r442"
        }
      ],
      "displaycategory": 5,
      "calculationcategory": 5,
      "manufacturername": "Aerodyne Research",
      "manufacturerurl": "https://www.flyaerodyne.com",
      "manufacturerslug": "aerodyne_research",
      "search": "mamba|aerodyneresearch",
      "slug": "aerodyne_research-mamba"
    },
    "6C91684B-857B-455F-9A6B-9DC15A31E98A": {
      "id": "6C91684B-857B-455F-9A6B-9DC15A31E98A",
      "name": "Tesla",
      "maxsize": "150",
      "minsize": "97",
      "cells": "9",
      "firstyearofproduction": "2017",
      "category": 5,
      "commontype": 0,
      "manufacturerid": "932734A0-EDDD-4BED-8B8C-C329214F7F4C",
      "url": "http://www.fluidwings.com/tesla",
      "xbraced": false,
      "links": [
        {
          "type": "vimeo",
          "title": "TESLA Release Video",
          "url": "https://vimeo.com/189958872"
        },
        {
          "type": "vimeo",
          "title": "Tesla Teaser",
          "url": "https://vimeo.com/180641619"
        },
        {
          "type": "youtube",
          "title": "Fluid Wings Tesla 120 Openings",
          "url": "https://www.youtube.com/watch?v=Iq3_vH_qk7U"
        }
      ],
      "displaycategory": 5,
      "calculationcategory": 5,
      "manufacturername": "Fluid Wings",
      "manufacturerurl": "http://www.fluidwings.com/",
      "manufacturerslug": "fluid_wings",
      "search": "tesla|fluidwings",
      "slug": "fluid_wings-tesla"
    },
    "0858835E-BC3A-43D9-A085-3DF96D70D3ED": {
      "id": "0858835E-BC3A-43D9-A085-3DF96D70D3ED",
      "name": "Sensei",
      "category": 6,
      "cells": "21",
      "commontype": 1,
      "dropzoneid": "sensei-r638",
      "firstyearofproduction": "2010",
      "manufacturerid": "0BF35960-3F26-42FD-8C97-03263EC42E84",
      "maxsize": "121",
      "minsize": "71",
      "url": "https://www.flyaerodyne.com/sensei.asp",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/sensei-r638"
        },
        {
          "type": "youtube",
          "title": "Test Piloting the Sensei 91 Parachute",
          "url": "https://www.youtube.com/watch?v=AQLM9ZLpBMw"
        },
        {
          "type": "youtube",
          "title": "Sensei canopies for ToraTora",
          "url": "https://www.youtube.com/watch?v=uwShpnBkA10"
        },
        {
          "type": "skydivemag",
          "title": "Skydivemag review",
          "url": "http://www.skydivemag.com/new/2014-03-07-20131002-the-sensei"
        }
      ],
      "xbraced": true,
      "displaycategory": 6,
      "calculationcategory": 6,
      "manufacturername": "Aerodyne Research",
      "manufacturerurl": "https://www.flyaerodyne.com",
      "manufacturerslug": "aerodyne_research",
      "search": "sensei|aerodyneresearch",
      "slug": "aerodyne_research-sensei"
    },
    "858EEBF3-169F-4AE7-99A2-A7CC8D025D4E": {
      "id": "858EEBF3-169F-4AE7-99A2-A7CC8D025D4E",
      "name": "V-Max",
      "category": 6,
      "cells": "27",
      "commontype": 0,
      "dropzoneid": "v-max-r579",
      "manufacturerid": "781D6203-9BF1-4D51-8CE0-21FFFB9D4D12",
      "url": "http://parachutesystems.com/vmax",
      "xbraced": true,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/v-max-r579"
        }
      ],
      "displaycategory": 6,
      "calculationcategory": 6,
      "manufacturername": "Parachute Systems",
      "manufacturerurl": "http://parachutesystems.com/",
      "manufacturerslug": "parachute_systems",
      "search": "vmax|parachutesystems",
      "slug": "parachute_systems-v-max"
    },
    "EB83326F-B055-46C7-8754-74C9033EE41E": {
      "id": "EB83326F-B055-46C7-8754-74C9033EE41E",
      "name": "Velocity",
      "category": 6,
      "cells": "21",
      "commontype": 1,
      "dropzoneid": "velocity-r9",
      "firstyearofproduction": "1998",
      "manufacturerid": "541E8673-69E0-458A-8633-A3698311FD4B",
      "maxsize": "120",
      "minsize": "75",
      "url": "https://www.performancedesigns.com/products/velocity/",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/velocity-r9"
        },
        {
          "type": "youtube",
          "title": "How to fly and and Velocity 103",
          "url": "https://www.youtube.com/watch?v=3E7EYnOOTL0"
        },
        {
          "type": "skydivemag",
          "title": "Skydive Magazine review",
          "url": "http://www.skydivemag.com/new/2013-11-15-20131115-velocity-103"
        }
      ],
      "xbraced": true,
      "displaycategory": 6,
      "calculationcategory": 6,
      "manufacturername": "Performance Designs",
      "manufacturerurl": "https://www.performancedesigns.com/",
      "manufacturerslug": "performance_designs",
      "search": "velocity|performancedesigns",
      "slug": "performance_designs-velocity"
    },
    "EA08A05D-B79A-4687-AED8-AFB272287ADA": {
      "id": "EA08A05D-B79A-4687-AED8-AFB272287ADA",
      "name": "Comp Velocity",
      "category": 6,
      "cells": "21",
      "commontype": 1,
      "dropzoneid": "comp-velocity-r580",
      "firstyearofproduction": "2009",
      "manufacturerid": "541E8673-69E0-458A-8633-A3698311FD4B",
      "maxsize": "111",
      "minsize": "71",
      "url": "https://www.performancedesigns.com/products/compvelocity/",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/comp-velocity-r580"
        },
        {
          "type": "skydivemag",
          "title": "Skydive Magazine review",
          "url": "http://www.skydivemag.com/new/2013-09-26-20130815-competition-velocity/"
        }
      ],
      "xbraced": true,
      "displaycategory": 6,
      "calculationcategory": 6,
      "manufacturername": "Performance Designs",
      "manufacturerurl": "https://www.performancedesigns.com/",
      "manufacturerslug": "performance_designs",
      "search": "compvelocity|performancedesigns",
      "slug": "performance_designs-comp_velocity"
    },
    "F566B784-F61C-49A6-89A1-BEFF497487D2": {
      "id": "F566B784-F61C-49A6-89A1-BEFF497487D2",
      "name": "Ninja",
      "category": 6,
      "cells": "21",
      "commontype": 0,
      "dropzoneid": "ninja-r216",
      "manufacturerid": "35AF72C6-F1D1-473D-99D0-804769E3E768",
      "xbraced": true,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/ninja-r216"
        }
      ],
      "displaycategory": 6,
      "calculationcategory": 6,
      "manufacturername": "Parachutes de France",
      "manufacturerslug": "parachutes_de_france",
      "search": "ninja|parachutesdefrance",
      "slug": "parachutes_de_france-ninja"
    },
    "136EE958-6048-4129-B1B1-D0E4B11147EC": {
      "id": "136EE958-6048-4129-B1B1-D0E4B11147EC",
      "name": "Ninja 2",
      "category": 6,
      "cells": "21",
      "commontype": 0,
      "manufacturerid": "35AF72C6-F1D1-473D-99D0-804769E3E768",
      "xbraced": true,
      "links": [],
      "displaycategory": 6,
      "calculationcategory": 6,
      "manufacturername": "Parachutes de France",
      "manufacturerslug": "parachutes_de_france",
      "search": "ninja2|parachutesdefrance",
      "slug": "parachutes_de_france-ninja_2"
    },
    "67E749A9-324E-4322-B0D2-015974DD31A7": {
      "id": "67E749A9-324E-4322-B0D2-015974DD31A7",
      "name": "Xaos-21",
      "category": 6,
      "cells": "21",
      "commontype": 1,
      "dropzoneid": "xaos-21-r219",
      "manufacturerid": "7D4DAE42-062F-42A6-AD27-187B0AECDDA6",
      "maxsize": "135",
      "minsize": "68",
      "url": "http://www.precision.net/xaos-21.htm",
      "xbraced": true,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/xaos-21-r219"
        }
      ],
      "displaycategory": 6,
      "calculationcategory": 6,
      "manufacturername": "Precision Aerodynamics",
      "manufacturerurl": "http://www.precision.net/",
      "manufacturerslug": "precision_aerodynamics",
      "search": "xaos21|precisionaerodynamics",
      "slug": "precision_aerodynamics-xaos-21"
    },
    "B8B42051-2947-4461-A1A8-439BE861DB39": {
      "id": "B8B42051-2947-4461-A1A8-439BE861DB39",
      "name": "Xaos-27",
      "category": 6,
      "cells": "27",
      "commontype": 1,
      "dropzoneid": "xaos-27-r358",
      "manufacturerid": "7D4DAE42-062F-42A6-AD27-187B0AECDDA6",
      "maxsize": "118",
      "minsize": "58",
      "url": "http://www.precision.net/xaos-27.htm",
      "xbraced": true,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/xaos-27-r358"
        }
      ],
      "displaycategory": 6,
      "calculationcategory": 6,
      "manufacturername": "Precision Aerodynamics",
      "manufacturerurl": "http://www.precision.net/",
      "manufacturerslug": "precision_aerodynamics",
      "search": "xaos27|precisionaerodynamics",
      "slug": "precision_aerodynamics-xaos-27"
    },
    "084EE6A7-9F1B-4102-ABF3-8F80E4E83132": {
      "id": "084EE6A7-9F1B-4102-ABF3-8F80E4E83132",
      "name": "Extreme VX",
      "category": 6,
      "cells": "27",
      "commontype": 1,
      "dropzoneid": "extreme-vx-r3",
      "firstyearofproduction": "1999",
      "manufacturerid": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
      "maxsize": "119",
      "minsize": "59",
      "remarks": {
        "en": "Daedalus JVX was introduced in 2005",
        "nl": "Daedalus JVX werd geïntroduceerd in 2005"
      },
      "url": "http://www.nzaerosports.com/canopies/icarus-VX",
      "xbraced": true,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/extreme-vx-r3"
        }
      ],
      "displaycategory": 6,
      "calculationcategory": 6,
      "manufacturername": "NZ Aerosports",
      "manufacturerurl": "http://www.nzaerosports.com",
      "manufacturerslug": "nz_aerosports",
      "search": "extremevx|nzaerosports",
      "slug": "nz_aerosports-extreme_vx"
    },
    "65BE2372-98A5-4109-9D88-16550BD74A3D": {
      "id": "65BE2372-98A5-4109-9D88-16550BD74A3D",
      "name": "Extreme FX",
      "category": 6,
      "cells": "21",
      "commontype": 1,
      "dropzoneid": "extreme-fx-r6",
      "firstyearofproduction": "1997",
      "manufacturerid": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
      "maxsize": "119",
      "minsize": "59",
      "remarks": {
        "en": "Daedalus JFX was introduced in 2010",
        "nl": "Daedalus JFX werd geïntroduceerd in 2010"
      },
      "url": "http://www.nzaerosports.com/canopies/icarus-FX",
      "xbraced": true,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/extreme-fx-r6"
        }
      ],
      "displaycategory": 6,
      "calculationcategory": 6,
      "manufacturername": "NZ Aerosports",
      "manufacturerurl": "http://www.nzaerosports.com",
      "manufacturerslug": "nz_aerosports",
      "search": "extremefx|nzaerosports",
      "slug": "nz_aerosports-extreme_fx"
    },
    "F996C3D1-5684-45BF-9B30-9B0ADEC987C2": {
      "id": "F996C3D1-5684-45BF-9B30-9B0ADEC987C2",
      "name": "Neos",
      "category": 6,
      "cells": "21",
      "commontype": 1,
      "dropzoneid": "neos-r456",
      "manufacturerid": "29A49954-54B1-4F77-A399-CCEFCCC9B3B9",
      "maxsize": "119",
      "minsize": "59",
      "url": "http://www.icaruscanopies.aero/index.php?option=com_content&view=article&id=19&Itemid=18",
      "xbraced": true,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/neos-r456"
        },
        {
          "type": "youtube",
          "title": "Swoop Neos",
          "url": "https://www.youtube.com/watch?v=QWTfOFW4YgA"
        }
      ],
      "displaycategory": 6,
      "calculationcategory": 6,
      "manufacturername": "Icarus Canopies",
      "manufacturerurl": "http://www.icaruscanopies.aero/",
      "manufacturerslug": "icarus_canopies",
      "search": "neos|icaruscanopies",
      "slug": "icarus_canopies-neos"
    },
    "86F21B4A-D883-4391-A792-D2F43259942B": {
      "id": "86F21B4A-D883-4391-A792-D2F43259942B",
      "name": "Daedalus JVX",
      "category": 6,
      "cells": "27",
      "commontype": 0,
      "dropzoneid": "jvx-r639",
      "firstyearofproduction": "2005",
      "manufacturerid": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
      "maxsize": "119",
      "minsize": "59",
      "url": "http://www.nzaerosports.com/canopies/daedalus-JVX",
      "xbraced": true,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/jvx-r639"
        },
        {
          "type": "youtube",
          "title": "The World's Smallest Parachute",
          "url": "https://www.youtube.com/watch?v=yW3pimvJSro"
        }
      ],
      "displaycategory": 6,
      "calculationcategory": 6,
      "manufacturername": "NZ Aerosports",
      "manufacturerurl": "http://www.nzaerosports.com",
      "manufacturerslug": "nz_aerosports",
      "search": "daedalusjvx|nzaerosports",
      "slug": "nz_aerosports-daedalus_jvx"
    },
    "AD3229CE-7B38-4BC8-A1C6-73D6DF651198": {
      "id": "AD3229CE-7B38-4BC8-A1C6-73D6DF651198",
      "name": "Daedalus JFX",
      "category": 6,
      "cells": "21",
      "commontype": 0,
      "dropzoneid": "jfx-r640",
      "firstyearofproduction": "2010",
      "manufacturerid": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
      "maxsize": "119",
      "minsize": "59",
      "url": "http://www.nzaerosports.com/canopies/daedalus-JFX",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/jfx-r640"
        },
        {
          "type": "youtube",
          "title": "Daedalus JFX Opening: Camera by Nick Batsch",
          "url": "https://www.youtube.com/watch?v=IK3uWGdh6eA"
        }
      ],
      "xbraced": true,
      "displaycategory": 6,
      "calculationcategory": 6,
      "manufacturername": "NZ Aerosports",
      "manufacturerurl": "http://www.nzaerosports.com",
      "manufacturerslug": "nz_aerosports",
      "search": "daedalusjfx|nzaerosports",
      "slug": "nz_aerosports-daedalus_jfx"
    },
    "47F5A0C0-1B04-4F5F-B9BF-6569E72C6121": {
      "id": "47F5A0C0-1B04-4F5F-B9BF-6569E72C6121",
      "name": "Icarus JFX 2",
      "category": 6,
      "cells": "21",
      "commontype": 0,
      "firstyearofproduction": "2018",
      "manufacturerid": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
      "maxsize": "119",
      "minsize": "59",
      "url": "http://www.nzaerosports.com/canopies/icarus-jfx2",
      "xbraced": true,
      "links": [],
      "displaycategory": 6,
      "calculationcategory": 6,
      "manufacturername": "NZ Aerosports",
      "manufacturerurl": "http://www.nzaerosports.com",
      "manufacturerslug": "nz_aerosports",
      "search": "icarusjfx2|nzaerosports",
      "slug": "nz_aerosports-icarus_jfx_2"
    },
    "3193501C-9111-4782-B0EC-778CD6923E08": {
      "id": "3193501C-9111-4782-B0EC-778CD6923E08",
      "name": "Onyx",
      "cells": "27",
      "category": 6,
      "commontype": 0,
      "dropzoneid": "onyx-r390",
      "manufacturerid": "6C76AE26-F763-4536-AA22-2C4C8326804D",
      "xbraced": true,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/onyx-r390"
        }
      ],
      "displaycategory": 6,
      "calculationcategory": 6,
      "manufacturername": "Atair Aerodynamics",
      "manufacturerurl": "http://www.atair.com/parachutes/",
      "manufacturerslug": "atair_aerodynamics",
      "search": "onyx|atairaerodynamics",
      "slug": "atair_aerodynamics-onyx"
    },
    "9556811C-4FA7-4A1A-9915-61295E9EC259": {
      "id": "9556811C-4FA7-4A1A-9915-61295E9EC259",
      "name": "Echo",
      "maxsize": "170",
      "minsize": "107",
      "cells": "9",
      "firstyearofproduction": "2019",
      "category": null,
      "commontype": 0,
      "manufacturerid": "932734A0-EDDD-4BED-8B8C-C329214F7F4C",
      "url": "https://www.fluidwings.com/echo",
      "xbraced": false,
      "links": [
        {
          "type": "vimeo",
          "title": "Echo Teaser",
          "url": "https://vimeo.com/316856335"
        }
      ],
      "displaycategory": "?",
      "calculationcategory": 6,
      "manufacturername": "Fluid Wings",
      "manufacturerurl": "http://www.fluidwings.com/",
      "manufacturerslug": "fluid_wings",
      "search": "echo|fluidwings",
      "slug": "fluid_wings-echo"
    },
    "13C2B0F2-82C3-4996-A8D8-D42872879587": {
      "id": "13C2B0F2-82C3-4996-A8D8-D42872879587",
      "name": "Gangster",
      "maxsize": "135",
      "minsize": "69",
      "cells": "21",
      "firstyearofproduction": "2017",
      "category": 6,
      "commontype": 0,
      "manufacturerid": "932734A0-EDDD-4BED-8B8C-C329214F7F4C",
      "url": "https://www.fluidwings.com/gangster",
      "xbraced": true,
      "remarks": {
        "en": "Partially braced",
        "nl": "Partially braced "
      },
      "links": [],
      "displaycategory": 6,
      "calculationcategory": 6,
      "manufacturername": "Fluid Wings",
      "manufacturerurl": "http://www.fluidwings.com/",
      "manufacturerslug": "fluid_wings",
      "search": "gangster|fluidwings",
      "slug": "fluid_wings-gangster"
    },
    "1931C950-68C7-11E2-BCFD-0800200C9A66": {
      "id": "1931C950-68C7-11E2-BCFD-0800200C9A66",
      "name": "Peregrine",
      "category": 7,
      "cells": "27",
      "commontype": 0,
      "dropzoneid": "peregrine-r772",
      "manufacturerid": "541E8673-69E0-458A-8633-A3698311FD4B",
      "remarks": {
        "en": "Not freely available",
        "nl": "Niet vrij beschikbaar"
      },
      "url": "https://www.performancedesigns.com/products/peregrine/",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/peregrine-r772"
        },
        {
          "type": "youtube",
          "title": "PD Peregrine promo",
          "url": "https://www.youtube.com/watch?v=Tj3U5QJbaAc"
        },
        {
          "type": "youtube",
          "title": "Distance swoop Peregrine 61",
          "url": "https://www.youtube.com/watch?v=ewQdizH4oaM"
        },
        {
          "type": "youtube",
          "title": "Peregrine XRW (met wingsuit)",
          "url": "https://www.youtube.com/watch?v=dPAcENXxtnw"
        }
      ],
      "xbraced": true,
      "displaycategory": 7,
      "calculationcategory": 7,
      "manufacturername": "Performance Designs",
      "manufacturerurl": "https://www.performancedesigns.com/",
      "manufacturerslug": "performance_designs",
      "search": "peregrine|performancedesigns",
      "slug": "performance_designs-peregrine"
    },
    "23498D9C-045C-4D99-AAD7-E01CFDBC717B": {
      "id": "23498D9C-045C-4D99-AAD7-E01CFDBC717B",
      "name": "Valkyrie",
      "category": 7,
      "cells": "21",
      "commontype": 0,
      "dropzoneid": "valkyrie-r852",
      "firstyearofproduction": "2014",
      "manufacturerid": "541E8673-69E0-458A-8633-A3698311FD4B",
      "maxsize": "103",
      "minsize": "67",
      "url": "https://www.performancedesigns.com/products/valkyrie/",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/valkyrie-r852"
        },
        {
          "type": "youtube",
          "title": "PD Valkyrie promo",
          "url": "https://www.youtube.com/watch?v=LJr70PKbgOE"
        },
        {
          "type": "youtube",
          "title": "Flight of the Valkyries",
          "url": "https://www.youtube.com/watch?v=rrr4BlBLFTY"
        },
        {
          "type": "skydivemag",
          "title": "Skydive Magazine review",
          "url": "http://www.skydivemag.com/new/2017-03-06-valkyrie-review"
        }
      ],
      "xbraced": true,
      "displaycategory": 7,
      "calculationcategory": 7,
      "manufacturername": "Performance Designs",
      "manufacturerurl": "https://www.performancedesigns.com/",
      "manufacturerslug": "performance_designs",
      "search": "valkyrie|performancedesigns",
      "slug": "performance_designs-valkyrie"
    },
    "0b8688c1-bb96-41d4-ac0f-8c9c71f54eda": {
      "id": "0b8688c1-bb96-41d4-ac0f-8c9c71f54eda",
      "name": "Petra",
      "category": 7,
      "cells": "27",
      "commontype": 0,
      "dropzoneid": "petra-r840",
      "firstyearofproduction": "2013",
      "manufacturerid": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
      "url": "http://www.nzaerosports.com/gallery/jpx-petra",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/petra-r840"
        },
        {
          "type": "youtube",
          "title": "Petra 69 Project: new canopy flown by Nick Batsch",
          "url": "https://www.youtube.com/watch?v=n_oMXHn427w"
        },
        {
          "type": "youtube",
          "title": "Petra Terminal Openings",
          "url": "https://www.youtube.com/watch?v=ot7Bi5dpdRA"
        },
        {
          "type": "youtube",
          "title": "Petra 86 Prototype: Flown by Patrick Boulongne",
          "url": "https://www.youtube.com/watch?v=J2wKPYvNBdk"
        },
        {
          "type": "youtube",
          "title": "Tech Toys 360 preview: Petra Parachute",
          "url": "https://www.youtube.com/watch?v=BetKPFN_VUY"
        }
      ],
      "remarks": {
        "en": "To order NZA requires at least 1500 jumps, of which at least 300 on a crossbraced canopy with a similar wing loading than the Leia odered; 150 jumps in the last 6 months; 2 references from coach or Petra/Peregrine jumper.",
        "nl": "Om te bestellen eist NZA minimaal 1500 sprongen, waarvan minstens 300 onder een crossbraced koepel met een soorgelijke wingload als de bestelde Leia; 150 sprongen in de laatste 6 maanden; 2 referenties van een coach of Petra/Peregrine springer."
      },
      "xbraced": true,
      "displaycategory": 7,
      "calculationcategory": 7,
      "manufacturername": "NZ Aerosports",
      "manufacturerurl": "http://www.nzaerosports.com",
      "manufacturerslug": "nz_aerosports",
      "search": "petra|nzaerosports",
      "slug": "nz_aerosports-petra"
    },
    "de98ce5e-513e-454a-bfdb-fc8ff93ee92e": {
      "id": "de98ce5e-513e-454a-bfdb-fc8ff93ee92e",
      "name": "Leia",
      "category": 7,
      "cells": "21",
      "commontype": 0,
      "dropzoneid": "leia-r839",
      "firstyearofproduction": "2014",
      "manufacturerid": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
      "url": "http://www.nzaerosports.com/gallery/daedalus-leia",
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/leia-r839"
        },
        {
          "type": "youtube",
          "title": "Petra Project Episode II: Leia",
          "url": "https://www.youtube.com/watch?v=zomcgbDLTPI"
        }
      ],
      "remarks": {
        "en": "To order NZA requires at least 1500 jumps, of which at least 300 on a crossbraced canopy with a similar wing loading than the Leia odered; 150 jumps in the last 6 months.",
        "nl": "Om te bestellen eist NZA minimaal 1500 sprongen, waarvan minstens 300 onder een crossbraced koepel met een soorgelijke wingload als de bestelde Leia; 150 sprongen in de laatste 6 maanden."
      },
      "xbraced": true,
      "displaycategory": 7,
      "calculationcategory": 7,
      "manufacturername": "NZ Aerosports",
      "manufacturerurl": "http://www.nzaerosports.com",
      "manufacturerslug": "nz_aerosports",
      "search": "leia|nzaerosports",
      "slug": "nz_aerosports-leia"
    },
    "6334C760-93BD-41D1-90B7-4A63678177E7": {
      "id": "6334C760-93BD-41D1-90B7-4A63678177E7",
      "name": "SLeia",
      "category": 7,
      "cells": "21",
      "commontype": 0,
      "firstyearofproduction": "2018",
      "manufacturerid": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
      "url": "http://www.nzaerosports.com/canopies/daedalus-sleia/",
      "remarks": {
        "en": "To order NZA requires at least 1500 jumps, of which at least 300 on a crossbraced canopy with a similar wing loading than the Leia odered; 150 jumps in the last 6 months.",
        "nl": "Om te bestellen eist NZA minimaal 1500 sprongen, waarvan minstens 300 onder een crossbraced koepel met een soorgelijke wingload als de bestelde Leia; 150 sprongen in de laatste 6 maanden."
      },
      "xbraced": true,
      "links": [],
      "displaycategory": 7,
      "calculationcategory": 7,
      "manufacturername": "NZ Aerosports",
      "manufacturerurl": "http://www.nzaerosports.com",
      "manufacturerslug": "nz_aerosports",
      "search": "sleia|nzaerosports",
      "slug": "nz_aerosports-sleia"
    },
    "30592423-330F-4E41-9BCD-9CA85E06D425": {
      "id": "30592423-330F-4E41-9BCD-9CA85E06D425",
      "name": "Helix",
      "cells": "21",
      "maxsize": "96",
      "minsize": "67",
      "firstyearofproduction": "2015",
      "category": 7,
      "commontype": 0,
      "manufacturerid": "932734A0-EDDD-4BED-8B8C-C329214F7F4C",
      "url": "http://www.fluidwings.com/#!helix/cczj",
      "links": [
        {
          "type": "vimeo",
          "title": "Helix release video",
          "url": "https://vimeo.com/134061502"
        },
        {
          "type": "youtube",
          "title": "Fluid Wings Helix 84 SN:13",
          "url": "https://www.youtube.com/watch?v=Q7m9OT3VSnE"
        }
      ],
      "xbraced": true,
      "displaycategory": 7,
      "calculationcategory": 7,
      "manufacturername": "Fluid Wings",
      "manufacturerurl": "http://www.fluidwings.com/",
      "manufacturerslug": "fluid_wings",
      "search": "helix|fluidwings",
      "slug": "fluid_wings-helix"
    },
    "AF1933DE-FA7F-4753-8DA1-713035A955DD": {
      "id": "AF1933DE-FA7F-4753-8DA1-713035A955DD",
      "name": "HS",
      "cells": "9",
      "category": 7,
      "commontype": 0,
      "manufacturerid": "932734A0-EDDD-4BED-8B8C-C329214F7F4C",
      "url": "http://www.fluidwings.com/hs",
      "xbraced": true,
      "links": [],
      "displaycategory": 7,
      "calculationcategory": 7,
      "manufacturername": "Fluid Wings",
      "manufacturerurl": "http://www.fluidwings.com/",
      "manufacturerslug": "fluid_wings",
      "search": "hs|fluidwings",
      "slug": "fluid_wings-hs"
    },
    "9E7E3E3C-F4DE-41EE-A08B-15E8CCA08B9A": {
      "id": "9E7E3E3C-F4DE-41EE-A08B-15E8CCA08B9A",
      "name": "HK",
      "cells": "27",
      "maxsize": "84",
      "minsize": "64",
      "firstyearofproduction": "2017",
      "lastyearofproduction": "2020",
      "category": 7,
      "commontype": 0,
      "manufacturerid": "932734A0-EDDD-4BED-8B8C-C329214F7F4C",
      "url": "http://www.fluidwings.com/hk",
      "xbraced": true,
      "links": [
        {
          "type": "youtube",
          "title": "Fluid Wings HK 60",
          "url": "https://www.youtube.com/watch?v=XL_9JGr-a-o"
        },
        {
          "type": "youtube",
          "title": "Canopy Action 2017, Timmi, Fly Fluid",
          "url": "https://www.youtube.com/watch?v=Day0Zqw-SqY"
        },
        {
          "type": "skydivemag",
          "title": "Skydivemag Fluid Wings Releases Competition Wing",
          "url": "http://www.skydivemag.com/new/fluid-wings-releases-competition-wing"
        }
      ],
      "displaycategory": 7,
      "calculationcategory": 7,
      "manufacturername": "Fluid Wings",
      "manufacturerurl": "http://www.fluidwings.com/",
      "manufacturerslug": "fluid_wings",
      "search": "hk|fluidwings",
      "slug": "fluid_wings-hk"
    },
    "AF70A061-2995-47E3-A68B-12F4B68385CC": {
      "id": "AF70A061-2995-47E3-A68B-12F4B68385CC",
      "name": "HK2",
      "cells": "27",
      "maxsize": "84",
      "minsize": "64",
      "firstyearofproduction": "2020",
      "category": null,
      "commontype": 0,
      "manufacturerid": "932734A0-EDDD-4BED-8B8C-C329214F7F4C",
      "url": "http://www.fluidwings.com/hk2",
      "xbraced": true,
      "links": [
        {
          "type": "vimeo",
          "title": "New Game - Fluid Wings HK2 Launch",
          "url": "https://vimeo.com/443516186"
        },
        {
          "type": "youtube",
          "title": "2020 Opening Weekends - Fluid Wings HK2-69",
          "url": "https://www.youtube.com/watch?v=xiBv1WY47J8"
        }
      ],
      "displaycategory": "?",
      "calculationcategory": 7,
      "manufacturername": "Fluid Wings",
      "manufacturerurl": "http://www.fluidwings.com/",
      "manufacturerslug": "fluid_wings",
      "search": "hk2|fluidwings",
      "slug": "fluid_wings-hk2"
    },
    "DC694B4B-EF96-4E4C-993C-601929279178": {
      "id": "DC694B4B-EF96-4E4C-993C-601929279178",
      "name": "HK Terminal",
      "cells": "27",
      "maxsize": "84",
      "minsize": "64",
      "firstyearofproduction": "2017",
      "category": 7,
      "commontype": 0,
      "manufacturerid": "932734A0-EDDD-4BED-8B8C-C329214F7F4C",
      "url": "http://www.fluidwings.com/hkterminal",
      "xbraced": true,
      "links": [],
      "displaycategory": 7,
      "calculationcategory": 7,
      "manufacturername": "Fluid Wings",
      "manufacturerurl": "http://www.fluidwings.com/",
      "manufacturerslug": "fluid_wings",
      "search": "hkterminal|fluidwings",
      "slug": "fluid_wings-hk_terminal"
    },
    "E0142D15-96D2-461C-8915-9C1625C9C5EB": {
      "id": "E0142D15-96D2-461C-8915-9C1625C9C5EB",
      "name": "Airwolf",
      "cells": "7",
      "maxsize": "96",
      "minsize": "67",
      "firstyearofproduction": "2017",
      "category": 7,
      "commontype": 0,
      "manufacturerid": "932734A0-EDDD-4BED-8B8C-C329214F7F4C",
      "url": "http://www.fluidwings.com/airwolf",
      "xbraced": true,
      "links": [
        {
          "type": "vimeo",
          "title": "AirWolf Release Video",
          "url": "https://vimeo.com/221748201"
        },
        {
          "type": "youtube",
          "title": "Airwolf 90",
          "url": "https://www.youtube.com/watch?v=W6gnL5O5YtI"
        },
        {
          "type": "youtube",
          "title": "Fluid Wings Airwolf 79 @ 2.72",
          "url": "https://www.youtube.com/watch?v=J1Wc2aSCv18"
        },
        {
          "type": "youtube",
          "title": "Fluid Wings AirWolf 90 2.7 loading",
          "url": "https://www.youtube.com/watch?v=v569hpZwrmg"
        }
      ],
      "displaycategory": 7,
      "calculationcategory": 7,
      "manufacturername": "Fluid Wings",
      "manufacturerurl": "http://www.fluidwings.com/",
      "manufacturerslug": "fluid_wings",
      "search": "airwolf|fluidwings",
      "slug": "fluid_wings-airwolf"
    },
    "1802CFF1-E4F6-40D7-895E-D3F8EE01B7C3": {
      "id": "1802CFF1-E4F6-40D7-895E-D3F8EE01B7C3",
      "name": "Wairwolf",
      "cells": "7",
      "maxsize": "96",
      "minsize": "67",
      "firstyearofproduction": "2020",
      "category": null,
      "commontype": 0,
      "manufacturerid": "932734A0-EDDD-4BED-8B8C-C329214F7F4C",
      "url": "http://www.fluidwings.com/wairwolf",
      "xbraced": true,
      "links": [
        {
          "type": "vimeo",
          "title": "WairWolf Release Video",
          "url": "https://vimeo.com/370386214"
        },
        {
          "type": "vimeo",
          "title": "WairWolf 79 cutaway",
          "url": "https://vimeo.com/398312832"
        }
      ],
      "remarks": {
        "en": "The Wairwolf is a limited-release wing, a significant step up from the Airwolf",
        "nl": "The Wairwolf is a limited-release koepel, met verbeteringen ten opzichte van de Airwolf"
      },
      "displaycategory": "?",
      "calculationcategory": 7,
      "manufacturername": "Fluid Wings",
      "manufacturerurl": "http://www.fluidwings.com/",
      "manufacturerslug": "fluid_wings",
      "search": "wairwolf|fluidwings",
      "slug": "fluid_wings-wairwolf"
    },
    "A8C274D3-DE56-413D-AAA2-C8EE6D7CA3BE": {
      "id": "A8C274D3-DE56-413D-AAA2-C8EE6D7CA3BE",
      "name": "Sonic",
      "cells": "9",
      "maxsize": "220",
      "minsize": "120",
      "category": null,
      "commontype": 0,
      "dropzoneid": "sonic-r824",
      "manufacturerid": "A82F2C34-DBA9-4A4B-8DE7-7319B00C7DB4",
      "url": "http://www.jojowing.com/cs/sonic/",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/sonic-r824"
        }
      ],
      "displaycategory": "?",
      "calculationcategory": 6,
      "manufacturername": "Jojowings",
      "manufacturerurl": "http://www.jojowing.com/",
      "manufacturerslug": "jojowings",
      "search": "sonic|jojowings",
      "slug": "jojowings-sonic"
    },
    "CDC9F86A-5F6C-4334-8887-4A4AC7D6C24E": {
      "id": "CDC9F86A-5F6C-4334-8887-4A4AC7D6C24E",
      "name": "Raptor",
      "cells": "9",
      "maxsize": "170",
      "minsize": "108",
      "category": null,
      "commontype": 0,
      "manufacturerid": "A82F2C34-DBA9-4A4B-8DE7-7319B00C7DB4",
      "url": "http://www.jojowing.com/cs/raptor/",
      "xbraced": false,
      "links": [],
      "displaycategory": "?",
      "calculationcategory": 6,
      "manufacturername": "Jojowings",
      "manufacturerurl": "http://www.jojowing.com/",
      "manufacturerslug": "jojowings",
      "search": "raptor|jojowings",
      "slug": "jojowings-raptor"
    },
    "89DA27EF-860B-4FF9-9917-EF81A266FAFB": {
      "id": "89DA27EF-860B-4FF9-9917-EF81A266FAFB",
      "name": "XF15",
      "cells": "21",
      "maxsize": "115",
      "minsize": "85",
      "category": null,
      "commontype": 0,
      "manufacturerid": "A82F2C34-DBA9-4A4B-8DE7-7319B00C7DB4",
      "url": "http://www.jojowing.com/cs/xf15/",
      "xbraced": true,
      "links": [],
      "displaycategory": "?",
      "calculationcategory": 7,
      "manufacturername": "Jojowings",
      "manufacturerurl": "http://www.jojowing.com/",
      "manufacturerslug": "jojowings",
      "search": "xf15|jojowings",
      "slug": "jojowings-xf15"
    },
    "6B1E0B26-043D-48EE-883F-59C60BB8F04F": {
      "id": "6B1E0B26-043D-48EE-883F-59C60BB8F04F",
      "name": "Axon",
      "category": null,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "ipt-axon-r439",
      "firstyearofproduction": "2019",
      "maxsize": "100",
      "minsize": "260",
      "manufacturerid": "75B438C0-B3B7-4D3E-9D64-6058A351A82F",
      "url": "https://www.innovativeparachute.com/axon",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/ipt-axon-r439"
        }
      ],
      "displaycategory": "?",
      "calculationcategory": 6,
      "manufacturername": "Innovative Parachute Technologies",
      "manufacturerslug": "innovative_parachute_technologies",
      "search": "axon|innovativeparachutetechnologies",
      "slug": "innovative_parachute_technologies-axon"
    },
    "770353E7-3B9A-4969-AE70-268DADA2EF23": {
      "id": "770353E7-3B9A-4969-AE70-268DADA2EF23",
      "name": "Furai",
      "category": null,
      "cells": "9",
      "commontype": 0,
      "dropzoneid": "ipt-furai-r440",
      "firstyearofproduction": "2019",
      "maxsize": "100",
      "minsize": "190",
      "manufacturerid": "75B438C0-B3B7-4D3E-9D64-6058A351A82F",
      "url": "https://www.innovativeparachute.com/furai",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/ipt-furai-r440"
        }
      ],
      "displaycategory": "?",
      "calculationcategory": 6,
      "manufacturername": "Innovative Parachute Technologies",
      "manufacturerslug": "innovative_parachute_technologies",
      "search": "furai|innovativeparachutetechnologies",
      "slug": "innovative_parachute_technologies-furai"
    },
    "BF43A017-C447-4FF6-9685-D57DD628A6D1": {
      "id": "BF43A017-C447-4FF6-9685-D57DD628A6D1",
      "name": "Vapor",
      "category": null,
      "cells": "7",
      "commontype": 0,
      "dropzoneid": "vapor-r960",
      "firstyearofproduction": "2019",
      "maxsize": "100",
      "minsize": "230",
      "manufacturerid": "75B438C0-B3B7-4D3E-9D64-6058A351A82F",
      "url": "https://www.innovativeparachute.com/vapor",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/vapor-r960"
        }
      ],
      "displaycategory": "?",
      "calculationcategory": 6,
      "manufacturername": "Innovative Parachute Technologies",
      "manufacturerslug": "innovative_parachute_technologies",
      "search": "vapor|innovativeparachutetechnologies",
      "slug": "innovative_parachute_technologies-vapor"
    },
    "00CA8EEA-7D47-43EC-8696-DDC49D54C4D8": {
      "id": "00CA8EEA-7D47-43EC-8696-DDC49D54C4D8",
      "name": "Helios",
      "category": null,
      "cells": "7",
      "commontype": 0,
      "dropzoneid": "helios-r959",
      "firstyearofproduction": "2019",
      "manufacturerid": "75B438C0-B3B7-4D3E-9D64-6058A351A82F",
      "url": "https://www.innovativeparachute.com/helios",
      "xbraced": false,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/helios-r959"
        }
      ],
      "displaycategory": "?",
      "calculationcategory": 6,
      "manufacturername": "Innovative Parachute Technologies",
      "manufacturerslug": "innovative_parachute_technologies",
      "search": "helios|innovativeparachutetechnologies",
      "slug": "innovative_parachute_technologies-helios"
    },
    "A2252BB9-ED7C-4EEF-AB7B-18B6B4DC774E": {
      "id": "A2252BB9-ED7C-4EEF-AB7B-18B6B4DC774E",
      "name": "Scirocco",
      "category": 7,
      "cells": "30",
      "commontype": 0,
      "dropzoneid": "scirocco-r636",
      "manufacturerid": "F497D86C-7E1E-4D4E-8FD4-AD4766AE5B15",
      "maxsize": "124",
      "minsize": "64",
      "url": "http://skylarkparachutes.com/scirocco",
      "xbraced": true,
      "links": [
        {
          "type": "dropzone.com",
          "title": "Dropzone.com",
          "url": "https://www.dropzone.com/gear/main-canopies/scirocco-r636"
        },
        {
          "type": "youtube",
          "title": "Packing Scirocco",
          "url": "https://www.youtube.com/watch?v=TyEshNybpng"
        },
        {
          "type": "youtube",
          "title": "Scirocco 2-way floсking",
          "url": "https://www.youtube.com/watch?v=SEvR-pgquw8"
        }
      ],
      "displaycategory": 7,
      "calculationcategory": 7,
      "manufacturername": "Skylark Parachutes",
      "manufacturerurl": "http://skylarkparachutes.com/",
      "manufacturerslug": "skylark_parachutes",
      "search": "scirocco|skylarkparachutes",
      "slug": "skylark_parachutes-scirocco"
    },
    "71605FA8-1F08-41EF-98E0-339005AA2D4A": {
      "id": "71605FA8-1F08-41EF-98E0-339005AA2D4A",
      "name": "XTC",
      "cells": "9",
      "maxsize": "94",
      "minsize": "74",
      "category": 7,
      "firstyearofproduction": "2020",
      "commontype": 0,
      "manufacturerid": "E1DA167E-6512-401E-9033-8621884D2C67",
      "url": "https://www.paratec.de/xtc",
      "xbraced": true,
      "links": [],
      "displaycategory": 7,
      "calculationcategory": 7,
      "manufacturername": "Paratec",
      "manufacturerurl": "http://www.paratec.de/",
      "manufacturerslug": "paratec",
      "search": "xtc|paratec",
      "slug": "paratec-xtc"
    }
  },
  "canopiesByName": [
    "F0C4AF1D-A9BE-4560-B8E4-4F38616B30D4",
    "E0142D15-96D2-461C-8915-9C1625C9C5EB",
    "C5820EA7-FD28-48D5-9BEC-0D830639B02A",
    "6B1E0B26-043D-48EE-883F-59C60BB8F04F",
    "600122AA-A33A-4AF6-A326-8B9E251219D9",
    "2A2D16C5-D208-44AB-AF97-1398405EC1A5",
    "F0B876B6-F2C6-4560-BF4D-A22DD3C070ED",
    "51970E29-3AFA-4668-9641-4F5281140168",
    "F1269861-262D-4CCC-A8FA-4C190B59C0C6",
    "D7165D47-1EAA-49C9-8B53-A47CA6F30572",
    "7017A355-35E0-4578-A81D-89CE8F021AAA",
    "3A6927EF-1272-48DF-BB2A-130E960FA1E8",
    "9634AACA-2A16-42B6-A687-6536DE20D3F7",
    "918A79DF-E421-4207-ADFD-C15C64194642",
    "21BD9B3B-77E6-4605-B89A-0B118C97C960",
    "EA08A05D-B79A-4687-AED8-AFB272287ADA",
    "8B779965-13DC-4836-825F-96360ADEA300",
    "428AC10E-5398-4593-852B-EBD29E451899",
    "1FB6128A-0203-44D9-9C55-06E4289D1540",
    "909AAB5D-37EA-447A-A850-4AEEE03CF1D2",
    "6F21BE85-8902-4392-967C-8DE5FAAD478C",
    "2B6BAAC0-2E47-48BF-B966-137373CA9CA9",
    "AD3229CE-7B38-4BC8-A1C6-73D6DF651198",
    "86F21B4A-D883-4391-A792-D2F43259942B",
    "CB0F9AD7-83BF-4555-B749-09A5BC0868B7",
    "C5479C12-84C5-4FB9-BBBB-E7A9202EE41A",
    "037A12CF-5B9F-4B20-82C1-4A5FB376B38D",
    "4BBA3DB9-9EC3-4EAD-BC59-C72744F4EE02",
    "747B45F3-B12C-4743-A5F8-2D90C20F22F1",
    "128B96F9-DAC3-4297-8329-FC24CCF9E781",
    "9556811C-4FA7-4A1A-9915-61295E9EC259",
    "9BADF237-317E-4B64-BF72-9A476F85CA6B",
    "85990128-ED3F-442D-9D76-373E3F267CDA",
    "9B97CE42-AF52-41E6-828C-9D0EF103B7A3",
    "2351611F-068C-4901-820D-26EC38FC36D9",
    "89DCF61C-2FE5-4717-B14F-B5E8BB266DFB",
    "CCD4E177-0E52-4837-BCA8-0640570E41E6",
    "C6C59DF8-9127-4BCA-B320-2BFE21AAA1A8",
    "65BE2372-98A5-4109-9D88-16550BD74A3D",
    "084EE6A7-9F1B-4102-ABF3-8F80E4E83132",
    "1AFA9F7D-F7C2-4807-80EA-B394D15AAFE9",
    "DD5B5953-DDC5-49E1-995D-1997794694D3",
    "ABB72EAB-6276-42E8-9681-19FBCB846FC1",
    "8BF69609-7C2D-4EF6-97F8-8A0530893D02",
    "371ABAEA-0A5B-4832-BB52-135A20A2C74F",
    "770353E7-3B9A-4969-AE70-268DADA2EF23",
    "E1297544-310F-4FBD-9436-98368303E71E",
    "8A64E433-8856-4329-9B5E-2BF6F2D37632",
    "13C2B0F2-82C3-4996-A8D8-D42872879587",
    "5A77CCA4-6BCF-4A6D-87BA-091CE6397E24",
    "00CA8EEA-7D47-43EC-8696-DDC49D54C4D8",
    "30592423-330F-4E41-9BCD-9CA85E06D425",
    "9E7E3E3C-F4DE-41EE-A08B-15E8CCA08B9A",
    "DC694B4B-EF96-4E4C-993C-601929279178",
    "AF70A061-2995-47E3-A68B-12F4B68385CC",
    "BF1DAC87-02CD-4743-B715-62EFF23ECBED",
    "86593DB8-9894-4866-A580-E7A35D53D600",
    "AF1933DE-FA7F-4753-8DA1-713035A955DD",
    "CBA0E65A-1984-436E-A35F-AD2C5DECB853",
    "47F5A0C0-1B04-4F5F-B9BF-6569E72C6121",
    "D5522A6B-5A85-4C59-A0E7-D424A96E94E4",
    "772004AF-78F6-4E1E-ADAD-DC1E3187CC7E",
    "116DA4A7-448E-4E49-849C-9457E292E1AC",
    "9EE6B1E3-564D-4310-81FC-8BD152FD7B61",
    "7428649F-5425-4F5A-A704-14DC383611C9",
    "3CADD8FE-CBFE-4390-9306-DB453623D44C",
    "EE53A162-A74B-4899-AD30-CCE659CC5AF4",
    "de98ce5e-513e-454a-bfdb-fc8ff93ee92e",
    "1EE39B10-69B0-4434-BCA1-5A26BADAFD27",
    "52B5C7B1-D277-4358-AECA-714E0E219590",
    "C3165B46-F1F8-4524-9D50-46ADD9B1E86F",
    "9661B8FA-BD0D-4C75-9F0A-0409C3121B37",
    "3F35D12C-4BBA-400D-BA7F-BC13512B22EB",
    "BE30E66B-0C3C-4664-ABE5-99997416B687",
    "08FC5722-0531-4D4C-97E4-61C2A123D784",
    "947b67ce-177f-4c70-8fa4-10ab3c171d29",
    "9D021CA3-A103-4CB9-B053-A7ABCC4BBC96",
    "B1CCC136-FAB0-4140-A927-9603C11DD1AA",
    "89E4F360-8E77-457D-8549-C739496A287E",
    "69B03296-4EDD-4823-BDAF-DD91604F9587",
    "CB50EA97-DACF-4CCB-9D09-BA89C6C4B967",
    "F996C3D1-5684-45BF-9B30-9B0ADEC987C2",
    "CD87EF17-F2AC-429D-A8D5-5443485A6BF3",
    "F566B784-F61C-49A6-89A1-BEFF497487D2",
    "136EE958-6048-4129-B1B1-D0E4B11147EC",
    "613D4345-0C3F-4BE2-AC7D-28D209F159BA",
    "FC044FAE-FD5C-45A2-8740-9CE8783C76FA",
    "C0785556-9B3B-431E-95A0-39911DB1DBD0",
    "19982FDF-FB8B-4178-8F34-127E40964A12",
    "F46272C1-526B-41A1-88B1-8A4F7CE7BAA9",
    "95031F08-A04A-4FC4-81D5-EEAAEC329F6D",
    "5A213243-07F2-4450-9397-B021595F5865",
    "3193501C-9111-4782-B0EC-778CD6923E08",
    "24924509-5EF2-473C-863C-6B43BF64FE09",
    "3231807B-22F3-4D6B-A490-403CC0018042",
    "393488EE-E78B-4DFB-9995-0E44AB538F42",
    "1931C950-68C7-11E2-BCFD-0800200C9A66",
    "0b8688c1-bb96-41d4-ac0f-8c9c71f54eda",
    "F32C85D5-615A-4CDA-A7AD-F2F27985E283",
    "E0F993E0-766D-4FEB-86E1-DE06748F912C",
    "D3678054-4391-45BD-9C32-0FD253EF2AAD",
    "65A55BBF-5E9E-4ACC-BD87-723BD3CB3ACF",
    "9EFB5970-67D1-4A17-BD43-687072DFBEA3",
    "66EE6227-E990-4451-B134-43D0D51715C6",
    "C34B49DE-FB46-49EA-B34C-41CC059EC4B5",
    "86B73616-BABF-4D88-9184-ECD1C5657293",
    "D1AEB195-55A5-4447-9578-47BD8431E13F",
    "4B50B9FE-8895-4454-A94B-4860E664883C",
    "FF9B09BF-27F1-4072-B0FB-1801C0A9B89E",
    "BBF8B6D2-AD53-45AF-98E5-CBD30A00ED50",
    "A2B53986-0BFC-40D6-8D6D-6A2B4DFF8D14",
    "CDC9F86A-5F6C-4334-8887-4A4AC7D6C24E",
    "3ECA0242-5B31-47C4-AC03-31FEC46BB777",
    "B831B534-D7A7-4063-A1C5-694D1FEE9F22",
    "B83BF1CE-F80C-481A-9797-0FE46F6BA11F",
    "EDC9AA02-9A92-4809-BCEC-5BA863A28C69",
    "46281C3A-4286-422D-B8F4-8D9015AB96BB",
    "15109E1D-8473-4218-9510-65EB089ADCC9",
    "3B9BF398-E112-45F3-B0A7-B9DC448DF868",
    "78C709A5-C1C8-4CAE-99F0-52D43B189D88",
    "83654CC3-4296-445B-863C-A5CC09185945",
    "E761C654-5896-4903-8929-581C229EE0C3",
    "A2252BB9-ED7C-4EEF-AB7B-18B6B4DC774E",
    "5DB3826F-4207-4A7A-A3A4-F968F85F1D04",
    "0858835E-BC3A-43D9-A085-3DF96D70D3ED",
    "DD3686AE-6C9C-446F-A255-C41A5CEEBE46",
    "DEBE5437-93D0-4F1C-8A64-F75E77567AE9",
    "40734BBD-6781-45F7-9A90-C73C89C9ECF2",
    "3EF9E44E-900D-40CC-9916-DFC4560F377C",
    "9B56DFEE-C85E-4DD7-B828-19D232C323BA",
    "884274A6-948A-409C-AE6E-96BEA6373ACE",
    "6334C760-93BD-41D1-90B7-4A63678177E7",
    "8360A137-A59C-4541-8B7A-6F596128A6AF",
    "A8C274D3-DE56-413D-AAA2-C8EE6D7CA3BE",
    "620114F1-70C7-474A-B996-318B49AA2D9F",
    "4CCBA3E8-89A4-4F25-BAEE-B2248E90415F",
    "38A6C9BE-8929-4599-8C1C-77C10F93000C",
    "AAC013BE-1485-46B3-9539-A4162D9C95AC",
    "A088AE69-7D61-45A5-9919-BF2328F836BE",
    "04B47713-9ACE-4E57-851F-137DE23CD4E5",
    "5FF8C2E2-9A99-47C5-BAD1-3E5C3B0EE615",
    "107633B0-202B-4625-9CD4-8AFE131C5D10",
    "A982E92C-85D9-480B-98CE-A2F2BF80380C",
    "86A007AE-A9FE-404E-9BF6-AD880D4F8568",
    "49B5D670-24BF-4392-B6D5-3426A88DC2AC",
    "54596159-118E-40BC-B8F5-080F962C0D16",
    "8C4442DA-BBEF-4C3A-A95C-F45CE891F407",
    "6C91684B-857B-455F-9A6B-9DC15A31E98A",
    "02BFB95A-E433-4A02-A6A1-3F8593BFDC90",
    "54BB53BC-E33D-4FCF-8C7C-2211FF09F57C",
    "3F5AAAF5-64FD-435D-81C8-17EF54119440",
    "6BC329AA-C9C7-4372-990E-46E3CA20ECF2",
    "858EEBF3-169F-4AE7-99A2-A7CC8D025D4E",
    "23498D9C-045C-4D99-AAD7-E01CFDBC717B",
    "BF43A017-C447-4FF6-9685-D57DD628A6D1",
    "EB83326F-B055-46C7-8754-74C9033EE41E",
    "639CE7C8-8907-4F07-9AE6-C5D61B7E8341",
    "E20AECA2-ECF5-4985-9EF2-0A01130B084B",
    "38533C8E-BAA0-4BC5-B12F-FE0E0E88C471",
    "6188C8D0-8CF4-4BE4-86FE-268862413701",
    "FF9EE1B5-2B29-4EF8-B929-35C530DD52A0",
    "C728529C-B0FE-4726-9D1C-7438721117CA",
    "1802CFF1-E4F6-40D7-895E-D3F8EE01B7C3",
    "A23740C3-2E70-4B87-8326-C9A88F4CD66E",
    "B66CC389-2650-4205-B30B-5D279ACDE8AB",
    "3E43D7CE-DDFD-446F-B6D4-BB793ABB46A6",
    "67E749A9-324E-4322-B0D2-015974DD31A7",
    "B8B42051-2947-4461-A1A8-439BE861DB39",
    "89DA27EF-860B-4FF9-9917-EF81A266FAFB",
    "71605FA8-1F08-41EF-98E0-339005AA2D4A",
    "D78B4C18-1887-4D66-8FF6-6FFFEF93C6CB",
    "26C05258-8F36-4A4B-A710-5B7A4D64810B",
    "B14481B6-5DE6-485F-9858-7011DE27884C",
    "8544A47F-DF6D-4B07-A0D9-FF85800E8BB1",
    "F9F3F9F7-04E3-4E60-BFE8-A07A517550FE",
    "6B6EEC2A-31FE-4112-9200-3F74B3E6D119"
  ],
  "canopiesByManufacturer": [
    "C5479C12-84C5-4FB9-BBBB-E7A9202EE41A",
    "3F35D12C-4BBA-400D-BA7F-BC13512B22EB",
    "F32C85D5-615A-4CDA-A7AD-F2F27985E283",
    "E0F993E0-766D-4FEB-86E1-DE06748F912C",
    "0858835E-BC3A-43D9-A085-3DF96D70D3ED",
    "8360A137-A59C-4541-8B7A-6F596128A6AF",
    "02BFB95A-E433-4A02-A6A1-3F8593BFDC90",
    "38533C8E-BAA0-4BC5-B12F-FE0E0E88C471",
    "6B6EEC2A-31FE-4112-9200-3F74B3E6D119",
    "9EE6B1E3-564D-4310-81FC-8BD152FD7B61",
    "7428649F-5425-4F5A-A704-14DC383611C9",
    "F0C4AF1D-A9BE-4560-B8E4-4F38616B30D4",
    "C5820EA7-FD28-48D5-9BEC-0D830639B02A",
    "9634AACA-2A16-42B6-A687-6536DE20D3F7",
    "918A79DF-E421-4207-ADFD-C15C64194642",
    "037A12CF-5B9F-4B20-82C1-4A5FB376B38D",
    "772004AF-78F6-4E1E-ADAD-DC1E3187CC7E",
    "3193501C-9111-4782-B0EC-778CD6923E08",
    "4B50B9FE-8895-4454-A94B-4860E664883C",
    "620114F1-70C7-474A-B996-318B49AA2D9F",
    "A23740C3-2E70-4B87-8326-C9A88F4CD66E",
    "52B5C7B1-D277-4358-AECA-714E0E219590",
    "E761C654-5896-4903-8929-581C229EE0C3",
    "7017A355-35E0-4578-A81D-89CE8F021AAA",
    "F1269861-262D-4CCC-A8FA-4C190B59C0C6",
    "D7165D47-1EAA-49C9-8B53-A47CA6F30572",
    "8B779965-13DC-4836-825F-96360ADEA300",
    "CB0F9AD7-83BF-4555-B749-09A5BC0868B7",
    "CCD4E177-0E52-4837-BCA8-0640570E41E6",
    "4CCBA3E8-89A4-4F25-BAEE-B2248E90415F",
    "38A6C9BE-8929-4599-8C1C-77C10F93000C",
    "3A6927EF-1272-48DF-BB2A-130E960FA1E8",
    "428AC10E-5398-4593-852B-EBD29E451899",
    "2B6BAAC0-2E47-48BF-B966-137373CA9CA9",
    "C6C59DF8-9127-4BCA-B320-2BFE21AAA1A8",
    "371ABAEA-0A5B-4832-BB52-135A20A2C74F",
    "E1297544-310F-4FBD-9436-98368303E71E",
    "BE30E66B-0C3C-4664-ABE5-99997416B687",
    "08FC5722-0531-4D4C-97E4-61C2A123D784",
    "B1CCC136-FAB0-4140-A927-9603C11DD1AA",
    "89E4F360-8E77-457D-8549-C739496A287E",
    "66EE6227-E990-4451-B134-43D0D51715C6",
    "C34B49DE-FB46-49EA-B34C-41CC059EC4B5",
    "FF9B09BF-27F1-4072-B0FB-1801C0A9B89E",
    "A2B53986-0BFC-40D6-8D6D-6A2B4DFF8D14",
    "DD3686AE-6C9C-446F-A255-C41A5CEEBE46",
    "DEBE5437-93D0-4F1C-8A64-F75E77567AE9",
    "5FF8C2E2-9A99-47C5-BAD1-3E5C3B0EE615",
    "E0142D15-96D2-461C-8915-9C1625C9C5EB",
    "9556811C-4FA7-4A1A-9915-61295E9EC259",
    "13C2B0F2-82C3-4996-A8D8-D42872879587",
    "30592423-330F-4E41-9BCD-9CA85E06D425",
    "9E7E3E3C-F4DE-41EE-A08B-15E8CCA08B9A",
    "DC694B4B-EF96-4E4C-993C-601929279178",
    "AF70A061-2995-47E3-A68B-12F4B68385CC",
    "AF1933DE-FA7F-4753-8DA1-713035A955DD",
    "CD87EF17-F2AC-429D-A8D5-5443485A6BF3",
    "65A55BBF-5E9E-4ACC-BD87-723BD3CB3ACF",
    "6C91684B-857B-455F-9A6B-9DC15A31E98A",
    "1802CFF1-E4F6-40D7-895E-D3F8EE01B7C3",
    "89DCF61C-2FE5-4717-B14F-B5E8BB266DFB",
    "F996C3D1-5684-45BF-9B30-9B0ADEC987C2",
    "F46272C1-526B-41A1-88B1-8A4F7CE7BAA9",
    "B831B534-D7A7-4063-A1C5-694D1FEE9F22",
    "B66CC389-2650-4205-B30B-5D279ACDE8AB",
    "6B1E0B26-043D-48EE-883F-59C60BB8F04F",
    "770353E7-3B9A-4969-AE70-268DADA2EF23",
    "00CA8EEA-7D47-43EC-8696-DDC49D54C4D8",
    "BF43A017-C447-4FF6-9685-D57DD628A6D1",
    "CDC9F86A-5F6C-4334-8887-4A4AC7D6C24E",
    "A8C274D3-DE56-413D-AAA2-C8EE6D7CA3BE",
    "89DA27EF-860B-4FF9-9917-EF81A266FAFB",
    "8BF69609-7C2D-4EF6-97F8-8A0530893D02",
    "747B45F3-B12C-4743-A5F8-2D90C20F22F1",
    "128B96F9-DAC3-4297-8329-FC24CCF9E781",
    "24924509-5EF2-473C-863C-6B43BF64FE09",
    "1FB6128A-0203-44D9-9C55-06E4289D1540",
    "909AAB5D-37EA-447A-A850-4AEEE03CF1D2",
    "6F21BE85-8902-4392-967C-8DE5FAAD478C",
    "AD3229CE-7B38-4BC8-A1C6-73D6DF651198",
    "86F21B4A-D883-4391-A792-D2F43259942B",
    "65BE2372-98A5-4109-9D88-16550BD74A3D",
    "084EE6A7-9F1B-4102-ABF3-8F80E4E83132",
    "47F5A0C0-1B04-4F5F-B9BF-6569E72C6121",
    "D5522A6B-5A85-4C59-A0E7-D424A96E94E4",
    "EE53A162-A74B-4899-AD30-CCE659CC5AF4",
    "de98ce5e-513e-454a-bfdb-fc8ff93ee92e",
    "947b67ce-177f-4c70-8fa4-10ab3c171d29",
    "9D021CA3-A103-4CB9-B053-A7ABCC4BBC96",
    "95031F08-A04A-4FC4-81D5-EEAAEC329F6D",
    "5A213243-07F2-4450-9397-B021595F5865",
    "0b8688c1-bb96-41d4-ac0f-8c9c71f54eda",
    "3B9BF398-E112-45F3-B0A7-B9DC448DF868",
    "78C709A5-C1C8-4CAE-99F0-52D43B189D88",
    "83654CC3-4296-445B-863C-A5CC09185945",
    "6334C760-93BD-41D1-90B7-4A63678177E7",
    "86A007AE-A9FE-404E-9BF6-AD880D4F8568",
    "3F5AAAF5-64FD-435D-81C8-17EF54119440",
    "6BC329AA-C9C7-4372-990E-46E3CA20ECF2",
    "CBA0E65A-1984-436E-A35F-AD2C5DECB853",
    "858EEBF3-169F-4AE7-99A2-A7CC8D025D4E",
    "E20AECA2-ECF5-4985-9EF2-0A01130B084B",
    "6188C8D0-8CF4-4BE4-86FE-268862413701",
    "FF9EE1B5-2B29-4EF8-B929-35C530DD52A0",
    "26C05258-8F36-4A4B-A710-5B7A4D64810B",
    "B14481B6-5DE6-485F-9858-7011DE27884C",
    "F9F3F9F7-04E3-4E60-BFE8-A07A517550FE",
    "F0B876B6-F2C6-4560-BF4D-A22DD3C070ED",
    "51970E29-3AFA-4668-9641-4F5281140168",
    "4BBA3DB9-9EC3-4EAD-BC59-C72744F4EE02",
    "9BADF237-317E-4B64-BF72-9A476F85CA6B",
    "85990128-ED3F-442D-9D76-373E3F267CDA",
    "116DA4A7-448E-4E49-849C-9457E292E1AC",
    "69B03296-4EDD-4823-BDAF-DD91604F9587",
    "F566B784-F61C-49A6-89A1-BEFF497487D2",
    "136EE958-6048-4129-B1B1-D0E4B11147EC",
    "D3678054-4391-45BD-9C32-0FD253EF2AAD",
    "9EFB5970-67D1-4A17-BD43-687072DFBEA3",
    "A088AE69-7D61-45A5-9919-BF2328F836BE",
    "04B47713-9ACE-4E57-851F-137DE23CD4E5",
    "54BB53BC-E33D-4FCF-8C7C-2211FF09F57C",
    "600122AA-A33A-4AF6-A326-8B9E251219D9",
    "9B97CE42-AF52-41E6-828C-9D0EF103B7A3",
    "DD5B5953-DDC5-49E1-995D-1997794694D3",
    "ABB72EAB-6276-42E8-9681-19FBCB846FC1",
    "FC044FAE-FD5C-45A2-8740-9CE8783C76FA",
    "D1AEB195-55A5-4447-9578-47BD8431E13F",
    "BBF8B6D2-AD53-45AF-98E5-CBD30A00ED50",
    "5DB3826F-4207-4A7A-A3A4-F968F85F1D04",
    "49B5D670-24BF-4392-B6D5-3426A88DC2AC",
    "3E43D7CE-DDFD-446F-B6D4-BB793ABB46A6",
    "71605FA8-1F08-41EF-98E0-339005AA2D4A",
    "EA08A05D-B79A-4687-AED8-AFB272287ADA",
    "BF1DAC87-02CD-4743-B715-62EFF23ECBED",
    "3CADD8FE-CBFE-4390-9306-DB453623D44C",
    "1EE39B10-69B0-4434-BCA1-5A26BADAFD27",
    "CB50EA97-DACF-4CCB-9D09-BA89C6C4B967",
    "3231807B-22F3-4D6B-A490-403CC0018042",
    "393488EE-E78B-4DFB-9995-0E44AB538F42",
    "1931C950-68C7-11E2-BCFD-0800200C9A66",
    "86B73616-BABF-4D88-9184-ECD1C5657293",
    "B83BF1CE-F80C-481A-9797-0FE46F6BA11F",
    "EDC9AA02-9A92-4809-BCEC-5BA863A28C69",
    "46281C3A-4286-422D-B8F4-8D9015AB96BB",
    "15109E1D-8473-4218-9510-65EB089ADCC9",
    "40734BBD-6781-45F7-9A90-C73C89C9ECF2",
    "AAC013BE-1485-46B3-9539-A4162D9C95AC",
    "107633B0-202B-4625-9CD4-8AFE131C5D10",
    "A982E92C-85D9-480B-98CE-A2F2BF80380C",
    "23498D9C-045C-4D99-AAD7-E01CFDBC717B",
    "EB83326F-B055-46C7-8754-74C9033EE41E",
    "639CE7C8-8907-4F07-9AE6-C5D61B7E8341",
    "8544A47F-DF6D-4B07-A0D9-FF85800E8BB1",
    "5A77CCA4-6BCF-4A6D-87BA-091CE6397E24",
    "86593DB8-9894-4866-A580-E7A35D53D600",
    "884274A6-948A-409C-AE6E-96BEA6373ACE",
    "2A2D16C5-D208-44AB-AF97-1398405EC1A5",
    "1AFA9F7D-F7C2-4807-80EA-B394D15AAFE9",
    "8A64E433-8856-4329-9B5E-2BF6F2D37632",
    "613D4345-0C3F-4BE2-AC7D-28D209F159BA",
    "3ECA0242-5B31-47C4-AC03-31FEC46BB777",
    "54596159-118E-40BC-B8F5-080F962C0D16",
    "8C4442DA-BBEF-4C3A-A95C-F45CE891F407",
    "C728529C-B0FE-4726-9D1C-7438721117CA",
    "67E749A9-324E-4322-B0D2-015974DD31A7",
    "B8B42051-2947-4461-A1A8-439BE861DB39",
    "21BD9B3B-77E6-4605-B89A-0B118C97C960",
    "C3165B46-F1F8-4524-9D50-46ADD9B1E86F",
    "9661B8FA-BD0D-4C75-9F0A-0409C3121B37",
    "C0785556-9B3B-431E-95A0-39911DB1DBD0",
    "19982FDF-FB8B-4178-8F34-127E40964A12",
    "A2252BB9-ED7C-4EEF-AB7B-18B6B4DC774E",
    "3EF9E44E-900D-40CC-9916-DFC4560F377C",
    "9B56DFEE-C85E-4DD7-B828-19D232C323BA",
    "2351611F-068C-4901-820D-26EC38FC36D9",
    "D78B4C18-1887-4D66-8FF6-6FFFEF93C6CB"
  ],
  "canopiesByCategory": [
    "600122AA-A33A-4AF6-A326-8B9E251219D9",
    "3A6927EF-1272-48DF-BB2A-130E960FA1E8",
    "428AC10E-5398-4593-852B-EBD29E451899",
    "2B6BAAC0-2E47-48BF-B966-137373CA9CA9",
    "4BBA3DB9-9EC3-4EAD-BC59-C72744F4EE02",
    "747B45F3-B12C-4743-A5F8-2D90C20F22F1",
    "128B96F9-DAC3-4297-8329-FC24CCF9E781",
    "9B97CE42-AF52-41E6-828C-9D0EF103B7A3",
    "89DCF61C-2FE5-4717-B14F-B5E8BB266DFB",
    "CCD4E177-0E52-4837-BCA8-0640570E41E6",
    "1AFA9F7D-F7C2-4807-80EA-B394D15AAFE9",
    "371ABAEA-0A5B-4832-BB52-135A20A2C74F",
    "E1297544-310F-4FBD-9436-98368303E71E",
    "D5522A6B-5A85-4C59-A0E7-D424A96E94E4",
    "BE30E66B-0C3C-4664-ABE5-99997416B687",
    "08FC5722-0531-4D4C-97E4-61C2A123D784",
    "B1CCC136-FAB0-4140-A927-9603C11DD1AA",
    "89E4F360-8E77-457D-8549-C739496A287E",
    "CB50EA97-DACF-4CCB-9D09-BA89C6C4B967",
    "3231807B-22F3-4D6B-A490-403CC0018042",
    "393488EE-E78B-4DFB-9995-0E44AB538F42",
    "D3678054-4391-45BD-9C32-0FD253EF2AAD",
    "9EFB5970-67D1-4A17-BD43-687072DFBEA3",
    "A2B53986-0BFC-40D6-8D6D-6A2B4DFF8D14",
    "3ECA0242-5B31-47C4-AC03-31FEC46BB777",
    "EDC9AA02-9A92-4809-BCEC-5BA863A28C69",
    "DEBE5437-93D0-4F1C-8A64-F75E77567AE9",
    "884274A6-948A-409C-AE6E-96BEA6373ACE",
    "8360A137-A59C-4541-8B7A-6F596128A6AF",
    "38A6C9BE-8929-4599-8C1C-77C10F93000C",
    "5FF8C2E2-9A99-47C5-BAD1-3E5C3B0EE615",
    "86A007AE-A9FE-404E-9BF6-AD880D4F8568",
    "54596159-118E-40BC-B8F5-080F962C0D16",
    "54BB53BC-E33D-4FCF-8C7C-2211FF09F57C",
    "C728529C-B0FE-4726-9D1C-7438721117CA",
    "B14481B6-5DE6-485F-9858-7011DE27884C",
    "F9F3F9F7-04E3-4E60-BFE8-A07A517550FE",
    "F1269861-262D-4CCC-A8FA-4C190B59C0C6",
    "7017A355-35E0-4578-A81D-89CE8F021AAA",
    "21BD9B3B-77E6-4605-B89A-0B118C97C960",
    "037A12CF-5B9F-4B20-82C1-4A5FB376B38D",
    "9BADF237-317E-4B64-BF72-9A476F85CA6B",
    "69B03296-4EDD-4823-BDAF-DD91604F9587",
    "95031F08-A04A-4FC4-81D5-EEAAEC329F6D",
    "5A213243-07F2-4450-9397-B021595F5865",
    "24924509-5EF2-473C-863C-6B43BF64FE09",
    "F32C85D5-615A-4CDA-A7AD-F2F27985E283",
    "E0F993E0-766D-4FEB-86E1-DE06748F912C",
    "86B73616-BABF-4D88-9184-ECD1C5657293",
    "D1AEB195-55A5-4447-9578-47BD8431E13F",
    "B83BF1CE-F80C-481A-9797-0FE46F6BA11F",
    "40734BBD-6781-45F7-9A90-C73C89C9ECF2",
    "AAC013BE-1485-46B3-9539-A4162D9C95AC",
    "49B5D670-24BF-4392-B6D5-3426A88DC2AC",
    "8C4442DA-BBEF-4C3A-A95C-F45CE891F407",
    "02BFB95A-E433-4A02-A6A1-3F8593BFDC90",
    "3F5AAAF5-64FD-435D-81C8-17EF54119440",
    "6BC329AA-C9C7-4372-990E-46E3CA20ECF2",
    "8544A47F-DF6D-4B07-A0D9-FF85800E8BB1",
    "2351611F-068C-4901-820D-26EC38FC36D9",
    "C6C59DF8-9127-4BCA-B320-2BFE21AAA1A8",
    "ABB72EAB-6276-42E8-9681-19FBCB846FC1",
    "8A64E433-8856-4329-9B5E-2BF6F2D37632",
    "BF1DAC87-02CD-4743-B715-62EFF23ECBED",
    "86593DB8-9894-4866-A580-E7A35D53D600",
    "EE53A162-A74B-4899-AD30-CCE659CC5AF4",
    "1EE39B10-69B0-4434-BCA1-5A26BADAFD27",
    "52B5C7B1-D277-4358-AECA-714E0E219590",
    "C3165B46-F1F8-4524-9D50-46ADD9B1E86F",
    "9661B8FA-BD0D-4C75-9F0A-0409C3121B37",
    "947b67ce-177f-4c70-8fa4-10ab3c171d29",
    "CD87EF17-F2AC-429D-A8D5-5443485A6BF3",
    "FC044FAE-FD5C-45A2-8740-9CE8783C76FA",
    "F46272C1-526B-41A1-88B1-8A4F7CE7BAA9",
    "65A55BBF-5E9E-4ACC-BD87-723BD3CB3ACF",
    "C34B49DE-FB46-49EA-B34C-41CC059EC4B5",
    "B831B534-D7A7-4063-A1C5-694D1FEE9F22",
    "46281C3A-4286-422D-B8F4-8D9015AB96BB",
    "15109E1D-8473-4218-9510-65EB089ADCC9",
    "3B9BF398-E112-45F3-B0A7-B9DC448DF868",
    "78C709A5-C1C8-4CAE-99F0-52D43B189D88",
    "83654CC3-4296-445B-863C-A5CC09185945",
    "DD3686AE-6C9C-446F-A255-C41A5CEEBE46",
    "3EF9E44E-900D-40CC-9916-DFC4560F377C",
    "9B56DFEE-C85E-4DD7-B828-19D232C323BA",
    "4CCBA3E8-89A4-4F25-BAEE-B2248E90415F",
    "A982E92C-85D9-480B-98CE-A2F2BF80380C",
    "FF9EE1B5-2B29-4EF8-B929-35C530DD52A0",
    "A23740C3-2E70-4B87-8326-C9A88F4CD66E",
    "D78B4C18-1887-4D66-8FF6-6FFFEF93C6CB",
    "26C05258-8F36-4A4B-A710-5B7A4D64810B",
    "F0C4AF1D-A9BE-4560-B8E4-4F38616B30D4",
    "C5820EA7-FD28-48D5-9BEC-0D830639B02A",
    "2A2D16C5-D208-44AB-AF97-1398405EC1A5",
    "F0B876B6-F2C6-4560-BF4D-A22DD3C070ED",
    "9634AACA-2A16-42B6-A687-6536DE20D3F7",
    "8B779965-13DC-4836-825F-96360ADEA300",
    "1FB6128A-0203-44D9-9C55-06E4289D1540",
    "C5479C12-84C5-4FB9-BBBB-E7A9202EE41A",
    "85990128-ED3F-442D-9D76-373E3F267CDA",
    "DD5B5953-DDC5-49E1-995D-1997794694D3",
    "8BF69609-7C2D-4EF6-97F8-8A0530893D02",
    "5A77CCA4-6BCF-4A6D-87BA-091CE6397E24",
    "CBA0E65A-1984-436E-A35F-AD2C5DECB853",
    "772004AF-78F6-4E1E-ADAD-DC1E3187CC7E",
    "7428649F-5425-4F5A-A704-14DC383611C9",
    "66EE6227-E990-4451-B134-43D0D51715C6",
    "FF9B09BF-27F1-4072-B0FB-1801C0A9B89E",
    "5DB3826F-4207-4A7A-A3A4-F968F85F1D04",
    "620114F1-70C7-474A-B996-318B49AA2D9F",
    "A088AE69-7D61-45A5-9919-BF2328F836BE",
    "04B47713-9ACE-4E57-851F-137DE23CD4E5",
    "107633B0-202B-4625-9CD4-8AFE131C5D10",
    "E20AECA2-ECF5-4985-9EF2-0A01130B084B",
    "38533C8E-BAA0-4BC5-B12F-FE0E0E88C471",
    "6188C8D0-8CF4-4BE4-86FE-268862413701",
    "6B6EEC2A-31FE-4112-9200-3F74B3E6D119",
    "51970E29-3AFA-4668-9641-4F5281140168",
    "D7165D47-1EAA-49C9-8B53-A47CA6F30572",
    "918A79DF-E421-4207-ADFD-C15C64194642",
    "909AAB5D-37EA-447A-A850-4AEEE03CF1D2",
    "6F21BE85-8902-4392-967C-8DE5FAAD478C",
    "CB0F9AD7-83BF-4555-B749-09A5BC0868B7",
    "116DA4A7-448E-4E49-849C-9457E292E1AC",
    "9EE6B1E3-564D-4310-81FC-8BD152FD7B61",
    "3CADD8FE-CBFE-4390-9306-DB453623D44C",
    "3F35D12C-4BBA-400D-BA7F-BC13512B22EB",
    "9D021CA3-A103-4CB9-B053-A7ABCC4BBC96",
    "613D4345-0C3F-4BE2-AC7D-28D209F159BA",
    "C0785556-9B3B-431E-95A0-39911DB1DBD0",
    "19982FDF-FB8B-4178-8F34-127E40964A12",
    "4B50B9FE-8895-4454-A94B-4860E664883C",
    "BBF8B6D2-AD53-45AF-98E5-CBD30A00ED50",
    "E761C654-5896-4903-8929-581C229EE0C3",
    "6C91684B-857B-455F-9A6B-9DC15A31E98A",
    "639CE7C8-8907-4F07-9AE6-C5D61B7E8341",
    "B66CC389-2650-4205-B30B-5D279ACDE8AB",
    "3E43D7CE-DDFD-446F-B6D4-BB793ABB46A6",
    "6B1E0B26-043D-48EE-883F-59C60BB8F04F",
    "EA08A05D-B79A-4687-AED8-AFB272287ADA",
    "AD3229CE-7B38-4BC8-A1C6-73D6DF651198",
    "86F21B4A-D883-4391-A792-D2F43259942B",
    "9556811C-4FA7-4A1A-9915-61295E9EC259",
    "65BE2372-98A5-4109-9D88-16550BD74A3D",
    "084EE6A7-9F1B-4102-ABF3-8F80E4E83132",
    "770353E7-3B9A-4969-AE70-268DADA2EF23",
    "13C2B0F2-82C3-4996-A8D8-D42872879587",
    "00CA8EEA-7D47-43EC-8696-DDC49D54C4D8",
    "47F5A0C0-1B04-4F5F-B9BF-6569E72C6121",
    "F996C3D1-5684-45BF-9B30-9B0ADEC987C2",
    "F566B784-F61C-49A6-89A1-BEFF497487D2",
    "136EE958-6048-4129-B1B1-D0E4B11147EC",
    "3193501C-9111-4782-B0EC-778CD6923E08",
    "CDC9F86A-5F6C-4334-8887-4A4AC7D6C24E",
    "0858835E-BC3A-43D9-A085-3DF96D70D3ED",
    "A8C274D3-DE56-413D-AAA2-C8EE6D7CA3BE",
    "858EEBF3-169F-4AE7-99A2-A7CC8D025D4E",
    "BF43A017-C447-4FF6-9685-D57DD628A6D1",
    "EB83326F-B055-46C7-8754-74C9033EE41E",
    "67E749A9-324E-4322-B0D2-015974DD31A7",
    "B8B42051-2947-4461-A1A8-439BE861DB39",
    "E0142D15-96D2-461C-8915-9C1625C9C5EB",
    "30592423-330F-4E41-9BCD-9CA85E06D425",
    "9E7E3E3C-F4DE-41EE-A08B-15E8CCA08B9A",
    "DC694B4B-EF96-4E4C-993C-601929279178",
    "AF70A061-2995-47E3-A68B-12F4B68385CC",
    "AF1933DE-FA7F-4753-8DA1-713035A955DD",
    "de98ce5e-513e-454a-bfdb-fc8ff93ee92e",
    "1931C950-68C7-11E2-BCFD-0800200C9A66",
    "0b8688c1-bb96-41d4-ac0f-8c9c71f54eda",
    "A2252BB9-ED7C-4EEF-AB7B-18B6B4DC774E",
    "6334C760-93BD-41D1-90B7-4A63678177E7",
    "23498D9C-045C-4D99-AAD7-E01CFDBC717B",
    "1802CFF1-E4F6-40D7-895E-D3F8EE01B7C3",
    "89DA27EF-860B-4FF9-9917-EF81A266FAFB",
    "71605FA8-1F08-41EF-98E0-339005AA2D4A"
  ],
  "slugs": {
    "aerodyne_research": "0BF35960-3F26-42FD-8C97-03263EC42E84",
    "airtime": "97AD094C-A001-4928-8B4E-792EC76EFBCB",
    "atair_aerodynamics": "6C76AE26-F763-4536-AA22-2C4C8326804D",
    "big-air_sportz": "5947BBA8-8DE6-4F61-AB89-B0B77A74B1EB",
    "parachute_systems": "781D6203-9BF1-4D51-8CE0-21FFFB9D4D12",
    "eiff_aerodynamics": "B1F74487-55A3-4A0C-9A7E-463B508E3438",
    "firebird": "7AE2A118-7566-456B-9D73-2E6751BF7D7A",
    "flight_concepts": "97C58924-5A47-4306-B8D4-E6A932E25A9F",
    "icarus_canopies": "29A49954-54B1-4F77-A399-CCEFCCC9B3B9",
    "jump_shack": "ACCD2816-15E8-4668-B217-3F345AA6854F",
    "north_american_aerodynamics_inc": "2228CEB3-3246-47D8-A393-52FF9DFEBB45",
    "nz_aerosports": "4A42F850-E739-44D4-9A69-F66AD3247B1F",
    "parachutes_de_france": "35AF72C6-F1D1-473D-99D0-804769E3E768",
    "paratec": "E1DA167E-6512-401E-9033-8621884D2C67",
    "performance_designs": "541E8673-69E0-458A-8633-A3698311FD4B",
    "pisa": "E0E33BAB-7067-42EC-96E3-FD6B35926A11",
    "precision_aerodynamics": "7D4DAE42-062F-42A6-AD27-187B0AECDDA6",
    "strong_enterprises": "ACD6855E-9F54-4E27-B498-14C9693FB7EF",
    "squirrel": "CD51335E-1304-4391-9672-A6058F97C352",
    "fluid_wings": "932734A0-EDDD-4BED-8B8C-C329214F7F4C",
    "jojowings": "A82F2C34-DBA9-4A4B-8DE7-7319B00C7DB4",
    "skylark_parachutes": "F497D86C-7E1E-4D4E-8FD4-AD4766AE5B15",
    "para-flite": "571E4861-2CCE-4276-A5B2-046A38F4870E",
    "innovative_parachute_technologies": "75B438C0-B3B7-4D3E-9D64-6058A351A82F",
    "performance_designs-navigator": "CB50EA97-DACF-4CCB-9D09-BA89C6C4B967",
    "performance_designs-pd_f111_serie_7_cell": "3231807B-22F3-4D6B-A490-403CC0018042",
    "performance_designs-pd_f111_serie_9_cell": "393488EE-E78B-4DFB-9995-0E44AB538F42",
    "performance_designs-sabre_student": "EDC9AA02-9A92-4809-BCEC-5BA863A28C69",
    "aerodyne_research-solo": "8360A137-A59C-4541-8B7A-6F596128A6AF",
    "firebird-exit_one": "CCD4E177-0E52-4837-BCA8-0640570E41E6",
    "firebird-spark_student_hybride": "38A6C9BE-8929-4599-8C1C-77C10F93000C",
    "parachutes_de_france-prima": "D3678054-4391-45BD-9C32-0FD253EF2AAD",
    "parachutes_de_france-drakkar": "4BBA3DB9-9EC3-4EAD-BC59-C72744F4EE02",
    "parachutes_de_france-turbo": "54BB53BC-E33D-4FCF-8C7C-2211FF09F57C",
    "parachutes_de_france-prime": "9EFB5970-67D1-4A17-BD43-687072DFBEA3",
    "precision_aerodynamics-raven": "3ECA0242-5B31-47C4-AC03-31FEC46BB777",
    "precision_aerodynamics-super_raven": "54596159-118E-40BC-B8F5-080F962C0D16",
    "precision_aerodynamics-falcon": "1AFA9F7D-F7C2-4807-80EA-B394D15AAFE9",
    "precision_aerodynamics-voyager_student": "C728529C-B0FE-4726-9D1C-7438721117CA",
    "pisa-skymaster": "884274A6-948A-409C-AE6E-96BEA6373ACE",
    "paratec-balance": "600122AA-A33A-4AF6-A326-8B9E251219D9",
    "paratec-element": "9B97CE42-AF52-41E6-828C-9D0EF103B7A3",
    "flight_concepts-maverick": "B1CCC136-FAB0-4140-A927-9603C11DD1AA",
    "flight_concepts-fury": "E1297544-310F-4FBD-9436-98368303E71E",
    "flight_concepts-firelite": "371ABAEA-0A5B-4832-BB52-135A20A2C74F",
    "flight_concepts-cricket": "428AC10E-5398-4593-852B-EBD29E451899",
    "flight_concepts-startrac": "5FF8C2E2-9A99-47C5-BAD1-3E5C3B0EE615",
    "flight_concepts-sharpchuter": "DEBE5437-93D0-4F1C-8A64-F75E77567AE9",
    "flight_concepts-raider": "A2B53986-0BFC-40D6-8D6D-6A2B4DFF8D14",
    "flight_concepts-maverone": "89E4F360-8E77-457D-8549-C739496A287E",
    "flight_concepts-clipper": "3A6927EF-1272-48DF-BB2A-130E960FA1E8",
    "flight_concepts-manta": "BE30E66B-0C3C-4664-ABE5-99997416B687",
    "flight_concepts-manta_zp": "08FC5722-0531-4D4C-97E4-61C2A123D784",
    "flight_concepts-cruiselite": "2B6BAAC0-2E47-48BF-B966-137373CA9CA9",
    "nz_aerosports-student_zp7": "86A007AE-A9FE-404E-9BF6-AD880D4F8568",
    "icarus_canopies-equinox": "89DCF61C-2FE5-4717-B14F-B5E8BB266DFB",
    "parachute_systems-zepo_student": "B14481B6-5DE6-485F-9858-7011DE27884C",
    "north_american_aerodynamics_inc-eagle_7-cell": "747B45F3-B12C-4743-A5F8-2D90C20F22F1",
    "north_american_aerodynamics_inc-eagle_9-cell": "128B96F9-DAC3-4297-8329-FC24CCF9E781",
    "nz_aerosports-icarus_student": "D5522A6B-5A85-4C59-A0E7-D424A96E94E4",
    "parachute_systems-zp": "F9F3F9F7-04E3-4E60-BFE8-A07A517550FE",
    "parachutes_de_france-electra": "9BADF237-317E-4B64-BF72-9A476F85CA6B",
    "parachutes_de_france-merit": "69B03296-4EDD-4823-BDAF-DD91604F9587",
    "aerodyne_research-pilot": "F32C85D5-615A-4CDA-A7AD-F2F27985E283",
    "aerodyne_research-pilot7": "E0F993E0-766D-4FEB-86E1-DE06748F912C",
    "aerodyne_research-triathlon": "02BFB95A-E433-4A02-A6A1-3F8593BFDC90",
    "paratec-super_7": "49B5D670-24BF-4392-B6D5-3426A88DC2AC",
    "paratec-quadra_v-tec": "D1AEB195-55A5-4447-9578-47BD8431E13F",
    "performance_designs-sabre": "B83BF1CE-F80C-481A-9797-0FE46F6BA11F",
    "performance_designs-spectre": "AAC013BE-1485-46B3-9539-A4162D9C95AC",
    "performance_designs-silhouette": "40734BBD-6781-45F7-9A90-C73C89C9ECF2",
    "performance_designs-pulse": "86B73616-BABF-4D88-9184-ECD1C5657293",
    "performance_designs-zero": "8544A47F-DF6D-4B07-A0D9-FF85800E8BB1",
    "precision_aerodynamics-synergy": "8C4442DA-BBEF-4C3A-A95C-F45CE891F407",
    "nz_aerosports-omni": "5A213243-07F2-4450-9397-B021595F5865",
    "nz_aerosports-omega": "95031F08-A04A-4FC4-81D5-EEAAEC329F6D",
    "firebird-cayenne": "F1269861-262D-4CCC-A8FA-4C190B59C0C6",
    "atair_aerodynamics-dragon": "037A12CF-5B9F-4B20-82C1-4A5FB376B38D",
    "north_american_aerodynamics_inc-parafoil": "24924509-5EF2-473C-863C-6B43BF64FE09",
    "eiff_aerodynamics-classic": "7017A355-35E0-4578-A81D-89CE8F021AAA",
    "skylark_parachutes-commodore": "21BD9B3B-77E6-4605-B89A-0B118C97C960",
    "para-flite-turbo_z": "3F5AAAF5-64FD-435D-81C8-17EF54119440",
    "para-flite-turbo_zx": "6BC329AA-C9C7-4372-990E-46E3CA20ECF2",
    "performance_designs-sabre_2": "46281C3A-4286-422D-B8F4-8D9015AB96BB",
    "performance_designs-sabre_3": "15109E1D-8473-4218-9510-65EB089ADCC9",
    "performance_designs-lightning": "1EE39B10-69B0-4434-BCA1-5A26BADAFD27",
    "performance_designs-storm": "A982E92C-85D9-480B-98CE-A2F2BF80380C",
    "performance_designs-horizon": "BF1DAC87-02CD-4743-B715-62EFF23ECBED",
    "precision_aerodynamics-fusion": "8A64E433-8856-4329-9B5E-2BF6F2D37632",
    "big-air_sportz-lotus": "52B5C7B1-D277-4358-AECA-714E0E219590",
    "nz_aerosports-safire_1": "3B9BF398-E112-45F3-B0A7-B9DC448DF868",
    "nz_aerosports-safire_2": "78C709A5-C1C8-4CAE-99F0-52D43B189D88",
    "icarus_canopies-s-fire": "B831B534-D7A7-4063-A1C5-694D1FEE9F22",
    "nz_aerosports-safire_3": "83654CC3-4296-445B-863C-A5CC09185945",
    "strong_enterprises-z-po": "D78B4C18-1887-4D66-8FF6-6FFFEF93C6CB",
    "paratec-faqtor": "ABB72EAB-6276-42E8-9681-19FBCB846FC1",
    "paratec-nrg": "FC044FAE-FD5C-45A2-8740-9CE8783C76FA",
    "pisa-hornet": "86593DB8-9894-4866-A580-E7A35D53D600",
    "parachute_systems-zepo_exe": "26C05258-8F36-4A4B-A710-5B7A4D64810B",
    "parachute_systems-volt": "FF9EE1B5-2B29-4EF8-B929-35C530DD52A0",
    "firebird-spark": "4CCBA3E8-89A4-4F25-BAEE-B2248E90415F",
    "flight_concepts-express": "C6C59DF8-9127-4BCA-B320-2BFE21AAA1A8",
    "flight_concepts-prodigy": "C34B49DE-FB46-49EA-B34C-41CC059EC4B5",
    "flight_concepts-sentry": "DD3686AE-6C9C-446F-A255-C41A5CEEBE46",
    "nz_aerosports-matrix": "947b67ce-177f-4c70-8fa4-10ab3c171d29",
    "nz_aerosports-matrix_2": "9D021CA3-A103-4CB9-B053-A7ABCC4BBC96",
    "skylark_parachutes-skipper": "3EF9E44E-900D-40CC-9916-DFC4560F377C",
    "skylark_parachutes-skipper_evo": "9B56DFEE-C85E-4DD7-B828-19D232C323BA",
    "skylark_parachutes-magellan": "C3165B46-F1F8-4524-9D50-46ADD9B1E86F",
    "skylark_parachutes-magellan_evo": "9661B8FA-BD0D-4C75-9F0A-0409C3121B37",
    "squirrel-epicene": "2351611F-068C-4901-820D-26EC38FC36D9",
    "fluid_wings-prime": "65A55BBF-5E9E-4ACC-BD87-723BD3CB3ACF",
    "atair_aerodynamics-winx": "A23740C3-2E70-4B87-8326-C9A88F4CD66E",
    "fluid_wings-nexus": "CD87EF17-F2AC-429D-A8D5-5443485A6BF3",
    "nz_aerosports-kraken": "EE53A162-A74B-4899-AD30-CCE659CC5AF4",
    "icarus_canopies-om-7": "F46272C1-526B-41A1-88B1-8A4F7CE7BAA9",
    "performance_designs-stiletto": "107633B0-202B-4625-9CD4-8AFE131C5D10",
    "parachutes_de_france-bt": "F0B876B6-F2C6-4560-BF4D-A22DD3C070ED",
    "parachutes_de_france-springo": "A088AE69-7D61-45A5-9919-BF2328F836BE",
    "parachutes_de_france-electra_xs": "85990128-ED3F-442D-9D76-373E3F267CDA",
    "parachutes_de_france-springo_ng": "04B47713-9ACE-4E57-851F-137DE23CD4E5",
    "precision_aerodynamics-batwing": "2A2D16C5-D208-44AB-AF97-1398405EC1A5",
    "airtime-jonathan": "7428649F-5425-4F5A-A704-14DC383611C9",
    "atair_aerodynamics-ace": "F0C4AF1D-A9BE-4560-B8E4-4F38616B30D4",
    "atair_aerodynamics-cobalt": "9634AACA-2A16-42B6-A687-6536DE20D3F7",
    "atair_aerodynamics-alpha": "C5820EA7-FD28-48D5-9BEC-0D830639B02A",
    "atair_aerodynamics-space": "620114F1-70C7-474A-B996-318B49AA2D9F",
    "atair_aerodynamics-impulse": "772004AF-78F6-4E1E-ADAD-DC1E3187CC7E",
    "aerodyne_research-vision": "38533C8E-BAA0-4BC5-B12F-FE0E0E88C471",
    "aerodyne_research-diablo": "C5479C12-84C5-4FB9-BBBB-E7A9202EE41A",
    "aerodyne_research-zulu": "6B6EEC2A-31FE-4112-9200-3F74B3E6D119",
    "flight_concepts-pro_serie": "66EE6227-E990-4451-B134-43D0D51715C6",
    "flight_concepts-rage": "FF9B09BF-27F1-4072-B0FB-1801C0A9B89E",
    "paratec-fandango": "DD5B5953-DDC5-49E1-995D-1997794694D3",
    "firebird-contrail": "8B779965-13DC-4836-825F-96360ADEA300",
    "pisa-heatwave": "5A77CCA4-6BCF-4A6D-87BA-091CE6397E24",
    "parachute_systems-hurricane": "CBA0E65A-1984-436E-A35F-AD2C5DECB853",
    "parachute_systems-vixen": "6188C8D0-8CF4-4BE4-86FE-268862413701",
    "jump_shack-firebolt": "8BF69609-7C2D-4EF6-97F8-8A0530893D02",
    "nz_aerosports-crossfire": "1FB6128A-0203-44D9-9C55-06E4289D1540",
    "parachute_systems-ventus_hybrid": "E20AECA2-ECF5-4985-9EF2-0A01130B084B",
    "paratec-scorpion": "5DB3826F-4207-4A7A-A3A4-F968F85F1D04",
    "skylark_parachutes-odyssey": "C0785556-9B3B-431E-95A0-39911DB1DBD0",
    "skylark_parachutes-odyssey_evo": "19982FDF-FB8B-4178-8F34-127E40964A12",
    "nz_aerosports-crossfire_2": "909AAB5D-37EA-447A-A850-4AEEE03CF1D2",
    "nz_aerosports-crossfire_3": "6F21BE85-8902-4392-967C-8DE5FAAD478C",
    "icarus_canopies-x-fire": "B66CC389-2650-4205-B30B-5D279ACDE8AB",
    "big-air_sportz-samurai": "E761C654-5896-4903-8929-581C229EE0C3",
    "atair_aerodynamics-cobalt_competition": "918A79DF-E421-4207-ADFD-C15C64194642",
    "atair_aerodynamics-radical": "4B50B9FE-8895-4454-A94B-4860E664883C",
    "airtime-jedei": "9EE6B1E3-564D-4310-81FC-8BD152FD7B61",
    "firebird-demon": "CB0F9AD7-83BF-4555-B749-09A5BC0868B7",
    "firebird-chilli": "D7165D47-1EAA-49C9-8B53-A47CA6F30572",
    "performance_designs-katana": "3CADD8FE-CBFE-4390-9306-DB453623D44C",
    "performance_designs-vengeance": "639CE7C8-8907-4F07-9AE6-C5D61B7E8341",
    "paratec-x-wing": "3E43D7CE-DDFD-446F-B6D4-BB793ABB46A6",
    "paratec-rage": "BBF8B6D2-AD53-45AF-98E5-CBD30A00ED50",
    "parachutes_de_france-bt_pro": "51970E29-3AFA-4668-9641-4F5281140168",
    "parachutes_de_france-instinct": "116DA4A7-448E-4E49-849C-9457E292E1AC",
    "precision_aerodynamics-nitron": "613D4345-0C3F-4BE2-AC7D-28D209F159BA",
    "aerodyne_research-mamba": "3F35D12C-4BBA-400D-BA7F-BC13512B22EB",
    "fluid_wings-tesla": "6C91684B-857B-455F-9A6B-9DC15A31E98A",
    "aerodyne_research-sensei": "0858835E-BC3A-43D9-A085-3DF96D70D3ED",
    "parachute_systems-v-max": "858EEBF3-169F-4AE7-99A2-A7CC8D025D4E",
    "performance_designs-velocity": "EB83326F-B055-46C7-8754-74C9033EE41E",
    "performance_designs-comp_velocity": "EA08A05D-B79A-4687-AED8-AFB272287ADA",
    "parachutes_de_france-ninja": "F566B784-F61C-49A6-89A1-BEFF497487D2",
    "parachutes_de_france-ninja_2": "136EE958-6048-4129-B1B1-D0E4B11147EC",
    "precision_aerodynamics-xaos-21": "67E749A9-324E-4322-B0D2-015974DD31A7",
    "precision_aerodynamics-xaos-27": "B8B42051-2947-4461-A1A8-439BE861DB39",
    "nz_aerosports-extreme_vx": "084EE6A7-9F1B-4102-ABF3-8F80E4E83132",
    "nz_aerosports-extreme_fx": "65BE2372-98A5-4109-9D88-16550BD74A3D",
    "icarus_canopies-neos": "F996C3D1-5684-45BF-9B30-9B0ADEC987C2",
    "nz_aerosports-daedalus_jvx": "86F21B4A-D883-4391-A792-D2F43259942B",
    "nz_aerosports-daedalus_jfx": "AD3229CE-7B38-4BC8-A1C6-73D6DF651198",
    "nz_aerosports-icarus_jfx_2": "47F5A0C0-1B04-4F5F-B9BF-6569E72C6121",
    "atair_aerodynamics-onyx": "3193501C-9111-4782-B0EC-778CD6923E08",
    "fluid_wings-echo": "9556811C-4FA7-4A1A-9915-61295E9EC259",
    "fluid_wings-gangster": "13C2B0F2-82C3-4996-A8D8-D42872879587",
    "performance_designs-peregrine": "1931C950-68C7-11E2-BCFD-0800200C9A66",
    "performance_designs-valkyrie": "23498D9C-045C-4D99-AAD7-E01CFDBC717B",
    "nz_aerosports-petra": "0b8688c1-bb96-41d4-ac0f-8c9c71f54eda",
    "nz_aerosports-leia": "de98ce5e-513e-454a-bfdb-fc8ff93ee92e",
    "nz_aerosports-sleia": "6334C760-93BD-41D1-90B7-4A63678177E7",
    "fluid_wings-helix": "30592423-330F-4E41-9BCD-9CA85E06D425",
    "fluid_wings-hs": "AF1933DE-FA7F-4753-8DA1-713035A955DD",
    "fluid_wings-hk": "9E7E3E3C-F4DE-41EE-A08B-15E8CCA08B9A",
    "fluid_wings-hk2": "AF70A061-2995-47E3-A68B-12F4B68385CC",
    "fluid_wings-hk_terminal": "DC694B4B-EF96-4E4C-993C-601929279178",
    "fluid_wings-airwolf": "E0142D15-96D2-461C-8915-9C1625C9C5EB",
    "fluid_wings-wairwolf": "1802CFF1-E4F6-40D7-895E-D3F8EE01B7C3",
    "jojowings-sonic": "A8C274D3-DE56-413D-AAA2-C8EE6D7CA3BE",
    "jojowings-raptor": "CDC9F86A-5F6C-4334-8887-4A4AC7D6C24E",
    "jojowings-xf15": "89DA27EF-860B-4FF9-9917-EF81A266FAFB",
    "innovative_parachute_technologies-axon": "6B1E0B26-043D-48EE-883F-59C60BB8F04F",
    "innovative_parachute_technologies-furai": "770353E7-3B9A-4969-AE70-268DADA2EF23",
    "innovative_parachute_technologies-vapor": "BF43A017-C447-4FF6-9685-D57DD628A6D1",
    "innovative_parachute_technologies-helios": "00CA8EEA-7D47-43EC-8696-DDC49D54C4D8",
    "skylark_parachutes-scirocco": "A2252BB9-ED7C-4EEF-AB7B-18B6B4DC774E",
    "paratec-xtc": "71605FA8-1F08-41EF-98E0-339005AA2D4A"
  }
};

module.exports = kompasroosData;